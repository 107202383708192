import React, { useState, useEffect } from 'react';
import CommonMessage from "../components/CommonMessage";
import { web3AccountCheck } from '../helper/web3helper';
import hostContract from '../web3/hostContract.json';
import web3Config from '../web3/web3Config.json';
import { formatEmailDetails, formatMessageList, getWeb2emails } from '../helper/dataFormatHelper';
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from 'react-redux';
import { getHostHelperContract } from ".././helper/contract-helper"
const cookies = new Cookies();

const Trash = () => {
  const [user] = useState(cookies.get("userObject"));
  const [web3Value, setWeb3] = useState(null);
  const [account, setAccount] = useState('');
  const [web3Contract, setWeb3Contract] = useState('');
  const [allBoxList, setAllBoxList] = useState([]);
  const [readList, setReadList] = useState([]);
  const [unreadList, setUnreadList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [rendered, setRendered] = useState(false);
  const dispatch = useDispatch();


  useEffect(() => {
    // Check if MetaMask is installed
    if (window.ethereum) {
      web3AccountCheck(setWeb3, setAccount);
    } else {
      console.log('MetaMask is not installed');
    }

    if (web3Contract) {
      setEmail();
    }

  }, []);

  useEffect(() => {
    if (account && web3Value) {
      const contractInstance = new web3Value.eth.Contract(hostContract.contract, web3Config.CONTRACT);
      setWeb3Contract(contractInstance);
    }
  }, [web3Value, account]);

  const getRefreshMessage = useSelector((state) => state.refreshMessage)
  useEffect(() => {
    if (web3Contract) { setEmail(); }
  }, [web3Contract, getRefreshMessage]);

  const refreshCount = useSelector((state) => state.refreshCount);
  const reRender = () => {
    dispatch({ type: "REFRESH_COUNT", payload: !refreshCount });
    setEmail();
  }


  async function setEmail(loaderRequired = true) {

    try {
      if (!web3Contract) return;

      if (loaderRequired) {
        setLoader(true);
        setRendered(false);
      }

      const emailList = await web3Contract.methods.getEmailList(user.name).call({ from: account });
      const sentEmailList = await web3Contract.methods.getSentEmailList(user.name).call({ from: account });
      const sentlist = await formatMessageList(sentEmailList.reverse(), "Senttrash");
      const updatedEmailList = await formatEmailDetails(emailList);
      const formattedResult = updatedEmailList
        .filter(email => email.senderName)
        .map(email => ({
          mailtype: "web3emails",
          id: parseInt(email.id),
          subject: email.subject,
          encryptedData: email.encryptedData,
          created_at: email.receivedDate,
          isStarred: email.isStarred,
          sender: email.senderName,
          isRead: email.isRead,
          header: email.emailDetails,
          isCC: email.isCC,
          isBCC: email.isBCC,
          ...email
        })).filter(data => !data.isSaved && data.isDeleted && !data.isArchive );
      const list = await formatMessageList(formattedResult.reverse(), "Trash");
      const web3 = list.allList;
      const allList = list.deletedList.concat(sentlist.deletedList)
      const web3list = web3.concat(allList).sort((a, b) => new Date(b.date) - new Date(a.date));
      const formattedresponse = await getWeb2emails(user.name, "Trash");
      const web2deletedemails = formattedresponse.filter((data) => {
        if (data.delete === 1 && data.delete !== 3) {
          return data
        }
        return null;
      })
      const sortEmails = (web3list, web2deletedemails) => {
        const combined = [...web3list, ...web2deletedemails];
        const sortedCombined = combined.sort((a, b) => {
          const dateA = new Date(a.date || a.receivedDate);
          const dateB = new Date(b.date || b.receivedDate);
          return dateB - dateA;
        });
        return sortedCombined;
      };
      const sortedresponse = sortEmails(web3list, web2deletedemails);
      setAllBoxList(sortedresponse);
      const readList = sortedresponse.filter(message => message.isRead);
      const unReadList = sortedresponse.filter(message => !message.isRead);
      setReadList(readList);
      setUnreadList(unReadList);
      if (loaderRequired) {
        setRendered(true);
      }

    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    if (rendered) {
      setLoader(false)
    }
  }, [rendered]);


  return (
    <div className="inbox-container">
      <CommonMessage messageList={allBoxList} pageType={"Trash"} unreadList={unreadList} readList={readList} loader={loader} reRender={reRender} />
    </div>
  );
}

export default Trash
