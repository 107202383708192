import CommonMessage from "../components/CommonMessage";
import React, { useEffect, useState, useMemo } from 'react';
import { getInsecure } from "../service/api-actions";
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";
import { formatMessageList } from '../helper/dataFormatHelper';
import web3Config from '../web3/web3Config.json';
import { getHostHelperContract } from "../helper/contract-helper.js";
const cookies = new Cookies();

const Trash = () => {
  const dispatch = useDispatch();
  const [user] = useState(cookies.get("userObject"));
  const [allBoxList, setAllBoxList] = useState([]);
  const [loader, setLoader] = useState(true);
  const getRefreshMessage = useSelector((state) => state.refreshMessage);
  const [readList, setReadList] = useState([]);
  const [unreadList, setUnreadList] = useState([]);
  const refreshCount = useSelector((state) => state.refreshCount);
  const [count, setCount] = useState(0);
  useEffect(() => {
    getInsecureData();
  }, [getRefreshMessage]);

  useEffect(() => {
    getInsecureData();
  }, []);

  useEffect(() => {
    const eventSource = new EventSource(`${web3Config.EMAIL_API}/events`);
    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const receivedUser = `${data.split("@")[0]}@${web3Config.DOMAIN}`;
      const messageList = document.getElementById("list-message-component");
      const replyModal = document.getElementById("reply-modal");
      const composeEmail = document.getElementById("compose-email-send");
      const userName = user.name;
      if (receivedUser === userName && messageList && !replyModal && !composeEmail) {
        getInsecureData(true);
      }
    };

    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };

  }, []);

  async function getInsecureData(autoFetch = false) {
    if (!autoFetch) { setLoader(true); }
    const insecureList = await getInsecure(user.name);
    const helperContract = getHostHelperContract();
    const attributes = await helperContract.methods.getAttributes(user.name).call();
    const formattedResult = await Promise.all(insecureList.res?.map(async (email) => {
      const data = JSON.parse(email.parts[0].data);
      const encrypted_data = data.encrypted_data;
      const emailDetails = JSON.parse(data.email_details[2]);
      const sender = emailDetails.sender;
      const subject = emailDetails.subject;
      const isRead = email.parts[0].status !== 0;
      const isFav = email.parts[0].fav === 1 ? true : false;
      const isArchive = email.parts[0].is_achieve;
      const deleteemails = email.parts[0].deleted_flag;
      const physmessage_id = email.physmessage_id;
      attributes.filter((att) => {
        return parseInt(att.id) === parseInt(email.physmessage_id);
      });


      return {
        mailtype: "web2emails",
        created_at: email.parts[0].internal_date,
        receivedDate: email.parts[0].internal_date,
        id: email.parts[0].message_id,
        encryptedData: encrypted_data,
        isStarred: isFav,
        sender: sender,
        senderName: sender,
        subject: subject,
        isRead: isRead,
        header: data.email_details[2],
        emailDetails: data.email_details[2],
        isCC: false,
        isBCC: false,
        isDeleted: false,
        isArchive,
        delete: deleteemails,
        physmessage_id,
        isfolder: (attributes.filter((att) => (att.attType === "folder") && parseInt(att.id) === parseInt(email.physmessage_id))[0])?.value ?? false,
      };
    }));
    const list = await formatMessageList(formattedResult, "Insecure");
    dispatch({ type: "ARICHVE_LIST", payload: list.allList });
    const filterReadList = list.readList.filter(data => data.delete !== 1 && data.delete !== 3 && data.isArchive !== true && data.isfolder !== true);
    const filterunreadlist = list.unReadList.filter(data => data.delete !== 1 && data.delete !== 3 && data.isArchive !== true && data.isfolder !== true);
    let responsemail = []
    for (let i = 0; i < list.allList.length; i++) {
      if (list.allList[i].isArchive === false) {
        responsemail.push(list.allList[i])
      }
    }

    const emailresponse = responsemail.filter((data) => {
      if (data.delete !== 1 && data.delete !== 3 && data.isfolder !== true) {
        return data
      }
      return null;
    })
    if (autoFetch && count < list.allList.length) {
      setAllBoxList(emailresponse);
      setReadList(filterReadList);
      setUnreadList(filterunreadlist);
    } else if (!autoFetch) {
      const readListAllEmails = emailresponse.filter(message => message.isRead);
      const unReadListAllEmails = emailresponse.filter(message => !message.isRead);
      setAllBoxList(emailresponse);
      setReadList(readListAllEmails);
      setUnreadList(unReadListAllEmails);
    }
    setCount(list.allList.length);
    dispatch({ type: "REFRESH_COUNT", payload: !refreshCount });
    setLoader(false)
  }
  const reRender = () => {
    dispatch({ type: "REFRESH_COUNT", payload: !refreshCount });
    getInsecureData();
  }

  const memoizedCommonMessage = useMemo(() => (
    <CommonMessage messageList={allBoxList} pageType={"Insecure"} unreadList={unreadList} readList={readList} loader={loader} reRender={reRender} />
  ), [allBoxList, unreadList, readList, loader]);

  return (
    <div className="inbox-container">
      {memoizedCommonMessage}
    </div>
  );
}

export default Trash