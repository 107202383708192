import React, { useState, useEffect, useRef } from "react";
import { logo } from "../../assets/svg/svg";
import { CompleteLoader, ReplyModal, FileReaderModel } from "../../modals/common-alert";
import Cookies from "universal-cookie";
import config from "../../web3/web3Config.json";
import hostContract from "../../web3/hostContract.json";
import moment from "moment-timezone";
import { markMessageAsImportant, web3AccountCheck, editEvent, createEvent, isEventExist, deleteEvent, updateEvent } from "../../helper/web3helper";
import { convertingTimeFormat, convertToUtc, formatDateTime, getEventPrams, updateICS, getEmailContents } from "../../helper/commonHelper.js"
import { convertToLocalDate, optionalValidation, convertToLocalDateforWeb2, getFirstLetterUppercase } from "../../helper/object-validation-helper";
import { downloadFile, getFile, getFileIcon } from "../../helper/attachmentHelper";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { deleteEmails } from "../../helper/emailHelper.js";
import { updateInsecureMail } from "../../service/api-actions.js";
import Swal from "sweetalert2";
import { toast, Bounce } from "react-toastify";
import { sendEmails } from '../../helper/emailHelper.js';
import '../../assets/css/calendar.css'
import { timeZone as staticTimezone } from '../../helper/settingsHelper.js';
import { parseICalendar } from '../../helper/inviteHelper.js';
import { repharseYahooMessage } from '../../helper/eventHelper.js';
import { Button, Popover } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { FileAudio2, FileVideo2 } from 'lucide-react';
const cookies = new Cookies();
const contractAddress = config.CONTRACT;

const Decrypt = (props) => {
    const { selectedMessage, decryptedContent, } = props;
    const filter = props.messageList.find(data => data.id === selectedMessage.id)
    const senderDetails = JSON.parse(selectedMessage?.header);
    const [replyModal, setReplyModal] = useState(false);
    const [messageType, setMessageType] = useState(null);
    const [disable, setDisable] = useState(false)
    const [web3Value, setWeb3] = useState(null);
    const [account, setAccount] = useState("");
    const [loader, setLoader] = useState(false);
    const [decryptFile, setDecryptFile] = useState(null);
    const [isOpenFileReader, setIsOpenFileReader] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [icons, setIcons] = useState({});
    const getFolderIndex = useSelector((state) => state.folderIndex);
    const [messageList, setMessageList] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const messageContainerRef = useRef(null);
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    const [fromstart, setFromStart] = useState();
    const [eventTimeZone, setEventTimeZone] = useState(null);
    const [toend, setToEnd] = useState();
    const [user] = useState(cookies.get("userObject"));
    const messageValue = JSON.parse(decryptedContent);
    const attachmentValue = messageValue.attachment || [];
    const [disabledButton, setDisabledButton] = useState(null);
    const [htmlContent, setHtmlContent] = useState('');
    const [isEventExistChain, setIsEventExist] = useState(true);
    const [organizer, setOrganizer] = useState(false);
    const isStarred = optionalValidation(selectedMessage, "isStarred");
    const starSvg = filter.isStarred ? logo.star_svg_fill : logo.star_svg_outline;
    const toOrFrom = props.pageType === "Sent" ? "To :" : "From :";
    const toOrFromName =
        props.pageType === "Sent"
            ? JSON.parse(selectedMessage.receiver).to[0]
            : selectedMessage.sender;
    const audioExtensions = ["mp3", "wav", "ogg", "flac"];
    const videoExtensions = ["mp4", "avi", "mkv"];
    const handleButtonClick = (buttonIndex) => {
        setDisabledButton(buttonIndex);
    };
    const handleCloseModal = () => {
        setReplyModal(false)
    }
    const deleteevent = async (event) => {
        const params = await getEventPrams(event, 'cancel', start, end);
        setLoader(true)
        const result = await deleteEvent(params?.uid, account, user.name.toString())
        if (result) {
            setDisable(true)
        }
        setLoader(false)
    }

    useEffect(() => {
        if (toOrFromName.split('@')[1] === 'yahoo.com' && messageValue.event && messageValue.message) {
            const rephareContent = repharseYahooMessage(messageValue.message, fromstart, toend)
            setHtmlContent(rephareContent);
        } else {
            setHtmlContent(messageValue.message);
        }

    }, [messageValue])

    useEffect(() => {
        if (!messageValue.event) {
            return;
        }
        const getOrganizer = messageValue.event.event.ORGANIZER?.value?.split(":")[messageValue.event.event.ORGANIZER?.value?.split(":").length - 1] || null;
        if (getOrganizer) {
            const organizerEmailHost = getOrganizer.split('@')[1];
            let email = null;
            if (organizerEmailHost === config.VERFIED_DOMAIN) {
                email = getOrganizer.replace(organizerEmailHost, config.DOMAIN).replace(/\s/g, '');
            } else {
                email = getOrganizer;
            }
            if (user.name.toString() === email) {
                setOrganizer(true);
            }
        }
        const timezone = messageValue.event.timezones?.END?.TZID || 'Etc/UTC';
        setEventTimeZone(timezone);
        const startEvent = messageValue.event.event.DTSTART.value || messageValue.event.event.DTSTART;
        const endEvent = messageValue.event.event.DTEND.value || messageValue.event.event.DTEND;
        const startTime = convertToUtc(startEvent, timezone);
        const endTime = convertToUtc(endEvent, timezone);
        const startTimeComponents = formatDateTime(startTime);
        const endTimeComponents = formatDateTime(endTime);
        const formatTime = ({ year, month, date, hour, minute }) => `${year}${month}${date}T${hour}${minute}00`;
        const eventStarts = formatTime(startTimeComponents);
        const eventEnds = formatTime(endTimeComponents);
        setStart(eventStarts);
        setFromStart(convertToLocalDate(startTime));
        setEnd(eventEnds);
        setToEnd(convertToLocalDate(endTime));
        checkEvent();
    }, [messageValue.event])

    const checkEvent = async () => {
        const uuid = messageValue.event.event.UID;
        if (uuid) {
            const getEvent = await isEventExist(user.name.toString(), uuid, account);
            if (getEvent) {
                setIsEventExist(true);
            } else {
                setIsEventExist(false);
            }
        }
    }

    const handleEventdata = async (acceptValue) => {
        try {
            let icsFile = null;
            let event = null;
            if (attachmentValue.length > 0) {
                for (const attachment of attachmentValue) {
                    const firstICSFile = Object.keys(attachment).find(file => file.endsWith('.ics'));
                    if (firstICSFile) {
                        const decryptedFile = await getFile(firstICSFile, attachment[firstICSFile]);
                        if (decryptedFile) {
                            const getICSfile = await updateICS(decryptedFile, user.name.toString(), acceptValue);
                            icsFile = getICSfile.file;
                            event = await parseICalendar(getICSfile.fileContent);
                        }
                        break;
                    }
                }
            }
            if (!icsFile) {
                toast.error("Failed to get ics file", {
                    position: "top-center",
                    transition: Bounce,
                });
                return;
            }
            const params = await getEventPrams(messageValue.event, acceptValue, start, end);
            let isDuplicate = false;
            let existingEventId = null;

            let existingParentEvent = null
            if (isEventExistChain) {
                isDuplicate = true;
                existingEventId = messageValue.event.event.UID;
                existingParentEvent = await isEventExist(user.name.toString(), messageValue.event.event.UID, account);
            }

            if (params?.singleEvent) {
                isDuplicate = false;
                const existingEvent = await isEventExist(user.name.toString(), params?.uid, account);
                if (existingEvent) {
                    isDuplicate = true;
                    existingEventId = params?.uid;
                }
            }
            if (isDuplicate) {
                try {
                    const functionParams = [existingEventId, params, user.name.toString()];
                    const shareCalendarparams = {
                        uid: params.uid,
                        fromTime: params.fromTime,
                        toTime: params.toTime,
                        account,
                        type: "update",
                    }
                    // updateEvent(shareCalendarparams)
                    const hash = await editEvent("editEvent", functionParams, account, user.name.toString());
                    if (hash) {
                        const convertedSubject = await getEmailContents(selectedMessage.subject, acceptValue);

                        const result = await sendEmails([toOrFromName], [], [], convertedSubject, htmlContent, false, 'MSG', [icsFile], event)
                        if (result) {
                            toast.success("Event updated successfully", {
                                position: "top-center",
                                transition: Bounce,
                            });
                        } else {
                            toast.error("Faild to invite the guests", {
                                position: "top-center",
                                transition: Bounce,
                            });
                        }
                    } else {
                        toast.error("Faild to updated event", {
                            position: "top-center",
                            transition: Bounce,
                        });
                    }

                } catch (error) {
                    setDisabledButton();
                    console.error("Error updating event:", error);
                    toast.error(`Failed to update event: ${error.message}`, {
                        position: "top-center",
                        transition: Bounce,
                    });
                }
            } else {
                try {
                    const inputcontact = [""]
                    const functionParams = [params, user.name.toString(), inputcontact];
                    const hash = await createEvent("addEvent", functionParams, account, user.name.toString());
                    if (hash) {
                        const shareCalendarparams = {
                            uid: params.uid,
                            fromTime: params.fromTime,
                            toTime: params.toTime,
                            account,
                            type: "update",
                        }
                        if (params?.singleEvent && existingParentEvent) {
                            const listings = [
                                ...(existingParentEvent?.list || []),
                                { key: 'singleEvent', value: params?.recurrence_id }
                            ].map(({ key, value }) => ({ key, value }));

                            existingParentEvent.list = listings;
                            const updatedEventObject = Object.fromEntries(
                                Object.entries(existingParentEvent)
                                    .filter(([key]) => isNaN(Number(key)) && key !== '__length__')
                            );
                            const functionParams = [messageValue.event.event.UID, updatedEventObject, user.name.toString()];
                            await editEvent("editEvent", functionParams, account, user.name.toString());
                        }
                        const convertedSubject = await getEmailContents(selectedMessage.subject, acceptValue)
                        const result = await sendEmails([toOrFromName], [], [], convertedSubject, htmlContent, false, 'MSG', [icsFile], event);
                        if (result) {
                            toast.success("Event added successfully", {
                                position: "top-center",
                                transition: Bounce,
                            });
                        } else {
                            toast.error("Faild to invite the guests", {
                                position: "top-center",
                                transition: Bounce,
                            });
                        }
                    } else {
                        toast.error("Faild to added event", {
                            position: "top-center",
                            transition: Bounce,
                        });
                    }
                } catch (error) {
                    setDisabledButton();
                    console.error("Error adding event:", error);
                    toast.error(`Failed to add event: ${error.message}`, {
                        position: "top-center",
                        transition: Bounce,
                    });
                }
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoader(false);
        }
    };

    // const handledelete = () => {};
    const toggleText = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        // Check if MetaMask is installed
        if (window.ethereum) {
            web3AccountCheck(setWeb3, setAccount);
        } else {
            console.log("MetaMask is not installed");
        }
    }, []);
    useEffect(() => {
        setMessageList(props.messageList);
    }, [props.messageList]);

    useEffect(() => {
        if (!isOpenFileReader) {
            const model = document.querySelector(".ant-modal-wrap");
            if (model) {
                model.style.display = "none";
            }
        }
    }, [isOpenFileReader]);

    useEffect(() => {
        const value = JSON.parse(decryptedContent);
        const fetchIcons = async () => {
            const newIcons = {};
            if (value.attachment && value.attachment.length) {
                for (const attachment of value.attachment) {
                    const type = Object.keys(attachment)[0];
                    const icon = await getFileIcon(type);
                    newIcons[type] = icon;
                }
            }
            setIcons(newIcons);
        };

        fetchIcons();
        const prefersDarkScheme = window.matchMedia(
            "(prefers-color-scheme: dark)"
        ).matches;
        if (prefersDarkScheme) {
            document
                .querySelectorAll('.primary-text:not([class^="x_"])')
                .forEach((el) => {
                    el.style.setProperty("color", "#3c4043", "important");
                });
            document
                .querySelectorAll('.secondary-text:not([class^="x_"])')
                .forEach((el) => {
                    el.style.setProperty("color", "#70757a", "important");
                });
        }
    }, [props.decryptedContent]);

    const checkHeight = () => {
        if (messageContainerRef.current) {
            const container = messageContainerRef.current;
            if (container.scrollHeight > 200) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };
    useEffect(() => {

    }, [messageType])
    async function emailActionFunction(type) {

        await setMessageType(type);
        switch (type) {
            case "Reply":
            case "ReplyAll":
            case "Forward":
                setReplyModal(true);
                break;

            case "Starred": {
                setLoader(true);
                if (
                    selectedMessage &&
                    selectedMessage.id &&
                    (props.pageType === "Inbox" || props.pageType === "Important")
                ) {
                    const contractMethods = new web3Value.eth.Contract(
                        hostContract.contract,
                        contractAddress
                    );
                    const obj = [parseInt(selectedMessage.id)];
                    const value = filter.isStarred;
                    console.log(contractMethods, user.name, obj, value, account, "contractMethods, user.name, obj, value, account")
                    await markMessageAsImportant(
                        contractMethods,
                        user.name,
                        obj,
                        value,
                        account
                    );
                    props.reRender();
                } else if (
                    selectedMessage &&
                    selectedMessage.id &&
                    props.pageType === "Insecure"
                ) {
                    await updateInsecureMail("fav", [selectedMessage.id], "");
                    props.reRender();
                }
                setLoader(false);
                break;
            }

            default:
                break;
        }
    }

    const renderTime = (time) => {
        const formattedDate = moment.tz(time, moment.tz.guess()).format('ddd MMM DD YYYY hh:mm A');
        return formattedDate;
    }
    const content = () => {

        return <div>
            <div>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <tbody>
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px", display: "flex", alignItems: 'start', justifyContent: "end" }}>from:</th>
                            <td>{selectedMessage.sender}</td>
                        </tr>
                        <tr>
                            {senderDetails.to && <>
                                <th style={{ textAlign: "left", paddingRight: "10px", display: "flex", alignItems: 'start', justifyContent: "end" }}>to:</th>
                                <td>
                                    <ul style={{ paddingLeft: "0", marginBottom: "5px" }}>
                                        {senderDetails.to?.map((data, index) => (
                                            <li key={index} style={{ listStyle: "none" }}>
                                                {data}
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                            </>}

                        </tr>
                        <tr>
                            {senderDetails.subject && <>
                                <th style={{ textAlign: "left", paddingRight: "10px", display: "flex", alignItems: 'start', justifyContent: "end" }}>subject:</th>
                                <td style={{ width: "300px", wordBreak: "break-word" }}>{selectedMessage.subject}</td>
                            </>}
                        </tr>
                        {senderDetails.cc.length > 0 && senderDetails.cc[0].length > 0 && <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px", display: "flex", alignItems: 'start', justifyContent: "end" }}>cc:</th>
                            <td>
                                <ul style={{ paddingLeft: "0", marginBottom: "5px" }}>
                                    {senderDetails.cc.map((data, index) => (
                                        <li key={index} style={{ listStyle: "none" }}>
                                            {data}
                                        </li>
                                    ))}
                                </ul>
                            </td>
                        </tr>}
                        {senderDetails.bcc.length > 0 && senderDetails.bcc[0].length > 0 && <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px", display: "flex", alignItems: 'start', justifyContent: "end" }}>bcc:</th>
                            <td>
                                <ul style={{ paddingLeft: "0", marginBottom: "5px" }}>
                                    {senderDetails && senderDetails.bcc.map((data, index) => (
                                        <li key={index} style={{ listStyle: "none" }}>
                                            {data}
                                        </li>
                                    ))}
                                </ul>
                            </td>
                        </tr>}
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px", justifyContent: "end", display: "flex" }}>date:</th>
                            <td>{renderTime(selectedMessage.date)}</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px", justifyContent: "end", display: "flex" }}>mailed-by:</th>
                            <td>{senderDetails.sender.split("@")[1]}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    }
    function processHtmlContent(htmlContent) {
        if (!htmlContent) return "";
        const div = document.createElement("div");
        div.innerHTML = htmlContent;
        const links = div.querySelectorAll("a");
        links.forEach((link) => {
            link.setAttribute("target", "_blank");
            link.setAttribute("rel", "noopener noreferrer");
        });
        return div.innerHTML;
    }
    return (
        <div className="encrypted-message-parent-ele">
            <div className="encrypted-message-header-ele">
                <div className="topic-ele">
                    <p> Topic :</p>
                    <h2> {selectedMessage.subject} </h2>
                </div>

                <div className="actions cursor-pointer">
                    <Tooltip title="Starred" placement="top">
                        <div onClick={() =>
                            emailActionFunction("Starred")}>{starSvg}</div>
                    </Tooltip>
                    <Tooltip title="Reply all">
                        <div onClick={() => emailActionFunction("ReplyAll")}>{logo.reply_all_svg}</div>
                    </Tooltip>
                    <Tooltip title="Reply">
                        <div onClick={() => emailActionFunction("Reply")}>{logo.reply_svg}</div>
                    </Tooltip>
                    <Tooltip title="Forward">
                        <div onClick={() => emailActionFunction("Forward")}>{logo.forward_svg}</div>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <div
                            className="cursor-pointer"
                            onClick={async () => {
                                Swal.fire({
                                    text: "Are you sure you want to delete?",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#1677ff",
                                    cancelButtonColor: "#ffffff",
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "No",
                                    customClass: "compose-sweet-alert",
                                }).then(async (result) => {
                                    if (result.isConfirmed) {
                                        setLoader(true);
                                        if (props.pageType !== "Insecure") {

                                            const folderIndex = getFolderIndex;
                                            await deleteEmails(selectedMessage, selectedMessage.id, props.selectedIndex, messageList, props.pageType, folderIndex);

                                        }
                                        else {
                                            const value = [{
                                                id: selectedMessage.id,
                                                value: 1
                                            }]
                                            await updateInsecureMail(
                                                "bulkdelete",
                                                "",
                                                value
                                            );
                                        }
                                        props.reRender();
                                        props.setSelectedMessageIndex(null);
                                        setLoader(false);
                                    }
                                });
                            }}
                        >
                            {logo.msg_delete_icon}
                        </div>
                    </Tooltip>
                </div>
            </div >

            <div className="encrypted-message-header-ele" >
                <div className="topic-ele">
                    <p>{toOrFrom}</p>
                    <div className="user-details-section child-ele-encrypt">
                        <div className="user-profile-pic">
                            {getFirstLetterUppercase(toOrFromName)}
                        </div>
                        <div className="user-profile-name">
                            <div className="user-profile-username">
                                {toOrFromName?.split("@")[0]}
                            </div>
                            <div style={{ fontSize: "11px", display: "flex", alignItems: "center" }}>
                                <span>to me</span>
                                {senderDetails.to && senderDetails.to.map((data, index) => {
                                    if (index < 2) {
                                        return (
                                            <div>
                                                <span>{", "}{data}</span>
                                            </div>
                                        )
                                    }
                                }
                                )
                                }
                                <Popover content={content} placement="bottom">
                                    <Button style={{
                                        padding: "0", border: "0"
                                    }}><CaretDownOutlined /></Button>
                                </Popover>

                            </div>
                            {/* <div className="user-profile-useremail">{toOrFromName}</div> */}
                        </div>
                    </div>
                </div>

                <div className="actions date-encrypt">
                    <h4>
                        {props.pageType === "Insecure"
                            ? convertToLocalDateforWeb2(selectedMessage.date)
                            : convertToLocalDate(selectedMessage.date)}
                    </h4>
                    <div> {logo.key_unlock_svg} </div>
                </div>
            </div>

            <div className="encrypted-msg-content decrypt">
                <div className="class-msg-component-render decrypt-msg">
                    {messageValue?.event && (
                        <div className="row border radius-4px">
                            <div
                                className="col-sm-2 d-flex justify-content-center align-items-center event-date flex-col"
                                style={{}}
                            >
                                <div className="cal-icon-view">
                                    <div className="calender-svg-icon">
                                        <svg width="75" height="75" viewBox="0 0 20 20" className=" w-full items-center justify-center">
                                            <defs>
                                                <linearGradient
                                                    id="gradientText"
                                                    x1="0%"
                                                    y1="0%"
                                                    x2="100%"
                                                    y2="100%"
                                                >
                                                    <stop offset="0%" style={{ stopColor: "#18F06E" }} />
                                                    <stop offset="100%" style={{ stopColor: "#18F06E" }} />
                                                </linearGradient>
                                            </defs>
                                            <path
                                                d="M18 3.496h-3.001v-.46a1 1 0 10-2 0v.46h-6v-.46a1 1 0 10-1.999 0v.46H2c-.633 0-1 .359-1 1v11.5c0 .641.367 1 1 1h16c.633 0 1-.359 1-1v-11.5c0-.641-.367-1-1-1z"
                                                fill="#ffffff"
                                            />
                                            <text
                                                x="50%"
                                                y="50%"
                                                dominantBaseline="middle"
                                                textAnchor="middle"
                                                fill="url(#gradientText)"
                                                fontWeight="bold"
                                                fontSize="4"
                                                dy=".3em"
                                            >
                                                {
                                                    convertToLocalDate(convertToUtc(messageValue.event.event.DTSTART.value, eventTimeZone)).split(
                                                        /[\s,]/
                                                    )[1]
                                                }
                                            </text>
                                        </svg>
                                    </div>
                                    <div className="text-light d-flex w-full align-items-center justify-content-center">
                                        {
                                            convertToLocalDate(convertToUtc(messageValue.event.event.DTSTART.value, eventTimeZone)).split(
                                                /[\s,]/
                                            )[0]
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm pt-2">
                                <p className="my-auto font-size-15 fw-bold">
                                    {messageValue.event.event.SUMMARY?.value || messageValue.event.event.SUMMARY}
                                </p>
                                <div className="row d-flex justify-content-center align-items-center mt-2">
                                    <div className="col-sm-1 w-auto">{logo.time_svg}</div>
                                    <div className="col-sm">
                                        <p
                                            className="my-auto font-size-11"
                                            style={{ color: "#979ea8" }}
                                        >
                                            When
                                        </p>
                                        <p className="my-auto fw-bold font-size-13">
                                            {fromstart} - {toend}
                                        </p>
                                    </div>
                                </div>
                                {(messageValue.event.event.ATTENDEES.length) && (
                                    <div className="row d-flex justify-content-center align-items-center mt-2">
                                        <div className="col-sm-1 w-auto">{logo.people_svg}</div>
                                        <div className="col-sm">
                                            <p className="my-auto font-size-11" style={{ color: "#979ea8" }}>
                                                Who
                                            </p>
                                            <p className="my-auto fw-bold font-size-13">
                                                {`${messageValue.event.event.ATTENDEES.length} guests invited`}
                                            </p>
                                        </div>
                                    </div>
                                )}
                                {!organizer &&
                                    <div className="col-sm border-top d-flex gap-1 mt-2 mb-1">
                                        <span
                                            className="my-auto font-size-11"
                                            style={{ color: "#979ea8" }}
                                        >
                                            Are you going?
                                        </span>

                                        {(messageValue.event.event.STATUS === "CANCELLED" || messageValue.event.method === "CANCEL") ? (<>
                                            <div
                                                className="btn-group btn-group-toggle border mt-1 ml-2"
                                                data-toggle="buttons"
                                            >
                                                <button disabled={disable}
                                                    className="btn btn-light deleteEvent"
                                                    onClick={() => { deleteevent(messageValue.event) }}

                                                >Update a calendar</button>
                                            </div>

                                        </>) : (<div
                                            className="btn-group btn-group-toggle border mt-1 ml-2"
                                            data-toggle="buttons"
                                        >
                                            <button
                                                className="btn btn-light"
                                                onClick={async () => {
                                                    setLoader(true);
                                                    handleButtonClick(1);
                                                    await handleEventdata('accept');
                                                    setLoader(false);
                                                }}
                                                disabled={disabledButton === 1}
                                            >
                                                Yes
                                            </button>
                                            <button
                                                className="btn btn-light"
                                                style={{ borderLeft: "#dee2e6 solid 1px" }}
                                                onClick={async () => {
                                                    setLoader(true);
                                                    handleButtonClick(2);
                                                    await handleEventdata('maybe');
                                                    setLoader(false);
                                                }}
                                                disabled={disabledButton === 2}
                                            >
                                                Maybe
                                            </button>
                                            <button
                                                className="btn btn-light"
                                                style={{ borderLeft: "#dee2e6 solid 1px" }}
                                                onClick={async () => {
                                                    setLoader(true);
                                                    handleButtonClick(3);
                                                    await handleEventdata('no');
                                                    setLoader(false);
                                                }}
                                                disabled={disabledButton === 3}
                                            >
                                                No
                                            </button>
                                        </div>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>
                    )}
                    <div>
                        <div
                            className={
                                isExpanded ? "email-contents mt-2 " : "email-contents truncated-text mt-2"
                            }
                            ref={messageContainerRef}
                            dangerouslySetInnerHTML={{
                                __html: htmlContent ? processHtmlContent(htmlContent) : "",
                            }}
                        />
                        {checkHeight() && (

                            <Tooltip title="More">
                                <button
                                    className="message_show_more_button cursor-pointer"
                                    onClick={toggleText}
                                >
                                    {logo.show_more_svg}
                                </button>
                            </Tooltip>
                        )}
                    </div>

                    {attachmentValue && (
                        <div className="attachment-msg-section">
                            {attachmentValue.map((attachment, index) => (
                                <div
                                    className="attachment-div-element d-flex-data "
                                    key={index}
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                    onClick={async () => {
                                        try {
                                            setLoader(true);
                                            const decryptedFile = await getFile(
                                                Object.keys(attachment)[0],
                                                attachment[Object.keys(attachment)[0]]
                                            );
                                            setDecryptFile(decryptedFile);
                                            setIsOpenFileReader(true);
                                            setLoader(false);
                                        } catch (e) {
                                            console.error("Error getting file:", e);
                                            setLoader(false);
                                        }
                                    }}
                                >
                                    <span className="file-name-preview text-center">
                                        {Object.keys(attachment)[0]}
                                    </span>


                                    {hoveredIndex !== index && (
                                        audioExtensions.includes(Object.keys(attachment)[0].split(".")[1]) ? (
                                            <div className="attachment-icon">
                                                <FileAudio2 width={70} height={70} strokeWidth="1px" />
                                            </div>
                                        ) : videoExtensions.includes(Object.keys(attachment)[0].split(".")[1]) ? (
                                            <div className="attachment-icon">
                                                <FileVideo2 width={70} height={70} strokeWidth="1px" />
                                            </div>
                                        ) : (
                                            <div className="attachment-icon">
                                                {icons[Object.keys(attachment)[0]] || ""}
                                            </div>
                                        )
                                    )}
                                    {hoveredIndex === index && (
                                        <>
                                            <div className="attachment-actions">
                                                <Tooltip title="Download">
                                                    <div
                                                        onClick={async (e) => {
                                                            e.stopPropagation();
                                                            setLoader(true);
                                                            await downloadFile(
                                                                Object.keys(attachment)[0],
                                                                attachment[Object.keys(attachment)[0]]
                                                            );
                                                            setLoader(false);
                                                        }}
                                                    >
                                                        {logo.download_svg_attachment}
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <CompleteLoader isOpen={loader} />
                <ReplyModal isModalOpen={replyModal} close={handleCloseModal} attachmentValue={attachmentValue} msg={selectedMessage} decryptedMessage={htmlContent} messageType={messageType} pageType={props.pageType} />
                {isOpenFileReader && (
                    <FileReaderModel isOpen={isOpenFileReader} file={decryptFile} close={() => { setDecryptFile(null); setIsOpenFileReader(false); }} />
                )}
            </div>
        </div >
    );
};

export default Decrypt;
