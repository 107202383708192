import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import "../components/styles/messagebox.css"
import { logo } from "../assets/svg/svg"
const NoInternetPage = () => {
    const [isOffline, setIsOffline] = useState(!navigator.onLine);

    useEffect(() => {
        const handleOnline = () => setIsOffline(false);
        const handleOffline = () => setIsOffline(true);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    if (!isOffline) {
        return null;
    }
    const handleReload = () => {
        if (!navigator.onLine) {
            window.location.href = window.location.href;
        } else {
            window.location.reload();
        }
    }
    return (
        <Modal open={isOffline}
            footer={null}
            closable={false}
            centered={false}
            style={{ top: '40%' }}>
            <div className='noInternetdiv'>
                <div style={{ marginBottom: "10px" }}>
                    {logo.internet_logo}
                </div>
                <h1 className='title'>
                    No Internet Connection</h1>
                <p className='message' >
                    It looks like you're offline. Please check your internet connection.
                </p>
                <Button onClick={() => handleReload()} style={{ padding: "0" }} >
                    <span style={{ fontSize: "15px", fontWeight: "bold", color: "white", border: "0", padding: "5px", borderRadius: "5px" }} className='text' >Try Again</span>
                </Button>
            </div >
        </Modal >
    );
};



export default NoInternetPage;