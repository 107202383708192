import Web3 from 'web3';
import { ethers } from 'ethers';
import { log } from "../service/api-actions.js";
import web3Config from "../web3/web3Config.json";
import helperABI from "../web3/helperContract.json";
import contactABI from "../web3/contactContract.json";
import calenderABI from "../web3/calendarContract.json";
import hostContractABI from "../web3/hostContract.json";
import db from '../db/db-service.js';
const web3 = new Web3(window.ethereum);

const contractDetails = {
    "calendar": {
        contractId: web3Config.CALENDAR_CONTRACT,
        contractABI: calenderABI.contract,
    },
    "contact": {
        contractId: web3Config.CONTACT_CONTRACT,
        contractABI: contactABI.contract,
    },
    "helper": {
        contractId: web3Config.HELPER,
        contractABI: helperABI.contract,
    },
    "hostContract": {
        contractId: web3Config.CONTRACT,
        contractABI: hostContractABI.contract,
    }
}

// Common transaction method for all the write operation
export const transactionAction = async (contract, functionName, functionParams, senderAccount, contractType, extraContractId = undefined) => {
    try {
        const organizationData = await db.table('organizationData').toArray();
        if (organizationData?.length > 0 && organizationData[0]?.uuid) {
            // Use ethers.js for organizationId case
            const provider = new ethers.JsonRpcProvider(web3Config.CHAIN_DETAILS.rpcUrls[0]);
            const senderWallet = new ethers.Wallet(web3Config.PRIVATE_KEY, provider);

            let contractId = undefined;
            let contractABI = undefined;
            if(extraContractId) {
                contractId = extraContractId;
                contractABI = contractType;
            } else {
                contractId = contractDetails[contractType].contractId;
                contractABI = contractDetails[contractType].contractABI;
            }

            const contractWithWallet = new ethers.Contract(contractId, contractABI, senderWallet);
            try {
                const tx = await contractWithWallet[functionName](...functionParams, {
                    gasLimit: ethers.parseUnits('1000000', 'wei'),  // Use a reasonable default or estimate
                });
                const receipt = await tx.wait();
                console.log('Transaction successful:', receipt?.hash);
                return receipt?.hash;
            } catch (error) {
                console.error("Transaction failed:", error);
                return null;
            }
        } else {
            let gasLimit = undefined;
            // get estimated gas for the transaction
            await contract.methods[functionName](...functionParams).estimateGas({ from: senderAccount }).then(function (gasAmount) {
                gasLimit = gasAmount;
            })
                .catch(function (error) {
                    console.log("gasLimit error", error)
                });
            // Get current gas price from the network
            const gasPrice = await web3.eth.getGasPrice();

            // Send the transaction
            const transaction = await contract.methods[functionName](...functionParams).send({ from: senderAccount, gas: gasLimit, gasPrice: gasPrice });

            // Get transaction receipt
            const receipt = await web3.eth.getTransactionReceipt(transaction.transactionHash);

            // Extract transaction hash
            const txHash = receipt.transactionHash;
            await log(txHash, 'transaction');
            return txHash;
        }
    } catch (error) {
        console.log("error", error)
        await log(JSON.stringify(error));
        return null;
    }
}


export const getEstimateGasFee = async(contract, functionName, functionParams, senderAccount)=> {
    const gasLimit =  await contract.methods[functionName](...functionParams).estimateGas({ from: senderAccount });
    return gasLimit;
}