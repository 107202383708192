import { createStore } from "redux";

const initialState = {
    searchTerm: "",
    filterType: false,
    sortType: "ALPHABET",
    draftData: null,
    refreshMessage: false,
    refreshCount: false,
    outBoxData: false,
    outBoxIndex: null,
    isCompose: false,
    refreshSettings: false,
    isMessageInclude: true,
    folderIndex: null,
    composeMin: false,
    selectedType: null, // 1 : "All" , 2 : "All Read" , 3 : "All Unread" , 4 : "All with Star"
    selectedMessage: false,
    hideSideBar: false,
    pagiClickRight: false,
    pagiClickLeft: false,
    messagesPerPage: 50,
    currentPage: 1,
    addressList: [],
    selectedMessageList: [],
    filterObject: {},
    trigger: false, // To trigger the filter object in the reducer
    insecuremail: [],
    bulkDelete: [],
    unreadcount: 0,
    profileName: "",
    organizationData: {},
    foldersList: [],
    folderUpdate: false,
};

const createReducer = (state = initialState, action) => {

    switch (action.type) {
        case "SEARCH_TERM":
            return { ...state, searchTerm: action.payload };
        case "FILTER_TYPE":
            return { ...state, filterType: action.payload };
        case "SORT_TYPE":
            return { ...state, sortType: action.payload };
        case "DRAFT":
            return { ...state, draftData: action.payload };
        case "REFRESH_MESSAGE":
            return { ...state, refreshMessage: action.payload };
        case "REFRESH_COUNT":
            return { ...state, refreshCount: action.payload };
        case "OUTBOX":
            return { ...state, outBoxData: action.value, outBoxIndex: action.payload };
        case "IS_COMPOSE":
            return { ...state, isCompose: action.payload };
        case "REFRESH_SETTINGS":
            return { ...state, refreshSettings: action.payload };
        case "IS_INCLUDE_MESSAGE":
            return { ...state, isMessageInclude: action.payload };
        case "FOLDER_INDEX":
            return { ...state, folderIndex: action.payload };
        case "COMPOSE_MIN":
            return { ...state, composeMin: action.payload };
        case "SELECT_TYPE":
            return { ...state, selectedType: action.payload };
        case "SELECT_MESSAGE":
            return { ...state, selectedMessage: action.payload };
        case "HIDE_SIDE_BAR":
            return { ...state, hideSideBar: action.payload };
        case "PAGI_CLICK_RIGHT":
            return { ...state, pagiClickRight: action.payload };
        case "PAGI_CLICK_LEFT":
            return { ...state, pagiClickLeft: action.payload };
        case "CURRENT_PAGE":
            return { ...state, currentPage: action.payload };
        case "MESSAGE_PER_PAGE":
            return { ...state, messagesPerPage: action.payload };
        case "ADDRESS_LIST":
            return { ...state, addressList: action.payload };
        case "SELECTED_MESSAGE_LIST":
            return { ...state, selectedMessageList: action.payload };
        case "FILTER_OBJECT":
            return { ...state, filterObject: action.payload, trigger: action.value };
        case "ARICHVE_LIST":
            return { ...state, insecuremail: action.payload };
        case "BULK_DELETE":
            return { ...state, bulkDelete: action.payload };
        case "UNREAD_COUNT":
            return { ...state, unreadcount: action.payload };
        case "PROFILE_NAME":
            return { ...state, profileName: action.payload };
        case "ORGANIZATION_DATA":
            return { ...state, organizationData: action.payload };
        case "FOLDERS_LIST":
            return { ...state, foldersList: action.payload };
        case "FOLDER_UPDATE":
            return { ...state, folderUpdate: action.payload }
        default:
            return state;
    }
};

const store = createStore(createReducer);

export default store;