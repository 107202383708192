import Web3 from "web3";
import { toast, Bounce } from "react-toastify";
import { transactionAction } from "./chain-helper";
import { requireAuth } from "../authMiddleware.js";
import { getCalendarContract } from './contract-helper.js'

export const web3AccountCheck = async (setWeb3, setAccount) => {
  if (window.ethereum) {
    const web3Instance = new Web3(window.ethereum);
    setWeb3(web3Instance);

    // Check if user is already connected
    window.ethereum
      .request({ method: "eth_accounts" })
      .then((accounts) => {
        if (accounts.length > 0) {
          setAccount(accounts[0]);
        }
      })
      .catch((err) => console.error(err));

    // Listen for account changes
    window.ethereum.on("accountsChanged", (accounts) => {
      requireAuth();
      setAccount(accounts[0] || "");
    });
  } else {
    console.log("MetaMask is not installed");
  }
};

export const getPublicKeyValue = async () => {
  try {
    const accounts = await window.ethereum.request({ method: "eth_accounts" });

    if (accounts.length) {
      const encryptionPublicKey = await window.ethereum.request({
        method: "eth_getEncryptionPublicKey",
        params: [accounts[0]],
      });
      return encryptionPublicKey;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const markMessageAsImportant = async (
  contract,
  userName,
  messageObject,
  value,
  account
) => {
  try {
    const functionParams = [
      userName,
      messageObject,
      [{key: 'isStarred', value: (!value).toString()}],
    ];
    const hash = await transactionAction(
      contract,
      "updateEmailAttributes",
      functionParams,
      account,
      'hostContract'
    );
    if (!hash) return false;
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getAccountBalance = async (web3, account) => {
  const balance = await web3.eth.getBalance(account);
  return web3.utils.fromWei(balance, "ether");
};

export const getEvents = async (user, account) => {

  try {
    const contract = getCalendarContract();
    const result = await contract.methods.getAllEvents(user).call({ from: account });
    return result;
  } catch (error) {
    console.error("Issue:", error);
    return null;
  }
};


export const editEvent = async (functionmethod, functionParams, account, user) => {

  try {
    const contract = getCalendarContract();
    const hash = await transactionAction(contract, functionmethod, functionParams, account, 'calendar')
    return hash;
  } catch (error) {
    console.error("Issue:", error);
    return null;
  }
}

export const createEvent = async (functionmethod, functionParams, account, user) => {
  try {
    const contract = getCalendarContract();
    const hash = await transactionAction(contract, functionmethod, functionParams, account, 'calendar')
    return hash;
  } catch (error) {
    console.error("Issue:", error);
    return null;
  }
}

export const deleteEvent = async (eventIndex, account, user) => {
  try {
    const contract = getCalendarContract();
    const params = [eventIndex, user];
    const hash = await transactionAction(
      contract,
      "deleteEvent",
      params,
      account,
      'calendar'
    );
    if (hash) {
      toast.success("Event updated successfully", {
        position: "top-center",
        transition: Bounce,
      });
    } else {
      toast.error("Faild to invite the guests", {
        position: "top-center",
        transition: Bounce,
      });
    }
  } catch (error) {
    console.error(error);
    toast.error("Error deleting event", {
      position: "top-center",
      transition: Bounce,
    });
  }
};


export const isEventExist = async (userName, uuid, account) => {
  try {
    const contract = getCalendarContract();
    const result = await contract.methods.getEvents(userName, uuid).call({ from: account });
    return result;
  } catch (error) {
    return null;
  }
}