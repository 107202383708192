import React, { useState, useEffect } from 'react';
import { logo } from '../assets/svg/svg';
import { connectWallet } from '../helper/wallet-helper';
import { useNavigate } from 'react-router-dom';
import { getUserContract } from '../helper/contract-helper';
import Cookies from "universal-cookie";
import { deleteDatabase } from '../db/db-helper';
import { login } from "../service/api-actions";
import { CompleteLoader } from '../modals/common-alert';
import { toast, Bounce } from 'react-toastify';
import dmailtumb from '../assets/images/dmailtumb.png';
import metatumb from '../assets/images/metatumb.jpg';
import playimg from '../assets/images/Play.jpg';
import { log } from "../service/api-actions.js";
import { getBrowserDetails } from '../helper/userActivity.js';
const cookies = new Cookies();


const WalletConnect = () => {
  const history = useNavigate(); // Get the history object
  const [loader, setLoader] = useState(false);
  const [isTutorial, setTutorial] = useState(false);
  const [isMetaTutorial, setMetaTutorial] = useState(false);

  const metaMaskLink = "https://metamask.io/download/";
  const faucetLink = "https://faucet.ncogchain.earth/";

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-lg-7 col-md-12 d-flex justify-content-center mb-4' style={{ textWrap: "nowrap" }}>
          <div className='parent-no-layout-containers common-header-ele metamask w-100'>
            <div className='common-header-ele-text text-center'>
              Connect Wallet
            </div>

            <div className='initial-div-ele-text justify-content-center text-center'>
              Connect your Metamask wallet here to launch our decentralized mail.
            </div>

            <div className='initial-div-ele-actions cursor-pointer text-center' onClick={async () => {
              setLoader(true);
              if (window.ethereum) {

                const walletAddress = await connectWallet();
                if (walletAddress) {

                  const contract = getUserContract();
                  if (walletAddress) {

                    const getUserDetailsForWallet = await contract.methods.getUserDetailsForWallet(walletAddress).call({ from: walletAddress });
                    console.log(getUserDetailsForWallet);
                    if (getUserDetailsForWallet.length) {
                      const userData = getUserDetailsForWallet[0];
                      const getToken = await login(userData.userName, userData.publicKey);
                      console.log(getToken);
                      if (getToken && getToken.isAuth && getToken.token) {
                        cookies.set("userObject", '', { path: "/", maxAge: 0 });
                        const userObject = { name: userData.userName, wallet: userData.walletAddress, publicKey: userData.publicKey };
                        cookies.set("userObject", userObject, { path: "/" });
                        cookies.set("accessToken", getToken.token, { path: "/" });
                        deleteDatabase();
                        // await log(await getBrowserDetails(), 'login');
                        history('/inbox');
                      } else if (!getToken?.isAccess && !getToken.isAuth) {
                        toast.error("You are not authorized to access this account. Please contact your administrator for assistance", {
                          position: "top-center",
                          transition: Bounce,
                          autoClose: 5000,
                        });
                      } else {
                        toast.error("Something went wrong", {
                          position: "top-center",
                          transition: Bounce,
                        });
                        window.open('/signup', '_self');

                        if (!toast.isActive("wentWrongErro")) {
                          toast.error("Something went wrong", {
                            toastId: "wentWrongErro",
                            position: "top-center",
                            transition: Bounce,
                          });
                        }
                      }
                    } else {
                      history('signup');
                    }
                  }
                } else {
                  if (!toast.isActive("errorToast")) {
                    toast.error("Please Connect Your Metamask Wallet", {
                      toastId: "errorToast",
                      position: "top-center",
                      transition: Bounce,
                    });
                  }
                }
              } else {
                if (!toast.isActive("installerror")) {
                  toast.error("Please Install Metamask", {
                    toastId: "installerror",
                    position: "top-center",
                    transition: Bounce,
                  });
                }
                window.open(metaMaskLink, "_blank");
              }
              setLoader(false);
            }}>
              {logo.meta_mask}
              <CompleteLoader isOpen={loader} />
            </div>
            <h4 className="meta-text text-center mt-1">METAMASK</h4>
            <button className='faucet-button mt-4' onClick={() => {
              window.open(faucetLink, "_blank");
            }}>
              <span>Get Free Test Tokens?<br />(NEC Faucet)</span>
            </button>
          </div>
        </div>

        <div className='col-lg-5 col-md-12' style={{ marginTop: '50px' }}>
          <div className='tutorial-text mt-3 text-start'>Tutorial <span className='mt-1'>{logo.info_icon_svg}</span></div>
          <div className='row justify-content-center'>
            <div className='video-section col-12 w-100 mt-4'>
              <div className='video-content w-100' style={{
                backgroundImage: !isMetaTutorial ? `url('${metatumb}')` : '',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}>
                {!isMetaTutorial &&
                  <span onClick={() => { setMetaTutorial(true) }} className='cursor-pointer d-block text-center'>
                    <img src={playimg} style={{ borderRadius: '50%' }} alt="Play" className="mx-auto" />
                  </span>
                }
                {isMetaTutorial &&
                  <iframe className='w-100' src="https://www.youtube.com/embed/A7sbpFvkEe0?autoplay=1" allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    title="Metamask Tutorial"></iframe>
                }
              </div>
              <p className='video-content-text text-center'>How to make metamask wallet</p>
            </div>

            <div className='video-section mt-3'>
              <div className='video-content w-100' style={{
                backgroundImage: !isTutorial ? `url('${dmailtumb}')` : '',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}>
                {isTutorial &&
                  <iframe className='w-100' src={`https://www.youtube.com/embed/qaqgmw3HQDA?autoplay=1`} allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    title="Dmail Tutorial"></iframe>
                }
                {!isTutorial &&
                  <span onClick={() => { setTutorial(true) }} className='cursor-pointer d-block mx-auto text-center'>
                    <img src={playimg} style={{ borderRadius: '50%' }} alt="Play" className="mx-auto" />
                  </span>
                }
              </div>
              <p className='video-content-text text-center'>How to create and use Dmail</p>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default WalletConnect;
