import React, { useState, useEffect, useMemo } from 'react';
import CommonMessage from "../components/CommonMessage";
import { web3AccountCheck } from '../helper/web3helper';
import hostContract from '../web3/hostContract.json';
import web3Config from '../web3/web3Config.json';
import { formatEmailDetails, formatMessageList } from '../helper/dataFormatHelper';
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from 'react-redux';
import { getHostHelperContract } from "../helper/contract-helper.js";

const cookies = new Cookies();

function Home() {
  const dispatch = useDispatch();
  const [user] = useState(cookies.get("userObject"));
  const [web3Value, setWeb3] = useState(null);
  const [account, setAccount] = useState('');
  const [web3Contract, setWeb3Contract] = useState('');
  const [allBoxList, setAllBoxList] = useState([]);
  const [readList, setReadList] = useState([]);
  const [unreadList, setUnreadList] = useState([]);
  const [loader, setLoader] = useState(true);
  const refreshCount = useSelector((state) => state.refreshCount);
  const userName = user.name;
  const [count, setCount] = useState(0);
  useEffect(() => {
    // Check if MetaMask is installed
    if (window.ethereum) {
      web3AccountCheck(setWeb3, setAccount);
    } else {
      console.log('MetaMask is not installed');
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const messageList = document.getElementById("list-message-component");
      const replyModal = document.getElementById("reply-modal");
      const composeEmail = document.getElementById("compose-email-send");
      if (window.ethereum && messageList && !replyModal && !composeEmail) {
        web3AccountCheck(setWeb3, setAccount);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);



  const handleClickOutside = async (event) => {
    if (event) {
      if (window.ethereum) {
        web3AccountCheck(setWeb3, setAccount);
      } else {
        console.log('MetaMask is not installed');
      }
      await setEmail();

    }
  };
  useEffect(() => {
    handleClickOutside(true);
  }, []);



  const getRefreshMessage = useSelector((state) => state.refreshMessage);
  useEffect(() => {
    handleClickOutside(true);
  }, [getRefreshMessage]);


  useEffect(() => {
    if (account && web3Value) {
      const contractInstance = new web3Value.eth.Contract(hostContract.contract, web3Config.CONTRACT);
      if (contractInstance) setWeb3Contract(contractInstance);
    }
  }, [web3Value, account]);


  useEffect(() => {
    if (web3Contract) {
      setEmail(true);
    }
  }, [web3Contract]);


  useEffect(() => {
    if (web3Contract) {
      setEmail();
    }
  }, []);


  async function setEmail(autoFetch = false) {
    try {
      if (!web3Contract) return;
      if (!autoFetch) { setLoader(true); }
      const emailList = await web3Contract.methods.getEmailList(userName).call({ from: account });
      const updatedEmailList = await formatEmailDetails(emailList);
      const formattedEmailList = updatedEmailList.filter(({ senderName }) => senderName)
        .map(({ id, subject, encryptedData, receivedDate, isStarred, senderName, isRead, emailDetails, isCC, isBCC, ...rest }) => ({
          id: parseInt(id),
          subject,
          encryptedData,
          receivedDate,
          created_at: receivedDate,
          isStarred: isStarred,
          sender: senderName,
          isRead,
          header: emailDetails,
          isCC,
          isBCC,
          mailtype: "web3emails",
          ...rest
        })).filter(data => data.attributes).filter(data => !data.attributes.value && !data.isDeleted && !data.isArchive && !data.folder && !data.isSaved );
      const { allList, readList, unReadList } = await formatMessageList(formattedEmailList.reverse(), "Inbox");
      if (autoFetch && count < emailList.length) {
        setAllBoxList(allList);
        setReadList(readList);
        setUnreadList(unReadList);
        dispatch({ type: "REFRESH_COUNT", payload: !refreshCount })
      } else if (!autoFetch) {
        setAllBoxList(allList);
        setReadList(readList);
        setUnreadList(unReadList);
        dispatch({ type: "REFRESH_COUNT", payload: !refreshCount })
      }
      setCount(emailList.length);
      setLoader(false)
    } catch (error) {
      console.log("error", error);
    }
  }


  const reRender = () => {
    dispatch({ type: "REFRESH_COUNT", payload: !refreshCount })
    setEmail();
  }

  const memoizedCommonMessage = useMemo(() => {
    return (
      <CommonMessage
        messageList={allBoxList}
        unreadList={unreadList}
        readList={readList}
        pageType={"Inbox"}
        loader={loader}
        reRender={reRender}
      />
    );
  }, [allBoxList, unreadList, readList, loader]);


  return (
    <div className="inbox-container">
      {memoizedCommonMessage}
    </div>
  );
}

export default Home;
