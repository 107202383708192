import React, { useState, useEffect, useRef } from 'react'
import { logo } from '../assets/svg/svg';
import { getHostContract, getHostHelperContract } from '../helper/contract-helper';
import Cookies from "universal-cookie";
import { permanentlyDeleteEmail, updateEmailAttributeValue } from "../helper/emailHelper.js"
import { markMessageAsImportant } from '../helper/web3helper';
import { useDispatch, useSelector } from 'react-redux';
import { CompleteLoader, ReplyModal } from '../modals/common-alert';
import ContextMenu from './context/ContextMenu';
import { convertToLocalDate, getFirstLetterUppercase, convertToLocalDateforWeb2 } from '../helper/object-validation-helper';
import { deleteEmails } from '../helper/emailHelper.js';
import { updateInsecureMail, getEncryptedMessage } from '../service/api-actions.js';
import { syncIndexedDB } from '../db/indexed-helper.js';
import contractData from '../web3/hostContract.json';
import config from '../web3/web3Config.json';
import { Tooltip } from 'antd';
import Web3 from 'web3';
import Swal from 'sweetalert2'
import { toast, Bounce } from 'react-toastify';
import NoMessage from './NoMessage';
import { calender_svg } from "../assets/svg/calender_svg.js";
import { getGeneralSettings } from '../helper/settingsHelper.js';
import db from '../db/db-service.js';
const cookies = new Cookies();

const Messages = (props) => {
    const [user] = useState(cookies.get("userObject"));
    const [messageList, setMessageList] = useState([]);
    const selectedValue = useSelector((state) => state.selectedType);
    const [loader, setLoader] = useState(false);
    const [folderList, setFolderList] = useState([]);
    const filteredMessage = useSelector((state) => state.selectedMessageList);
    const [contextMenuVisible, setContextMenuVisible] = useState(false); // State to manage context menu visibility
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 }); // State to manage context menu position
    const [archiveContent, setArchiveContent] = useState(false); // State to manage context menu visibility
    const [selectedContext, setContext] = useState(false);
    const [hoveredMessageIndex, setHoveredMessageIndex] = useState(null);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [message, setMessage] = useState(null);
    const [replyModal, setReplyModal] = useState(false);
    const [modalType, setModalType] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [checkedCollection, setCheckedCollection] = useState([]);
    const isClickableStar = ["Important", "Inbox", "Insecure"].includes(props.pageType);
    const isDragable = ["Important", "Inbox", "Sent"].includes(props.pageType);
    const [currentPage, setCurrentPage] = useState(1);
    const [messagesPerPage, setMessagesPerPage] = useState(50);
    const [openChildIndex, setOpenChildIndex] = useState([]);
    const dispatch = useDispatch();
    const dragImageRef = useRef(null);
    const pagiClickRight = useSelector((state) => state.pagiClickRight);
    const pagiClickLeft = useSelector((state) => state.pagiClickLeft);
    const contractAddress = config.CONTRACT;
    const [checkbox, setCheckBox] = useState({})
    const web3 = new Web3(window.ethereum);
    const [starred, setStarred] = useState()
    const contractMethods = new web3.eth.Contract(contractData.contract, contractAddress);
    const selectedType = useSelector((state) => state.selectedType);
    // Compute paginated messages
    const indexOfLastMessage = currentPage * messagesPerPage;
    const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;

    const currentMessages = props.messageList.slice(indexOfFirstMessage, indexOfLastMessage);
    useEffect(() => {
        setCurrentPage(1)
        dispatch({ type: "CURRENT_PAGE", payload: 1 });
    }, [props.messageList])
    const totalPages = Math.ceil(messageList.length / messagesPerPage);
    const contextMenuRef = useRef(null);
    // Pagination Controls
    const handlePageChange = (pageNumber) => {
        if (totalPages < pageNumber || pageNumber < 1) return;
        setCurrentPage(pageNumber);
        dispatch({ type: "CURRENT_PAGE", payload: pageNumber });
    };

    useEffect(() => {
        // setCurrentPage(1);
        // dispatch({ type : "CURRENT_PAGE",   payload :  1 } );
        setMessageList(props.messageList);
        fetchData();
    }, [props.messageList])
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (contextMenuRef.current && !contextMenuRef.current.contains(e.target)) {
                setArchiveContent(false); // Close context menu
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    useEffect(() => {
        setIncludeMessage();
        const dragImageElement = document.createElement('div');
        dragImageElement.className = 'drag-image';
        dragImageElement.innerText = 'move this conversation';
        document.body.appendChild(dragImageElement);
        dragImageRef.current = dragImageElement;

        return () => {
            document.body.removeChild(dragImageElement);
        };
    }, []);

    async function setIncludeMessage() {
        const settingsJson = await getGeneralSettings();
        const insecure = settingsJson.isIncludePrevious;
        dispatch({ type: "IS_INCLUDE_MESSAGE", payload: insecure });
    }

    async function fetchData() {
        const helperContract = getHostHelperContract();
        const folderRecords = await helperContract.methods.getFolders(user.name).call({ from: user.wallet });
        setFolderList(folderRecords);

    }

    const filterType = useSelector((state) => state.filterType);
    const parentClassName = filterType ? "sort-added" : "sort-inactive";

    const handleContextMenu = async (e, item) => {
        e.preventDefault();
        await fetchData();
        if (props.pageType === 'Trash') {
            setContextMenuVisible(true);
            setContextMenuPosition({ x: e.clientX, y: e.clientY });
            setContext(item);
        }
        if (props.pageType === 'Archive') {
            setContextMenuVisible(true);
            setContextMenuPosition({ x: e.clientX, y: e.clientY });
            setContext(item);
        }
        if (!isDragable) return true;
        setContextMenuVisible(true);
        setContextMenuPosition({ x: e.clientX, y: e.clientY });
        setContext(item);
    };


    // Close context menu
    const handleCloseContextMenu = () => {
        setContextMenuVisible(false);
    };


    const dragStart = (e, message, type) => {
        const dragImage = dragImageRef.current;
        if (dragImage) {
            e.dataTransfer.setDragImage(dragImage, 0, 0);
            e.dataTransfer.setData('message_id', message.id);
            e.dataTransfer.setData('message_type', type);
        }
    };


    const actionClick = async (message, type) => {

        if (props.pageType === "Draft") {
            return true;
        }
        setLoader(true);
        if (!message.decryptedMessage) {
            let userName = user?.name;
            const organizationValue = await db.table('organizationData').toArray();
            const { uuid, organizationData } = organizationValue[0] || {};    
            if (!uuid && organizationData?.primaryEmail !== userName ) { userName= null };
            const data = await getEncryptedMessage(message.encryptedMsg, userName);
            const encryptedMsg = data.returnValue ? data.returnValue : message.encryptedMsg;
            const functionNames = {
                "Sent": "updateEmailAttributes",
                "Inbox": "updateEmailAttributes",
            }
            try {
                let decryptedMsg = encryptedMsg;
                const accounts = await window.ethereum.request({ method: 'eth_accounts' });
                if (userName) {
                    decryptedMsg = await window.ethereum.request({ method: 'eth_decrypt', params: [encryptedMsg, accounts[0]] });
                }
                const obj = JSON.parse(decryptedMsg);
                if (message.emailType && message.emailType === 'Senttrash') {
                    syncIndexedDB(message.id, decryptedMsg, 'Senttrash', message);
                } else {
                    syncIndexedDB(message.id, decryptedMsg, props.pageType, message);
                }
                
                const functionParams = [user.name, [message.id], [{key:'isRead', value:'true'}]];
                if (
                    (props?.pageType === "Trash" && !message?.isRead && message?.emailType === "Senttrash") ||
                    (!message?.read && props?.pageType !== "Insecure")
                ) {
                    await updateEmailAttributeValue(...functionParams, 'hostContract');
                }
                  
                if (props.pageType === "Insecure" && !message.isRead) {
                    await updateInsecureMail('isRead', message.id, "");
                }
                if (obj.attachment) {
                    setAttachment(obj.attachment);
                }
                setMessage(obj.message);
            } catch (e) {
                setLoader(false);
                return true;
            }
        } else {
            if (message.decryptedMessage.attachment) {
                setAttachment(message.decryptedMessage.attachment);
            }
            setMessage(message.decryptedMessage.message)
        }
        setSelectedMessage(message);
        setLoader(false);
        setReplyModal(true);
        setModalType(type);
    }

    useEffect(() => {
        setAttachment([]);
    }, [replyModal])

    const fetchSenderReceiver = (message) => {
        if (props.pageType === "Sent") {
            try {
                const receiverObj = JSON.parse(message.receiver);
                const to = receiverObj.to.join(', ');
                return `${to}`;
            } catch (error) {
                console.error("Error parsing receiver JSON:", error);
                return '';
            }
        } else {
            return message.sender.split("@")[0];
        }
    };

    const getFolderIndex = useSelector((state) => state.folderIndex);
    const deleteMessage = async (message, msgId, index) => {
        Swal.fire({
            text: "Are you sure you want to delete?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1677ff",
            cancelButtonColor: "#ffffff",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: "compose-sweet-alert"
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoader(true);
                const folderIndex = getFolderIndex;
                await deleteEmails(message, msgId, index, messageList, props.pageType, folderIndex, true);
                props.reRender();
                props.setSelectedMessageIndex(null);
                setLoader(false);
            }
        });

    }
    const dragEnd = (e) => {
        // Reset cursor to default
        document.body.classList.remove('grabbing');
    };

    useEffect(() => {

        switch (selectedType) {

            case 1:
                const list1 = currentMessages.filter((item) => item.id).map((item) => item.id);
                // const list = currentMessages?.data.filter((item) => item.id).map((item) => item.id);
                const list = currentMessages?.map((item) => {
                    return (item.groupKey && Array.isArray(item.data))
                        ? item.data.filter(data => data.id)
                        : null;
                }).filter(Boolean);

                const result = list.map(item => item.map(item => item.id)).flat();
                setCheckedCollection([...list1, ...result]);
                dispatch({ type: "SELECTED_MESSAGE_LIST", payload: [...list1, ...result] });
                break;
            case 2:
                const list2 = currentMessages.filter((item) => item.isRead).map((item) => item.id);
                setCheckedCollection(list2);
                dispatch({ type: "SELECTED_MESSAGE_LIST", payload: list2 });
                break;
            case 3:
                const list3 = currentMessages.filter((item) => !item.isRead).map((item) => item.id);
                setCheckedCollection(list3);
                dispatch({ type: "SELECTED_MESSAGE_LIST", payload: list3 });
                break;
            case 4:
                const list4 = currentMessages.filter((item) => item.isStarred).map((item) => item.id)
                setCheckedCollection(list4);
                dispatch({ type: "SELECTED_MESSAGE_LIST", payload: list4 });
                break;

            default:
                setCheckedCollection([]);
                dispatch({ type: "SELECTED_MESSAGE_LIST", payload: [] });
                break;
        }
    }, [selectedType]);

    const handleChecked = (message) => {
        if (checkedCollection.includes(message.id)) {
            return true;
        }
    }

    const selectMessage = (id) => {

        if (checkedCollection.includes(id)) {
            const list = checkedCollection.filter((item) => item !== id);
            setCheckedCollection(list);
            dispatch({ type: "SELECTED_MESSAGE_LIST", payload: list });
        } else {
            setCheckedCollection([...checkedCollection, id]);
            dispatch({ type: "SELECTED_MESSAGE_LIST", payload: [...checkedCollection, id] });
        }
    }
    const getPageNumbers = () => {
        const pageNumbers = [];
        const totalPagesToShow = 5;

        if (totalPages <= totalPagesToShow) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            const startPage = Math.max(1, currentPage - Math.floor(totalPagesToShow / 2));
            const endPage = Math.min(totalPages, startPage + totalPagesToShow - 1);

            if (startPage > 1) {
                pageNumbers.push(1);
                if (startPage > 2) pageNumbers.push('...');
            }

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(i);
            }

            if (endPage < totalPages) {
                if (endPage < totalPages - 1) pageNumbers.push('...');
                pageNumbers.push(totalPages);
            }
        }
        return pageNumbers;
    };

    const handleItemsPerPageChange = (event) => {
        setMessagesPerPage(Number(event.target.value));
        dispatch({ type: "MESSAGE_PER_PAGE", payload: Number(event.target.value) });
        setCurrentPage(1);
        dispatch({ type: "CURRENT_PAGE", payload: 1 });
    };

    useEffect(() => {
        handlePageChange(currentPage + 1);
    }, [pagiClickRight])

    useEffect(() => {
        handlePageChange(currentPage - 1);
    }, [pagiClickLeft])
    useEffect(() => {
        handlePageChange(1);
    }, [])

    const reRender = () => {
        props.reRender();
    }

    const deleteLastThirty = () => {
        Swal.fire({
            text: "Are you sure you want to trash more than 30 days?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1677ff",
            cancelButtonColor: "#ffffff",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: "compose-sweet-alert"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const parseDate = (dateString) => new Date(dateString);
                const today = new Date();
                const thirtyDaysAgo = new Date(today);
                thirtyDaysAgo.setDate(today.getDate() - 30);
                const recentMessages = currentMessages.filter(message => {
                    const receivedDate = parseDate(message.receivedDate);
                    return receivedDate < thirtyDaysAgo;
                });
                if (!recentMessages.length) {
                    toast.info('No data found!', {
                        position: "top-center",
                        transition: Bounce,
                    });
                    return;
                }
                setLoader(true);
                await deleteEmails(recentMessages, null, null, null, 'emptyTrash', null);
                setLoader(false);
                props.reRender();
            }
        });
    }
    const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };
    async function deleteBulkEmails(inbox, __, value3, pagetype, getFolderIndex, functionName) {
        if (value3.length > 0) {
            const updatedResponse = [];
            value3.forEach((data) => {
                updatedResponse.push(data.messageId);
            });
            const chunks = chunkArray(updatedResponse, 25);
            for (const chunk of chunks) {
                let value = chunk;
                await functionName(inbox, value, null, value3, pagetype, getFolderIndex);
            }
        }
    }
    async function web2EmailsDelete(Emails, batchSize, type, functionName, resValue) {
        if (Emails.length > 0) {
            const updatedResponse = [];
            Emails.forEach((data) => {
                updatedResponse.push({
                    id: data.messageId || data.id,
                    value: resValue,
                });
            });
            const chunks = chunkArray(updatedResponse, batchSize);
            for (const chunk of chunks) {
                let value = chunk;
                await functionName(type, "", value);
            }
        }
    }
    const emptyTrash = () => {
        const selectedMessages = filteredMessage.map((message) => {
            const matchFound = messageList.find((data) => data.id === message);
            return matchFound;
        });
        const web3 = messageList.filter((data) => data.mailtype === "web3emails");
        const senttrash = messageList.filter((data) => data.emailType === "Senttrash");
        const web2 = messageList.filter((data) => data.mailtype === "web2emails");
        Swal.fire({
            text: "Are you sure you want to empty trash?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1677ff",
            cancelButtonColor: "#ffffff",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: "compose-sweet-alert"
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoader(true);
                await deleteBulkEmails(senttrash, null, senttrash, "Trash", null, deleteEmails)
                if (web3.length > 0) {
                    const updatedResponse = [];
                    web3.forEach((data) => {
                        updatedResponse.push(data.messageId);
                    });
                    const chunks = chunkArray(updatedResponse, 25);
                    for (const chunk of chunks) {
                        await permanentlyDeleteEmail(user.name, chunk, 'hostContract');
                    }
                }
                await web2EmailsDelete(web2, 50, "bulkdelete", updateInsecureMail, 3)
                setLoader(false);
                props.reRender();
            }
        });
    }
    const Archivemessage = async (e, message) => {
        e.preventDefault();
        if (props.pageType === "Insecure") {
            setArchiveContent(true);
            setContextMenuPosition({ x: e.clientX, y: e.clientY });
            setContext(message)
        }
    };

    const deleteweb2email = async (messageId) => {

        Swal.fire({
            text: "Are you sure you want to delete this email?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1677ff",
            cancelButtonColor: "#ffffff",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: "compose-sweet-alert"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const value = 1;
                    setLoader(true);
                    await updateInsecureMail('deleteemail', messageId, value);
                }
                catch (err) {
                    console.log(err);
                }
                finally {
                    setLoader(false);
                    props.reRender();
                }
            }
        });

    }
    const permanentdelete = async (messageid) => {
        Swal.fire({
            text: "Are you sure you want to empty trash?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1677ff",
            cancelButtonColor: "#ffffff",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: "compose-sweet-alert"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const value = 3;
                setLoader(true);
                await updateInsecureMail('deleteemail', messageid, value);
                setLoader(false);
                props.reRender();
            }
        });

    }
    const handleSentMailTrash = async (messageId) => {

        Swal.fire({
            text: "Are you sure you want to delete this email?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1677ff",
            cancelButtonColor: "#ffffff",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: "compose-sweet-alert"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setLoader(true);
                    await await deleteEmails(null, [messageId.id], null, [messageId], "Sent", null,)
                }
                catch (err) {
                    console.log(err);
                }
                finally {
                    setLoader(false);
                    props.reRender();
                }
            }
        });

    }


    const handleStart = (e, id) => {
        e.dataTransfer.setData('text', id);
    }
    const messageContents = (message, index) => {
        return (
            <div style={{ position: "relative" }} onDragStart={(e) => { handleStart(e, message.id) }} key={index} >
                <div className={'row-message-box-child' + ` ${message.read || message.isRead ? "Readed" : "Unreaded"} ${parentClassName}`} draggable={isDragable ? 'true' : 'false'} onDragStart={(e) => dragStart(e, message, props.pageType)} onDragEnd={dragEnd} onContextMenu={(e) => {
                    handleContextMenu(e, message)
                    Archivemessage(e, message)
                }} onMouseEnter={() => setHoveredMessageIndex(message.id)} onMouseLeave={() => setHoveredMessageIndex(null)} key={index}
                    onClick={(e) => {

                        if (props.pageType === "Draft") {
                            const draftJSON = message;
                            localStorage.setItem("recipient", draftJSON.to);
                            localStorage.setItem("topic", draftJSON.subject);
                            localStorage.setItem("cc", draftJSON.cc)
                            localStorage.setItem("bcc", draftJSON.bcc);
                            localStorage.setItem("sendingEmail", draftJSON.message);
                            dispatch({ type: "DRAFT", payload: JSON.stringify(message) });
                            return true;
                        } else if (props.pageType === "Outbox") {
                            dispatch({ type: "OUTBOX", payload: index, value: true });
                            return true;
                        }

                        props.setSelectedMessageIndex(index);
                        props.openMessage(message);

                        if (message.decryptedMessage) {
                            props.setIsDecrypted(true);
                            props.setDecryptedContent(JSON.stringify(message.decryptedMessage));
                        }
                    }}
                >
                    <div className='user-check-section' id='list-message-component'>
                        <div className='check-box-ele-user'>
                            <input type="checkbox" id="html" checked={handleChecked(message)} onClick={async (e) => { e.stopPropagation(); selectMessage(message.id); }} />
                        </div>
                        <div className='check-box-ele-user' style={{ cursor: isClickableStar ? 'pointer' : 'no-drop' }} onClick={async (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (props.pageType === "Insecure") {
                                setLoader(true);
                                await updateInsecureMail('fav', [message.id], "");
                                setLoader(false);
                                props.reRender();
                                return;
                            } else if (props.pageType === "Important" || props.pageType === "Inbox") {
                                setLoader(true);
                                const contractMethods = getHostContract();
                                const obj = [parseInt(message.id)];

                                const value = message.isStarred;
                                await markMessageAsImportant(contractMethods, user.name, obj, value, user.wallet);
                                setLoader(false);
                                props.reRender();
                            }
                            setLoader(false);
                        }}>
                            {message.isStarred &&
                                <Tooltip title="Starred" placement='bottom'>  <div className='start-icon-div'> {logo.star_svg_fill}  </div></Tooltip>
                            }

                            {!message.isStarred &&
                                <Tooltip title="Not Starred" placement='bottom'>    <div className='start-icon-div' > {logo.star_svg_outline}  </div></Tooltip>
                            }
                        </div>
                        {/* <div className="ncog-seperation"> | </div> */}
                    </div>

                    <div className='user-details-section message-section'>
                        <div className='user-profile-pic'>{getFirstLetterUppercase(fetchSenderReceiver(message))} </div>
                        <div className='user-profile-name inbox mail-username'>
                            <div className='user-profile-username'>{props.pageType === "Sent" ? "To: " : ""}{fetchSenderReceiver(message)}</div>
                            {props.pageType !== "Sent" &&
                                <div className='user-profile-useremail'>{message.sender}</div>
                            }
                        </div>
                        {/* <div className="ncog-seperation inbox-value"> | </div> */}
                    </div>

                    <div className={`user-subject-section ${message.read || message.isRead ? "Readed" : "Unreaded"}`}>
                        Topic : <span> {message.subject} </span>
                    </div>
                    <div className='user-encryption-section' onClick={(e) => {
                        e.stopPropagation();
                    }}>
                        <div className='user-date-section' style={hoveredMessageIndex === message.id ? { display: 'none' } : {}}>
                            {props.pageType === 'Insecure' ? convertToLocalDateforWeb2(message.date || message.created_at) : convertToLocalDate(message.date || message.created_at)}
                        </div>
                        {hoveredMessageIndex === message.id ?
                            <div className='hover-reply'>
                                {props.pageType !== "Draft" && props.pageType !== "Outbox" &&
                                    <>
                                        <Tooltip title="Reply all"><div onClick={() => { actionClick(message, "Reply"); }} > {logo.back_arrow_reply} </div></Tooltip>
                                        <Tooltip title="Reply"><div onClick={() => { actionClick(message, "Reply"); }} >  {logo.default_back_reply} </div></Tooltip>
                                        <Tooltip title="Forward"><div onClick={() => { actionClick(message, "Forward"); }} > {logo.forward_reply_svg} </div></Tooltip>
                                    </>
                                }
                                <Tooltip title="Delete">
                                    {message.mailtype === "web3emails" ?
                                        <div onClick={() => {
                                            deleteMessage(message, message.id, index);
                                        }}> {logo.delete_reply_svg} </div> :
                                        props.pageType === "Trash" ? <div onClick={() => {
                                            permanentdelete(message.id);
                                        }}> {logo.delete_reply_svg} </div> :
                                            props.pageType === "Sent" ? <div onClick={() => {
                                                handleSentMailTrash(message);
                                            }}> {logo.delete_reply_svg} </div> :
                                                <div onClick={() => {

                                                    deleteweb2email(message.id);
                                                }}> {logo.delete_reply_svg} </div>
                                    }
                                </Tooltip>
                            </div>
                            : (
                                <>
                                    <div className='lock-logo'>
                                        {message.decryptedMessage && logo.key_unlock_svg}
                                        {!message.decryptedMessage && logo.key_lock_svg}
                                    </div>
                                </>
                            )}
                    </div>
                </div >
            </div>
        )

    }
    const setChildIndex = (index) => {
        openChildIndex.includes(index) ? setOpenChildIndex(openChildIndex.filter(item => item !== index)) : setOpenChildIndex([...openChildIndex, index])
    }

    const checkReaded = (message) => {
        return message.data.every((item) => item.read);
    }
    useEffect(() => {
        const response = currentMessages.map((res, index) => {
            return res?.groupKey ? res.data.map(item => {
                return item.isStarred === false
            })
                : null;
        });
        if (response) {
            setStarred(response)
        }
    }, [props]);
    const handleCheckbox = (message) => {
        if (message?.groupKey && Array.isArray(message.data)) {
            const response = message.data.map(item => item.id);
            const allChecked = response.every(id => checkedCollection.includes(id));
            if (allChecked) {
                const updatedCollection = checkedCollection.filter(id => !response.includes(id));
                setCheckedCollection(updatedCollection);
                dispatch({ type: "SELECTED_MESSAGE_LIST", payload: updatedCollection });
            } else {
                const updatedCollection = [...checkedCollection, ...response.filter(id => !checkedCollection.includes(id))];
                setCheckedCollection(updatedCollection);
                dispatch({ type: "SELECTED_MESSAGE_LIST", payload: updatedCollection });
                setCheckBox((prevState) => ({
                    ...prevState,
                    [message.groupKey]: response.every(id => updatedCollection.includes(id)),
                }));
            }
        }
    };
    useEffect(() => {
        const initialGroupState = {};
        currentMessages?.forEach((message) => {
            if (message?.groupKey && Array.isArray(message.data)) {
                const response = message.data.map(item => item.id);
                const isAllGroupChecked = response.every(id => checkedCollection.includes(id));
                initialGroupState[message.groupKey] = isAllGroupChecked;
            }
        });
        setCheckBox(initialGroupState);
    }, [selectedType, checkedCollection]);

    return (
        <div >
            {props.pageType === 'Trash' &&
                <div className="card trash-header">
                    <p className="card-header d-flex align-center justify-content-center title">Delete Messages that have been in <span className='gradient-text cursor-pointer' onClick={() => { deleteLastThirty() }}>&nbsp;Trash more than 30 days&nbsp;</span>  or <span className='gradient-text cursor-pointer' onClick={() => { emptyTrash() }}>&nbsp;Empty Trash now&nbsp;</span></p>
                </div>
            }

            {currentMessages.map((message, index) => (
                < React.Fragment >
                    {!message.groupKey ? (
                        <div key={index} >{messageContents(message, index)}
                        </div>
                    ) : (
                        <div key={index}>
                            <div className={`row-message-box-child user-select-none +  ${checkReaded(message) ? "Readed" : "Unreaded"}`} onClick={() => { setChildIndex(index) }}>
                                <div className='user-check-section'>
                                    <span style={{ rotate: !openChildIndex.includes(index) ? '270deg' : '', marginLeft: '-20px' }}>{calender_svg.down_svg}</span>
                                    <div className='check-box-ele-user gap-10'>
                                        <input type="checkbox" id='html' checked={!!checkbox[message.groupKey]} onClick={async (e) => {
                                            e.stopPropagation();
                                            handleCheckbox(message)

                                        }} />
                                        {starred && starred[index] && (
                                            <div className='start-icon-div' key={index}>
                                                {starred[index].includes(false) ? logo.star_svg_fill : logo.star_svg_outline}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='user-details-section message-section'>

                                    <div className="user-profile-pic">{getFirstLetterUppercase(message.groupKey.split('___')[0] ?? '#')}</div>
                                    <div className='user-profile-name inbox mail-username'>
                                        <div className='user-profile-username'>{props.pageType === "Sent" ? `To: ${message.groupKey.split('___')[0] ?? ''}` : message.groupKey.split('___')[0].split('@')[0] ?? ''}{ }</div>
                                        {props.pageType !== "Sent" &&
                                            <div className='user-profile-useremail'>{message.groupKey.split('___')[0] ?? ''}</div>
                                        }
                                    </div>
                                </div>
                                <div className='user-subject-section group'>
                                    Topic : <span>{message.groupKey.split('___')[1] ?? ''}</span>
                                </div>
                                <div className='user-encryption-section'>
                                    <div className='user-date-section'>
                                        {props.pageType === 'Insecure' ? convertToLocalDateforWeb2(message.date) : convertToLocalDate(message.date)}
                                    </div>
                                </div>
                            </div>

                            {openChildIndex.includes(index) &&
                                <div className='group-emails'>
                                    {message.data.map((msg, ind) => {
                                        return <div>{messageContents(msg, ind, true)}</div>
                                    })}
                                </div>
                            }
                        </div>
                    )}
                </React.Fragment >
            ))
            }

            {/* Hidden drag image */}
            <div ref={dragImageRef} style={{ display: 'none' }}>
                <div className="drag-image">move this conversation</div>
            </div>
            <CompleteLoader isOpen={loader} />
            {
                (contextMenuVisible || archiveContent) && (
                    <ContextMenu
                        x={contextMenuPosition.x}
                        y={contextMenuPosition.y}
                        onClose={handleCloseContextMenu}
                        types={folderList}
                        selectedContext={selectedContext}
                        loader={setLoader}
                        pageType={props.pageType}
                        reRender={reRender}
                        messageList={messageList}

                    />
                )
            }
            {
                currentMessages.length > 0 &&
                <div className="pagination-controls">
                    <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>{logo.left_arrow_svg}</button>
                    {getPageNumbers().map((page, index) => (

                        <button
                            key={index}
                            onClick={() => handlePageChange(page)}
                            className={page === currentPage ? 'active' : ''}
                            disabled={page === '...'}
                        >
                            {page}
                        </button>
                    ))}
                    <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>{logo.right_arrow_svg}</button>
                    <span>per page:</span>
                    <select className="items-per-page-select"
                        value={messagesPerPage}
                        onChange={handleItemsPerPageChange}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </div>
            }
            {
                !props.messageList &&
                < NoMessage pageType={props.pageType} />
            }
            <ReplyModal isModalOpen={replyModal} close={() => { setReplyModal(false); if (replyModal) { props.reRender() } }} attachmentValue={attachment} msg={selectedMessage} decryptedMessage={message} messageType={modalType} pageType={props.pageType} />
        </div >
    )
}

export default Messages
