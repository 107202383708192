// apiActions.js
import axios from 'axios';
import { commonHeaders } from './commonHeader';
import config from '../web3/web3Config.json';
import Cookies from "universal-cookie";
const cookies = new Cookies();

const BASE_URL = config["EMAIL_API"];
commonHeaders();

async function fetchAPI(endpoint, method, body = null) {
    try {
        const token = cookies.get('accessToken');
        const response = await axios({
            method: method,
            url: `${BASE_URL}/${endpoint}`,
            headers: {
                'Content-Type': 'application/json',
                ...(token && { 'Authorization': `Bearer ${token}` })
            },
            data: body
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching :`, error);
        if (error?.response?.status === 401) {
            cookies.remove('accessToken');
            window.open(`/`, "_self");
        }
        return [];
    }
}
export async function getEncryptedValue(msg, key) {
    return await fetchAPI('getEncryptValue', 'POST', { msg, key });
}

export async function verifyToken(name) {
    return await fetchAPI('verifyToken', 'POST', { name });
}

export async function sendWebTwoEmail(emailObject) {
    const [subject, from, to, cc, bcc, message, attachment] = emailObject;

    const formData = new FormData();

    for (let i = 0; i < attachment.length; i++) {
        formData.append('files', attachment[i]);
    }

    formData.append('subject', subject);
    formData.append('from', from);
    formData.append('to', to);
    formData.append('cc', cc);
    formData.append('bcc', bcc);
    formData.append('message', message);

    try {
        const token = cookies.get('accessToken');
        const response = await axios.post(`${BASE_URL}/sendEmail`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...(token && { 'Authorization': `Bearer ${token}` })
            }
        });
        return response;
    } catch (error) {
        console.error('Error uploading files', error);
        if (error?.response?.status === 401) {
            cookies.remove('accessToken');
            window.open(`/`, "_self");
        }
        return null;
    }

}


export async function sendDecryptFile(file) {
    return await fetchAPI('decryptFile', 'POST', { stringfiedFile: file });
}

export async function sendAttachment(files, key) {

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
        formData.append('publicKey', key);
    }
    try {
        const token = cookies.get('accessToken');
        const response = await axios.post(`${BASE_URL}/sendAttachment`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...(token && { 'Authorization': `Bearer ${token}` })
            }
        });
        return response;
    } catch (error) {
        console.error('Error uploading files', error);
        if (error?.response?.status === 401) {
            cookies.remove('accessToken');
            window.open(`/`, "_self");
        }
    }
}


export async function stringToFile(file, fileName) {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);

    try {
        const token = cookies.get('accessToken');
        const response = await axios.post(`${BASE_URL}/stringToFile`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...(token && { 'Authorization': `Bearer ${token}` })
            },
            responseType: 'blob'
        });
        return response;

    } catch (error) {
        console.error('Error uploading files', error);
        if (error?.response?.status === 401) {
            cookies.remove('accessToken');
            window.open(`/`, "_self");
        }
    }
}


export async function getS3File(name, url) {
    return await fetchAPI('getS3Data', 'POST', { name, url });
}

export async function getInsecure(user_name) {
    return await fetchAPI('getInsecureEmails', 'POST', { user_name });
}

export async function updateInsecureMail(type, id, value) {
    return await fetchAPI('updateInsecureMail', 'POST', { type, id, value });
}

export async function createWeb2User(user_name) {
    return await fetchAPI('createWeb2User', 'POST', { user_name });
}

export async function login(user_name, publicKey) {
    return await fetchAPI('login', 'POST', { user_name, publicKey });
}

export async function log(message, type = null) {
    const user = cookies.get("userObject");
    const user_name = user?.name ?? '-';
    return await fetchAPI('log', 'POST', { message, type, user_name });
}

export async function getEncryptedMessage(uuid) {
    return await fetchAPI('getEncryptedMessage', 'POST', { uuid });
}
