import React, { useState, useRef, useEffect } from "react";
import { logo } from "../assets/svg/svg";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { useLocation } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { updateInsecureMail } from "../service/api-actions";
import { CompleteLoader } from "../modals/common-alert";
import { deleteEmails, permanentlyDeleteEmail, updateEmailAttributeValue, updateAttribute } from ".././helper/emailHelper";
import { ArchiveRestore, Archive, FolderInput, FolderOutput } from "lucide-react";
import { Tooltip } from "antd";
import { toast } from "react-toastify";
import { markMessageAsImportant } from "../helper/web3helper";
import { getHostContract } from "../helper/contract-helper";
import { folders } from "../assets/svg/folder-svg.js";
import '../components/styles/messagebox.css'
import { getWeb2emails } from "../helper/dataFormatHelper.js";
const MessageBoxHeader = (props) => {
  const cookies = new Cookies();
  const getFolderIndex = useSelector((state) => state.folderIndex);
  const [folderList, setFolderList] = useState(false)
  const foldersValue = useSelector((state) => state.foldersList)
  const [user] = useState(cookies.get("userObject"));
  const { pageType, groupedmessage, groupmessage } = props;
  const [isCheckOpen, setIsCheckOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState("all");
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const dispatch = useDispatch();
  const selectedValue = useSelector((state) => state.selectedType);
  const filteredMessage = useSelector((state) => state.selectedMessageList);
  const dropdownRef = useRef(null);
  const folderRef = useRef(null);
  const [loader, setloader] = useState(false);
  const pagiClickRight = useSelector((state) => state.pagiClickRight);
  const pagiClickLeft = useSelector((state) => state.pagiClickLeft);
  const currentPage = useSelector((state) => state.currentPage);
  const messagesPerPage = useSelector((state) => state.messagesPerPage);
  const checkbox = useRef(null);
  const checkboxRef = useRef(null);
  const type = props.pageType === "Insecure" ? "Web2 Inbox" : props.pageType;
  const handleOptionChange = props.handleOptionChange;
  const selectedOption = props.selectedOption;
  const messageList = props.messageList;
  const location = useLocation();
  const readList = props.readList;
  const unreadList = props.unreadList;
  const isVisibleFilter = !["Draft", "Outbox"].includes(props.pageType);
  const checkBoxType = {
    1: "All",
    2: "All Read",
    3: "All Unread",
    4: "All with Star",
  };

  useEffect(() => {
    dispatch({ type: "MESSAGE_PER_PAGE", payload: 50 });
    dispatch({ type: "CURRENT_PAGE", payload: 1 });
  }, [location.pathname, dispatch]);
  const returnHtmlCheckBox = () => {
    return (
      <div className="position-uncover-checkbox" ref={checkbox}>
        {Object.keys(checkBoxType).map((key) => (
          <div key={key}>
            <input
              type="checkbox"
              id={key}
              value={key}
              checked={selectedValue === parseInt(key, 10)}
              onClick={handleSelect(key)}
            />
            <label htmlFor={key}>{checkBoxType[key]}</label>
          </div>
        ))}
      </div>
    );
  };

  const handleSelect = (value) => () => {

    let parseValue = parseInt(value, 10);
    if (parseValue === selectedValue) {
      parseValue = null;
    }
    dispatch({ type: "SELECT_TYPE", payload: parseValue });
  };

  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      checkbox.current &&
      !checkbox.current.contains(event.target) &&
      checkboxRef.current &&
      !checkboxRef.current.contains(event.target)
    ) {
      setIsCheckOpen(false);
    }
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
    if (folderRef.current && !folderRef.current.contains(event.target)) {
      setFolderList(false)
    }
  };
  const getTotalLength = () => {
    if (selectedOption === "all") {
      if (groupmessage) {
        return groupedmessage.length;
      } else {
        return messageList.length;
      }
    }
    if (selectedOption === "read") return readList.length;
    if (selectedOption === "unread") return unreadList.length;
  };
  const handleDropdownSelect = (value) => () => {
    setSelectedDropdownOption(value);
    setIsDropdownOpen(false);
    handleOptionChange({ target: { value } });
  };
  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };
  async function processBulkData(sent, type, mailtype, resValue, functionName) {
    if (sent.length > 0) {
      const chunks = chunkArray(sent, 25);
      for (const chunk of chunks) {
        let value = chunk;
        await functionName(user.name, value, type, mailtype, resValue);
      }
    }
  }
  async function bulkWeb2Emails(Emails, batchSize, type, resValue, functionName) {
    if (Emails.length > 0) {
      const chunks = chunkArray(Emails, batchSize);
      for (const chunk of chunks) {
        let id = chunk;
        const value = resValue;
        await functionName(type, id, value);
      }
    }
  }
  async function bulkEmailsRestore(trash, type, functionName) {
    if (trash.length > 0) {
      const chunks = chunkArray(trash, 25);
      for (const chunk of chunks) {
        let value = chunk;
        await functionName(value, null, null, null, type, null);
      }
    }
  }
  async function deleteBulkEmails(inbox, __, value3, pagetype, getFolderIndex, functionName) {
    if (value3.length > 0) {
      const updatedResponse = [];
      value3.forEach((data) => {
        updatedResponse.push(data.messageId);
      });
      const chunks = chunkArray(updatedResponse, 25);
      for (const chunk of chunks) {
        let value = chunk;
        await functionName(inbox, value, null, value3, pagetype, getFolderIndex);
      }
    }
  }
  async function web2EmailsDelete(Emails, batchSize, type, functionName, resValue) {
    if (Emails.length > 0) {
      const updatedResponse = [];
      Emails.forEach((data) => {
        updatedResponse.push({
          id: data.messageId || data.id,
          value: resValue,
        });
      });
      const chunks = chunkArray(updatedResponse, batchSize);
      for (const chunk of chunks) {
        let value = chunk;
        await functionName(type, "", value);
      }
    }
  }
  const bulkDelete = async () => {

    const selectedMessages = filteredMessage.map((message) => {
      const matchFound = messageList.find((data) => data.id === message);
      return matchFound;
    });
    const web3 = selectedMessages.filter((data) => data.mailtype === "web3emails");
    const senttrash = selectedMessages.filter((data) => data.mailtype === "Senttrash");
    const web2 = selectedMessages.filter((data) => data.mailtype === "web2emails");
    Swal.fire({
      text: "Are you sure you want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1677ff",
      cancelButtonColor: "#ffffff",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: "compose-sweet-alert",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setloader(true);
        try {
          await deleteBulkEmails(null, null, web3, props.pageType, null, deleteEmails)
          await deleteBulkEmails(null, null, senttrash, "Sent", null, deleteEmails)
          await web2EmailsDelete(web2, 50, "bulkdelete", updateInsecureMail, 1)
        }
        catch (err) {
          console.error(err)
        }
        finally {
          props.reRender();
          setloader(false);
        }
      }
    });
  };


  const handleRestore = async () => {
    const selectedMessages = filteredMessage.map((message) => {
      const matchFound = messageList.find((data) => data.id === message);
      return matchFound;
    });
    const web3 = selectedMessages.filter((data) => data.mailtype === "web3emails");
    const senttrash = selectedMessages.filter((data) => data.emailType === "Senttrash");
    const web2 = selectedMessages.filter((data) => data.mailtype === "web2emails");
    Swal.fire({
      text: "Are you sure you want to restore?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1677ff",
      cancelButtonColor: "#ffffff",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: "compose-sweet-alert",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setloader(true);
        try {
          await bulkEmailsRestore(web3, "inboxtrash", deleteEmails)
          await bulkEmailsRestore(senttrash, "Senttrash", deleteEmails)
          await web2EmailsDelete(web2, 50, "bulkdelete", updateInsecureMail, 2)

        }
        catch (err) {
          console.error(err)
        }
        finally {
          props.reRender();
          setloader(false);
        }
      }
    });
  };
  const permanentDelete = async () => {
    const selectedMessages = filteredMessage.map((message) => {
      const matchFound = messageList.find((data) => data.id === message);
      return matchFound;
    });
    const web3 = selectedMessages.filter((data) => data.mailtype === "web3emails");
    const senttrash = selectedMessages.filter((data) => data.emailType === "Senttrash");
    const web2 = selectedMessages.filter((data) => data.mailtype === "web2emails");

    Swal.fire({
      text: "Are you sure you want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1677ff",
      cancelButtonColor: "#ffffff",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: "compose-sweet-alert",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setloader(true);
        try {
          await deleteBulkEmails(senttrash, null, senttrash, "Trash", null, deleteEmails)
          if (web3.length > 0) {
            const updatedResponse = [];
            web3.forEach((data) => {
              updatedResponse.push(data.messageId);
            });
            const chunks = chunkArray(updatedResponse, 25);
            for (const chunk of chunks) {
              await permanentlyDeleteEmail(
                user.name,
                chunk,
                'hostContract'
              )
            }
          }
          await web2EmailsDelete(web2, 50, "bulkdelete", updateInsecureMail, 3)
        }
        catch (Err) {
          console.error(Err)
        }
        finally {
          props.reRender();
          setloader(false);
        }

      }
    });
  };

  const handleOptionClick = async (data) => {
    const type = pageType === 'Sent' ? `sent-folder-${parseInt(data.id)}` : `inbox-folder-${parseInt(data.id)}`;
    setloader(true);
    if (pageType === "Insecure") {
      const formattedresponse = await getWeb2emails(user.name)
      const result = formattedresponse.map((data) => {
        const response = filteredMessage.includes(data.id) && data.physmessage_id
        return response;
      }
      ).filter(Boolean);
      await updateAttribute(user.name, result, type, 'folder', true);
    } else {
      await updateEmailAttributeValue(user.name, filteredMessage, [{key: 'folder', value: (data.id).toString()}], 'hostContract');
    }
    props.reRender();
    setloader(false);
    setFolderList(false)

  };
  const folderMailDelete = async () => {
    const selectedMessages = filteredMessage.map((message) => {
      const matchFound = messageList.find((data) => data.id === message);
      return matchFound;
    });
    const sent = selectedMessages.filter((data) => data.emailType === "sent");
    const inbox = selectedMessages.filter((data) => data.emailType === "inbox");
    const web2 = selectedMessages.filter((data) => data.mailtype === "web2emails");

    Swal.fire({
      text: "Are you sure you want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1677ff",
      cancelButtonColor: "#ffffff",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: "compose-sweet-alert",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setloader(true);
        try {
          await deleteBulkEmails(sent, null, sent, "Folders", getFolderIndex, deleteEmails);
          await deleteBulkEmails(inbox, null, inbox, "Folders", getFolderIndex, deleteEmails);
          await web2EmailsDelete(web2, 50, "bulkdelete", updateInsecureMail, 1)
          props.reRender();
        }
        catch (Err) {
          console.error(Err)
        }
        finally {
          props.reRender();
          setloader(false);
        }
      }
    });
  };
  const bulkRestoreArchive = async (resValue) => {
    setloader(true);
    const selectedMessages = filteredMessage.map((message) => {
      const matchFound = messageList.find((data) => data.id === message);
      return matchFound;
    });
    const web3emails = selectedMessages.filter((data) => ["Senttrash", "web3emails"].includes(data.mailtype)).map((data) => data.id);
    const web2 = selectedMessages.map((data) => data.mailtype === "web2emails" ? data.id : null).filter(data => data !== null);
    try {      
      const chunks = chunkArray(web3emails, 25);
      for (const chunk of chunks) {
        await updateEmailAttributeValue(user.name, chunk, [{key:'isArchive', value: (resValue).toString() }], 'hostContract');
      }
      await bulkWeb2Emails(web2, 50, "Web2emails", resValue, updateInsecureMail)

    } catch (err) {
      toast.error("Failed to move Archive");
      console.log(err);
    } finally {
      props.reRender();
      setloader(false);
    }
  };
  async function bulkStarred(inbox, contractMethods, response, functionName) {
    if (inbox.length > 0) {
      const chunks = chunkArray(inbox, 25);
      for (const chunk of chunks) {
        let obj = chunk;
        const value = response;
        await functionName(contractMethods, user.name, obj, value, user.wallet);
      }
    }
  }
  const handlestared = async () => {
    setloader(true);
    const contractMethods = getHostContract();
    const selectedMessages = filteredMessage.map((message) => {
      const matchFound = messageList.find((data) => data.id === message);
      return matchFound;
    });
    const inbox = selectedMessages.map((data) => data.mailtype === "web3emails" ? (data.id) : null).filter(data => data !== null);
    const web2 = selectedMessages.map((data) => data.mailtype === "web2emails" ? data.id : null).filter(data => data !== null);
    const response = selectedMessages.some((data) => data.isStarred === true);
    try {
      await bulkStarred(inbox, contractMethods, response, markMessageAsImportant)
      await bulkWeb2Emails(web2, 50, "fav", "", updateInsecureMail)
    } catch (err) {
      console.error(err);
    } finally {
      props.reRender();
      setloader(false);
    }
  };
  function getColorFromJson(jsonString) {
    try {
      const json = jsonString;
      const color = json.folderProperties.find(value => value.key == 'color')['value']
      return color;
    } catch (error) {
      return "green";
    }
  }
  return (
    <>
      <div className="box-common-header box-header-type">
        <div className="page-type">
          <div className="page-type-check-box" id={selectedValue}>
            <input
              type="checkbox"
              id="html"
              value="1"
              onClick={handleSelect(1)}
              checked={selectedValue === 1}
            />
          </div>
          <div className="page-type">
            <div
              className="page-type-arrow cursor-pointer"
              ref={checkboxRef}
              onClick={() => setIsCheckOpen(!isCheckOpen)}
            >
              {logo.down_arrow_box}
            </div>
            {type}
          </div>
          {isCheckOpen && returnHtmlCheckBox()}
          <div className="menu-dot-message-header"> {logo.svg_menu_dot} </div>
        </div>
        <div className="flex items-center">
          {isVisibleFilter && (
            <div
              className={`show-checkbox-box-type ${isMobileView ? "dropdown-show-checkbox-box-type" : ""
                }`}
              ref={dropdownRef}
            >
              {isMobileView ? (
                <div
                  className="show-btn"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  <p>
                    {selectedDropdownOption === "all" ? (
                      <>
                        All <span>({messageList.length})</span>
                      </>
                    ) : selectedDropdownOption === "read" ? (
                      <>
                        Read <span>({readList.length})</span>
                      </>
                    ) : (
                      <>
                        Unread <span>({unreadList.length})</span>
                      </>
                    )}
                  </p>
                  <svg
                    width="7"
                    height="6"
                    viewBox="0 0 7 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1.25L3.5 3.75L6 1.25"
                      stroke="#A3ABAD"
                      stroke-width="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              ) : (
                <>
                  <div className="show-btn show-text">
                    <span> Show : </span>
                  </div>
                  <div className="show-btn">
                    <label className="label-radio">
                      <input
                        type="radio"
                        className="input-radio on"
                        name="message-box"
                        value="all"
                        checked={selectedOption === "all"}
                        onChange={handleOptionChange}
                      />
                      <span
                        className={selectedOption === "all" ? "active" : ""}
                      >
                        {" "}
                        All <span>({messageList.length})</span>{" "}
                      </span>
                    </label>
                    <span className="pipe-symbol"> | </span>
                  </div>
                  {pageType !== "Sent" && (
                    <div className="show-btn">
                      <label className="label-radio">
                        <input
                          type="radio"
                          className="input-radio on"
                          name="message-box"
                          value="read"
                          checked={selectedOption === "read"}
                          onChange={handleOptionChange}
                        />
                        <span
                          className={selectedOption === "read" ? "active" : ""}
                        >
                          {" "}
                          Read <span>({readList.length})</span>{" "}
                        </span>
                      </label>
                      <span className="pipe-symbol"> | </span>
                    </div>
                  )}
                  {pageType !== "Sent" && (
                    <div className="show-btn">
                      <label className="label-radio">
                        <input
                          type="radio"
                          className="input-radio on"
                          name="message-box"
                          value="unread"
                          checked={selectedOption === "unread"}
                          onChange={handleOptionChange}
                        />
                        <span
                          className={
                            selectedOption === "unread" ? "active" : ""
                          }
                        >
                          {" "}
                          Unread <span>({unreadList.length})</span>{" "}
                        </span>
                      </label>
                    </div>
                  )}
                </>
              )}
              {isMobileView && (
                <div
                  className={`dropdown-content ${isDropdownOpen ? "show" : ""}`}
                >
                  <div
                    className="show-btn"
                    onClick={handleDropdownSelect("all")}
                  >
                    All <span>({messageList.length})</span>
                  </div>

                  <div
                    className="show-btn"
                    onClick={handleDropdownSelect("read")}
                  >
                    Read <span>({readList.length})</span>
                  </div>
                  <div
                    className="show-btn"
                    onClick={handleDropdownSelect("unread")}
                  >
                    Unread <span>({unreadList.length})</span>
                  </div>
                </div>
              )}
            </div>
          )}
          {filteredMessage.length > 0 && props.pageType === "Folders" && (
            <div
              onClick={() => {
                folderMailDelete();
              }}
              style={{ paddingRight: "10px", cursor: "pointer" }}
            >
              <Tooltip
                title="Delete"
                placement="top"
                enterDelay={500}
                leaveDelay={200}
              >
                <DeleteOutlined style={{ color: "#b9bfc1" }} />
              </Tooltip>
            </div>
          )}
          {filteredMessage.length > 0 &&
            props.pageType === "Trash" &&
            props.pageType !== "Folders" ? (
            <div
              onClick={() => {
                permanentDelete();
              }}
              style={{ paddingRight: "10px", cursor: "pointer" }}
            >
              <Tooltip title="Delete" placement="top">
                <DeleteOutlined style={{ color: "#b9bfc1" }} />
              </Tooltip>
            </div>
          ) : (
            filteredMessage.length > 0 &&
            props.pageType !== "Folders" && (
              <div
                onClick={() => { bulkDelete() }}
                style={{ paddingRight: "10px", cursor: "pointer" }}
              >
                <Tooltip title="Delete" placement="top">
                  <DeleteOutlined style={{ color: "#b9bfc1" }} />
                </Tooltip>
              </div>
            )
          )}

          {filteredMessage.length > 0 &&
            pageType !== "Trash" &&
            pageType === "Archive" && (
              <div
                onClick={() => { bulkRestoreArchive(false) }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Tooltip title={pageType !== "Archive" ? "Archive" : "Move to inbox"} placement="top">
                  <ArchiveRestore
                    style={{ color: "#b9bfc1" }}
                    width={18}
                    height={15}
                  />
                </Tooltip>
              </div>
            )}
          {filteredMessage.length > 0 &&
            (pageType === "Inbox" || pageType === "Insecure" || pageType === "Important") && (
              <div
                className="start-icon-div"
                onClick={() => { handlestared() }}
                style={{
                  marginRight: "7px",
                  marginBottom: "2px",
                  cursor: "pointer",
                }}
              >
                {" "}
                <Tooltip title="Starred" placement="top">
                  {logo.star_svg_outline}
                </Tooltip>
              </div>
            )}
          {filteredMessage.length > 0 &&
            pageType !== "Trash" &&
            pageType !== "Archive" &&
            pageType !== "Folders" && (
              <div
                onClick={() => { bulkRestoreArchive(true) }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Tooltip title="Archive" placement="top">
                  <Archive
                    style={{ color: "#b9bfc1" }}
                    width={18}
                    height={15}
                  />
                </Tooltip>
              </div>
            )}


          {filteredMessage.length > 0 && foldersValue.length > 0 &&
            (pageType === "Inbox" ||
              pageType === "Insecure" ||
              pageType === "Sent") &&
            pageType !== "Folders" && (
              <div
                onClick={() => { setFolderList(true) }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  paddingLeft: "7px"
                }}
              >
                <Tooltip title="Move to Folder" placement="top">
                  <FolderInput
                    style={{ color: "#b9bfc1" }}
                    width={18}
                    height={15}
                  />

                </Tooltip>

                {folderList && <div className="foldersList">
                  <ul className="folders" ref={folderRef}>
                    {foldersValue.length > 0 &&
                      foldersValue.map((data, index) => {

                        return (
                          <li key={index} onClick={() => handleOptionClick(data)}>
                            {folders.folderIcon[`${getColorFromJson(data)}Folder`]}
                            {data.name}
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>}

              </div>
            )}

          {filteredMessage.length > 0 &&
            pageType === "Folders" && (
              <div
                onClick={() => { bulkRestoreArchive(true) }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Tooltip title="Move to inbox" placement="top">
                  <FolderOutput
                    style={{ color: "#b9bfc1" }}
                    width={18}
                    height={15}
                  />
                </Tooltip>
              </div>
            )}
          {filteredMessage.length > 0 &&
            pageType === "Trash" &&
            filteredMessage.length > 0 &&
            <Tooltip title="Restore" placement="top">
              <img src="/trash-restore-solid-svgrepo-com.svg" alt="trash" onClick={() => handleRestore()} style={{ color: "#b9bfc1", marginBottom: "3px" }} />
            </Tooltip >
          }

          {isVisibleFilter && (
            <div
              className="sort-icon-action-01"
              onClick={() => {
                // dispatch({ type: "FILTER_TYPE", payload: !useSelectordata })
              }}
            >
              {/* {logo.filter_icon_svg} */}

              <div className="pagination-controls">
                <span className="pagination-list-length text-truncate">
                  {" "}
                  {currentPage * messagesPerPage - messagesPerPage + 1} -{" "}
                  {currentPage * messagesPerPage > getTotalLength()
                    ? getTotalLength()
                    : currentPage * messagesPerPage}{" "}
                  of {getTotalLength()}
                </span>
                <Tooltip title="Newer" placement="bottom">  <span
                  className="pagination-icon"
                  onClick={() => {
                    dispatch({
                      type: "PAGI_CLICK_LEFT",
                      payload: !pagiClickLeft,
                    });
                  }}
                >
                  {logo.left_arrow_svg}
                </span></Tooltip>
                <Tooltip title="older" placement="bottom">   <span
                  className="pagination-icon"
                  onClick={() => {
                    dispatch({
                      type: "PAGI_CLICK_RIGHT",
                      payload: !pagiClickRight,
                    });
                  }}
                >
                  {logo.right_arrow_svg}
                </span></Tooltip>
              </div>
            </div>
          )}
        </div>
      </div >
      <CompleteLoader isOpen={loader} />
    </>
  );
};

export default MessageBoxHeader;
