import React, { useState, useEffect, useRef } from "react";
import { logo } from '../../assets/svg/svg'

import Creatable from 'react-select/creatable';

import ReactQuill from 'react-quill';
import { saveSenderEncryptedEmail, sendEmails } from "../../helper/emailHelper";
import Cookies from "universal-cookie";
import { getHostContract } from "../../helper/contract-helper";
import { CompleteLoader } from "../../modals/common-alert";
import { useDispatch, useSelector } from "react-redux";
import config from "../../web3/web3Config.json";
import db from '../../db/db-service';
import { sendAttachment } from '../../service/api-actions';
import { toast, Bounce } from 'react-toastify';
import Swal from 'sweetalert2'
import { getGeneralSettings } from '../../helper/settingsHelper.js';
import { getSuggestion } from '../../helper/contract-helper.js';
import { Calendar as CalendarIcon } from "lucide-react";
import { Tooltip } from "antd";
import "../../../src/assets/css/calendar.css"

const cookies = new Cookies();

const editorConstant = {
  toolBar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ 'size': [] }],
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }]
  ],
  format: [
    'header', 'font', 'size',
    'bold', 'italic', 'underline',
    'list', 'bullet', 'indent'
  ]
}

const ComposeEmail = (props) => {
  const [user] = useState(cookies.get("userObject"));
  const [accountSettings, setAccountSettings] = useState([]);
  const [sendLoader, setSendLoader] = useState(false);
  const [isCC, setIsCC] = useState(false);
  const [isBCC, setIsBCC] = useState(false);
  const [emailId, setEmailId] = useState();
  const [emailCC, setEmailCC] = useState();
  const [emailBCC, setEmailBCC] = useState();
  const [receiverName, setReceiverName] = useState(localStorage.getItem("recipient") || '');
  const [CC, setCC] = useState(localStorage.getItem("cc") || '');
  const [BCC, setBCC] = useState(localStorage.getItem("bcc") || '');
  const [subject, setSubject] = useState(localStorage.getItem("topic") || '');
  const [isSpellCheck, setIsSpellCheck] = useState('false');
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const draftData = useSelector((state) => state.draftData);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const isMinimize = useSelector((state) => state.composeMin);
  const [suggestions, setSuggestions] = useState([]);
  const [inputType, setInputType] = useState('');
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const [ctrlA, setCtrlA] = useState(false);
  const [ctrlCC, setCtrlCC] = useState(false)
  const [ctrlBCC, setCtrlBCC] = useState(false)
  const [isFocused, setIsFocused] = useState({
    receiver: false,
    cc: false,
    bcc: false
  });


  useEffect(() => {
    setReceiverName(localStorage.getItem("recipient") || '');
    setSubject(localStorage.getItem("topic") || '')
    setCC(localStorage.getItem("cc") || '');
    setBCC(localStorage.getItem("bcc") || '');
    if (CC) {
      setIsCC(true)
    }
    if (BCC) {
      setIsBCC(true)
    }
    localStorage.getItem("sendingEmail")

  }, [props]);

  async function setSettingsJson() {
    const filteredAddressBook = await getSuggestion();
    setEmailSuggestions(filteredAddressBook);
    try {
      const generalSettings = await getGeneralSettings();
      setAccountSettings(generalSettings);
    } catch (error) { }
  }


  const handleClickOutside = () => {
    setCtrlA(false)
    setCtrlCC(false)
    setCtrlBCC(false)
  }
  useEffect(() => {
    document.addEventListener("click", handleClickOutside)
    return (() => {
      document.removeEventListener("click", handleClickOutside)
    })

  }, [])
  async function composeEmail(receiver) {
    const isSavedOn = accountSettings.isSaveSent;
    const recipient = receiver;
    const subject = document.getElementById("subject").value;
    const cc = isCC ? CC : "";
    const bcc = isBCC ? BCC : "";

    let isReceipientAdded = false;
    if (recipient || cc || bcc) isReceipientAdded = true;

    if (!isReceipientAdded) {
      toast.error("Please specify at least one recipient.", {
        position: "top-center",
        transition: Bounce,
      });

      setSendLoader(false);
      return;
    }

    if (!subject || !localStorage.getItem("sendingEmail")) {
      Swal.fire({
        text: `${(!subject && !localStorage.getItem("sendingEmail") && "Send this message without a subject or text in the body?") || (!subject && "Send this message without a subject?") || (!localStorage.getItem("sendingEmail") && "Send this message without a text in the body?")}  `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1677ff",
        cancelButtonColor: "#ffffff",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: "compose-sweet-alert"
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch({ type: "DRAFT", payload: null });

          let defaultEncryptedMessage = "MSG";
          if (isSavedOn) {
            const emailObject = { recipient: recipient, subject: subject, message: localStorage.getItem("sendingEmail") };
            if (selectedFiles && selectedFiles.length) {
              const attachmentResult = await sendAttachment(selectedFiles, user.publicKey);
              emailObject.attachment = attachmentResult.data.returnValue;
            }
            const accounts = await window.ethereum.request({ method: 'eth_accounts' });
            defaultEncryptedMessage = await saveSenderEncryptedEmail(emailObject, accounts);
          }
          const result = await sendEmails(recipient.replace(/\s/g, '').split(","), cc.replace(/\s/g, '').split(","), bcc.replace(/\s/g, '').split(","), subject, localStorage.getItem("sendingEmail"), isSavedOn, defaultEncryptedMessage, selectedFiles);
          setSendLoader(false);
          if (result) {
            const notifyMessage = `Message Sent`;
            toast.info(notifyMessage, {
              position: "top-center",
              transition: Bounce,
            })
            localStorage.setItem("sendingEmail", "");
            dispatch({ type: "IS_COMPOSE", payload: false })
            props.setIsCompose(false);
            if (props.reRender) props.reRender();
          }
        } else {
          setSendLoader(false);
          return true;
        }
      });
    } else {
      dispatch({ type: "DRAFT", payload: null });

      let defaultEncryptedMessage = "MSG";
      if (isSavedOn) {
        const emailObject = { recipient: recipient, subject: subject, message: localStorage.getItem("sendingEmail") };
        if (selectedFiles && selectedFiles.length) {
          const attachmentResult = await sendAttachment(selectedFiles, user.publicKey);
          emailObject.attachment = attachmentResult.data.returnValue;
        }
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        defaultEncryptedMessage = await saveSenderEncryptedEmail(emailObject, accounts);
      }
      const result = await sendEmails(recipient.replace(/\s/g, '').split(","), cc.replace(/\s/g, '').split(","), bcc.replace(/\s/g, '').split(","), subject, localStorage.getItem("sendingEmail"), isSavedOn, defaultEncryptedMessage, selectedFiles);
      if (result) {
        const notifyMessage = `Message Sent`;
        toast.info(notifyMessage, {
          position: "top-center",
          transition: Bounce,
        })
      }
      setSendLoader(false);
      if (result) {
        localStorage.setItem("sendingEmail", "");
        dispatch({ type: "IS_COMPOSE", payload: false })
        props.setIsCompose(false);
        if (props.reRender) props.reRender();
      }
    }
  }



  async function saveDraftFunction() {
    const draftJSON = JSON.parse(draftData);
    const draft = {
      type: "draft",
      to: receiverName,
      bcc: isBCC ? BCC : "",
      cc: isCC ? CC : "",
      subject: subject || "(No Subject)",
      message: localStorage.getItem("sendingEmail"),
      sender: user.name,
    }
    const decryptedObject = { json: draft };
    if (draftJSON && draftJSON.id) {
      const value = await db.table('draft').update(draftJSON.id, decryptedObject);
      return value;
    } else {
      const value = await db.table('draft').add(decryptedObject);
      return value;
    }

    // const recipient = document.getElementById("receiver").value;
    // const subject = document.getElementById("subject").value;
    // const cc = isCC ? CC : "";
    // const bcc = isBCC ? BCC : "";
    // const senderData = user.name;

    // const objectData = {
    //   created_at: getCurrentDate(),
    //   emailDetails: "",
    //   encryptedData: "encryptedData",
    //   senderName: senderData,
    //   subject: subject,
    //   isRead: false,
    //   isDeleted: false,
    //   recipient: recipient,
    //   cc: cc,
    //   bcc: bcc,
    //   isStarred: false
    // }

    // const functionParams = [senderData, JSON.stringify(objectData)];
    // const contractMethods = getHostContract();

    // await transactionAction(contractMethods, "saveDraft", functionParams, user.wallet);
    // dispatch({ type: "DRAFT", payload: null });

  }



  useEffect(() => {
    setSettingsJson();
  }, []);

  //outBox
  const outBoxIndex = useSelector((state) => state.outBoxIndex);
  useEffect(() => {
    const fetchReceiverData = async () => {
      if (outBoxIndex !== null) {
        const indexdb = await db.table('outbox').toArray();
        setReceiverName(indexdb[outBoxIndex].json.to);
        document.getElementById("subject").value = indexdb[outBoxIndex].json.subject;
        if (indexdb[outBoxIndex].json.cc.length && indexdb[outBoxIndex].json.cc.join(',')) {
          setIsCC(true);
          setCC(indexdb[outBoxIndex].json.cc.join(','));
        }
        if (indexdb[outBoxIndex].json.bcc.length && indexdb[outBoxIndex].json.bcc.join(',')) {
          setIsBCC(true);
          setBCC(indexdb[outBoxIndex].json.bcc.join(','));
        }
        localStorage.setItem("sendingEmail", indexdb[outBoxIndex].json.message);
        dispatch({ type: "OUTBOX", payload: null, value: false });
      }
    };
    fetchReceiverData();
  }, [outBoxIndex]);


  const Editor = () => {
    const [editorHtml, setEditorHtml] = useState(localStorage.getItem("sendingEmail") || "");
    const [theme] = useState('snow');
    const quillRef = useRef(null);


    const handleUndo = () => {
      const quill = quillRef.current.getEditor();
      quill.history.undo();
    };


    const handleRedo = () => {
      const quill = quillRef.current.getEditor();
      quill.history.redo();
    };

    const handleFileChange = async (event) => {
      const newFiles = Array.from(event.target.files);
      const combinedFiles = [...selectedFiles, ...newFiles];
      const maxSize = 25 * 1024 * 1024; // 25 MB in bytes
      const totalSize = combinedFiles.reduce((acc, file) => acc + file.size, 0);
      if (totalSize > maxSize) {
        toast.error("Total file size exceeds the limit of 25 MB.", {
          position: "top-center",
          transition: Bounce,
        });
        return;
      }
      setSelectedFiles(combinedFiles);
    };

    useEffect(() => {
      const getData = async () => {
        const generalSettings = await getGeneralSettings();
        setIsSpellCheck(generalSettings.isSpellChecked ? "true" : "false");
      }
      getData();
    }, []);


    useEffect(() => {
      const editor = document.querySelector('.ql-editor');
      if (editor) {
        editor.setAttribute('spellcheck', isSpellCheck);
      }
    }, [editorHtml]);


    const handleDragOver = (event) => {
      event.preventDefault();

    };

    const handleRemoveFile = (indexToRemove) => {
      setSelectedFiles(prevFiles => prevFiles.filter((file, index) => index !== indexToRemove));
    }
    const handleTabKey = (e) => {
      const quillEditor = quillRef.current.getEditor();
      const editorContent = quillEditor.root;
      if (e.key === "Tab") {
        e.preventDefault();
        if (e.shiftKey) {
          // Shift + Tab logic (unindent)
        } else {
          // Regular Tab logic (indent)
        }
        editorContent.focus();
      }
    };
    const handleFileDrop = (event) => {

      const text = event.dataTransfer.getData('text/plain');
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection();
      const cursorPosition = range ? range.index : 0;
      const files = event.dataTransfer.files;
      if (files) {
        const combinedFiles = [...selectedFiles, ...files];
        const maxSize = 25 * 1024 * 1024; // 25 MB in bytes
        const totalSize = combinedFiles.reduce((acc, file) => acc + file.size, 0);
        if (totalSize > maxSize) {
          toast.error("Total file size exceeds the limit of 25 MB.", {
            position: "top-center",
            transition: Bounce,
          });
          return;
        }
        setSelectedFiles(combinedFiles);
      }
      // const quillEditor = quillRef.current.getEditor();
      // quillEditor.focus();


      quill.insertText(cursorPosition, text);
      quill.setSelection(cursorPosition + text.length, 0);
    }



    return (
      //onDrop={handleFileDrop} onDragOver={handleDragOver}
      <div className="quil-text-editor" onKeyDown={handleTabKey} >
        <ReactQuill theme={theme} spellCheck={true} onChange={async (e) => { localStorage.setItem("sendingEmail", e); }} ref={quillRef} value={editorHtml} modules={{ toolbar: editorConstant.toolBar }} />
        <div className="attachment-file-rendering-method">
          {selectedFiles.length > 0 && (
            <div className="attachment-section-element">
              {selectedFiles.map((file, index) => (
                <a key={index}>{file.name} <span className="delete-icon cursor-pointer" onClick={() => handleRemoveFile(index)}> &#10006;
                </span></a>
              ))}
            </div>
          )}
        </div>
        <div className="div-btn-action-compose-email">
          <div className="compose-emails-actions text-selection">
            <div className="compose-email-bth-operations">

              <div className="delete-functionality cursor-pointer" title="Discard draft" >   <span onClick={() => {
                localStorage.setItem("sendingEmail", "");
                dispatch({ type: "IS_COMPOSE", payload: false });
                dispatch({ type: "DRAFT", payload: null });
                props.setIsCompose(false);
              }}>{logo.compose_action_delete}</span> </div>
              <div className="mobile-undo-redu-attachment flex items-center gap-10">
                <div className="undo-redu-functionality" >
                  <div className="undo cursor-pointer" onClick={handleUndo}> {logo.undo_action_compose} </div>
                  <div className="redo cursor-pointer" onClick={handleRedo} > {logo.redo_action_compose} </div>
                </div>
                <div className="add-attachment-functionality">
                  <div className="btn-attachment cursor-pointer" onClick={() => document.getElementById('fileInput').click()}>
                    {logo.attachment_icon} <span className="add-file-component"> add file </span>
                  </div>
                  <input
                    type="file"
                    id="fileInput"
                    multiple
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                </div>
                <Tooltip title="Create event">
                  <div className="btn-event cursor-pointer mt-2" onClick={() => {
                    window.dispatchEvent(new Event("NEW_EVENT"));
                  }}>
                    <CalendarIcon />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="send-compose-email text-selection" onClick={async () => {
            setSendLoader(true);
            try {
              composeEmail(receiverName);
            } catch (error) {
              setSendLoader(false);
              const notifyMessage = `Failed to Send Message`;
              toast.info(notifyMessage, {
                position: "top-center",
                transition: Bounce,
              })
              dispatch({ type: "IS_COMPOSE", payload: false })
              props.setIsCompose(false);
            }
          }}>
            <div className="btn-message-compose"> <div> Encrypt and Send </div> <div> (Gas 0.005 {config.SYMBOL}) </div>  </div>
            <div> {logo.compose_svg} </div>
          </div>

        </div>
        <div className="position-abo-messsage"> Message : </div>
      </div>
    );
  };

  const appendClassName = (isCC && isBCC) ? "both-carbon copy" : (isCC) ? "carbon" : (isBCC) ? "carbon" : "no-cc-bcc";

  const addToDraft = async () => {
    Swal.fire({
      text: "Are you sure you want to save draft?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1677ff",
      cancelButtonColor: "#ffffff",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: "compose-sweet-alert"
    }).then(async (result) => {
      if (result.isConfirmed) {
        setSendLoader(true);
        await saveDraftFunction();
        localStorage.setItem("sendingEmail", "");
        dispatch({ type: "COMPOSE_MIN", payload: false });
        dispatch({ type: "DRAFT", payload: null });
        dispatch({ type: "IS_COMPOSE", payload: false })
        props.setIsCompose(false);
        props.reRender();
        setSendLoader(false);
      } else {
        await closeCompose();
      }
    });
  }

  const closeCompose = async () => {
    dispatch({ type: "COMPOSE_MIN", payload: false });
    dispatch({ type: "IS_COMPOSE", payload: false })
    dispatch({ type: "DRAFT", payload: null });
    props.setIsCompose(false);
  }

  if (isMinimize) {

    return (
      <div className="minimise-compose-div-element">
        <div className="compose-new-message-txt"> New Message </div>
        <div className="cursor-pointer" >
          <div onClick={() => { dispatch({ type: "COMPOSE_MIN", payload: false }); }} > {logo.compose_max}    </div>
          <div onClick={async () => { addToDraft(); }}  > {logo.compose_close_light}  </div>
        </div>
      </div>
    )
  }
  const isMini = isMinimize ? "minimize-compose" : "normal-compose";

  const handleInputChange = (value, setValue, fieldType) => {

    setValue(value);

    const lastEmailPart = value.split(',').pop().trim();
    if (lastEmailPart) {
      setSuggestions(emailSuggestions.filter((email) =>
        email.toLowerCase().includes(lastEmailPart.toLowerCase()) &&
        email.toLowerCase() !== lastEmailPart.toLowerCase()
      ));

    } else {
      setSuggestions([]);
    }
  };
  const handleKeyDown = (e, value, setValue, setClrl, fieldType, ctrl) => {
    setInputType(fieldType);
    if (e.ctrlKey && e.key === 'a') {
      e.preventDefault();
      e.target.select();
      setClrl(true)
    }

    if (e.ctrlKey && e.key === 'c') {
      e.preventDefault();
      navigator.clipboard.writeText(value)
        .then(() => console.log('Emails copied to clipboard'))
        .catch((err) => console.error('Failed to copy emails: ', err));
    }

    if (e.ctrlKey && e.key === 'v') {
      e.preventDefault();
      navigator.clipboard.readText()
        .then((clipboardText) => {
          const emails = clipboardText.split(',').map((email) => email.trim()).filter(Boolean);
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const validEmails = emails.filter((email) => emailRegex.test(email.toLowerCase()));
          const response = value ? value.split(',').filter(Boolean) : [];
          const newEmails = [...response, ...validEmails];
          const result = [...new Set(newEmails)]
          setValue(result.join(','));
        })
        .catch((err) => console.error('Failed to read clipboard: ', err));
    }

    if (e.ctrlKey && e.key === 'x') {
      e.preventDefault();
      navigator.clipboard.writeText(value)
        .then(() => console.log('Emails cut to clipboard'))
        .catch((err) => console.error('Failed to cut emails: ', err));
      setValue('');
    }

    if (e.key === "Backspace") {
      if (value) {
        const updatedList = value.split(",").filter(Boolean);
        updatedList.pop();
        setValue(updatedList.join(","));
      }
    }
    if (ctrl && e.key === "Backspace") {
      setValue();
      setClrl(false)
    }
  };

  const handleSuggestionClick = (email, setEmailValue) => {
    const regexp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidEmail = regexp.test(String(email).toLowerCase());
    if (!isValidEmail) {
      toast.error("Please enter a valid email address", {
        position: "top-center",
        transition: Bounce,
      })
      return
    }
    if (inputType === 'receiver') {
      const updatedReceivers = receiverName ? receiverName.split(',').filter(Boolean) : [];
      updatedReceivers.push(email);
      const uniqueReceivers = [...new Set(updatedReceivers)];
      const updatedReceiverString = uniqueReceivers.join(',') + ',';
      setReceiverName(updatedReceiverString);
      localStorage.setItem("recipient", updatedReceiverString);
      setEmailId("")
    } else if (inputType === 'cc') {
      const updatedCC = CC ? CC.split(',').filter(Boolean) : [];
      updatedCC.push(email);
      const uniqueReceivers = [...new Set(updatedCC)];
      const updatedReceiverString = uniqueReceivers.join(',') + ',';
      setCC(updatedReceiverString);
      localStorage.setItem("cc", updatedReceiverString);
      setEmailCC("")
    } else if (inputType === 'bcc') {
      const updatedBCC = BCC ? BCC.split(',').filter(Boolean) : [];
      updatedBCC.push(email);
      const uniqueReceivers = [...new Set(updatedBCC)];
      const updatedReceiverString = uniqueReceivers.join(',') + ',';
      setBCC(updatedReceiverString);
      setEmailBCC("")
      localStorage.setItem("bcc", updatedReceiverString);
    }
    setSuggestions([]);
  };

  const handleFocus = (fieldType) => {
    setSuggestions([]);
    setIsFocused(prevState => ({
      receiver: false,
      cc: false,
      bcc: false,
    }));
    setIsFocused(prevState => ({ ...prevState, [fieldType]: true }));
  };

  const handleTabKey = (e) => {
    const editorContent = document.querySelector('.ql-editor');
    if (e.key === "Tab") {
      if (e.shiftKey) {
      } else {
        e.preventDefault();
        editorContent.focus();
      }

    }
  };

  let draggedItemIndex = null;
  let draggedSource = null;
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDragStart = (e, index, source, value) => {
    draggedItemIndex = index;
    draggedSource = source;
    const response = value && value.split(",").length > 0 ? value.split(",").filter(Boolean) : [];

    e.dataTransfer.setData("text", response[index]);
  };

  const handleDropText = (e, targetIndex, targetSource, setTargetSource) => {
    e.preventDefault();
    let draggedItems = [];
    let setDraggedSource;

    if (draggedSource === "receiver") {
      draggedItems = receiverName.split(",").filter(Boolean);
      setDraggedSource = setReceiverName;
    } else if (draggedSource === "cc") {
      draggedItems = CC.split(",").filter(Boolean);
      setDraggedSource = setCC;
    } else if (draggedSource === "bcc") {
      draggedItems = BCC.split(",").filter(Boolean);
      setDraggedSource = setBCC;
    }

    const [movedItem] = draggedItems.splice(draggedItemIndex, 1);
    setDraggedSource(draggedItems.join(","));

    const targetItems = targetSource.split(",").filter(Boolean);

    if (targetIndex >= 0) {
      targetItems.splice(targetIndex, 0, movedItem);
    } else {
      targetItems.push(movedItem);
    }
    const updatedvalue = [...new Set(targetItems)]
    setTargetSource(updatedvalue.join(","));
    draggedItemIndex = null;
    draggedSource = null;
  };

  const handleDropTexts = (e, targetSource, setTargetSource, inputValue, setInputValue) => {
    e.preventDefault();
    const droppedText = e.dataTransfer.getData("text");
    if (!droppedText) return;

    let draggedItems, setDraggedSource;
    if (draggedSource === "receiver") {
      draggedItems = receiverName.split(",");
      setDraggedSource = setReceiverName;
    } else if (draggedSource === "cc") {
      draggedItems = CC.split(",");
      setDraggedSource = setCC;
    } else if (draggedSource === "bcc") {
      draggedItems = BCC.split(",");
      setDraggedSource = setBCC;
    }
    const updatedDraggedItems = draggedItems.filter((email) => email !== droppedText);
    setDraggedSource(updatedDraggedItems.join(","));
    const res = targetSource ? targetSource.split(",").filter(Boolean) : []
    const updatedTargetItems = [...new Set([...res, droppedText])];
    setTargetSource(updatedTargetItems.join(","));
    draggedItemIndex = null;
    draggedSource = null;
  };

  const renderSuggestion = () => {
    const isSelected = receiverName
      ? receiverName.split(",").filter(Boolean).includes(emailCC)
      : false;
    const emailValue = BCC
      ? BCC.split(",").filter(Boolean).includes(emailCC)
      : false;
    const emailCCValue = CC
      ? CC.split(",").filter(Boolean).includes(emailCC)
      : false;

    if (!isSelected && !emailValue && !emailCCValue) {
      return (
        <div className="suggestion-list">
          <div className="suggestion-body">
            <div
              className="suggestion-list-email"
              key={emailCC}
              onClick={() => handleSuggestionClick(emailCC, setCC)}
            >
              <div className="user-profile-pic">{emailCC?.charAt(0)?.toUpperCase()}</div>
              <div>{emailCC}</div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };


  const renderSuggestions = () => {
    const isSelected = receiverName
      ? receiverName.split(",").filter(Boolean).includes(emailCC)
      : false;
    const emailValue = BCC
      ? BCC.split(",").filter(Boolean).includes(emailCC)
      : false;
    const emailCCValue = CC
      ? CC.split(",").filter(Boolean).includes(emailCC)
      : false;

    if (!isSelected && !emailValue && !emailCCValue) {
      return (
        <div className="suggestion-list">
          <div className="suggestion-body">
            <div
              className="suggestion-list-email"
              key={emailBCC}
              onClick={() => handleSuggestionClick(emailBCC, setBCC)}
            >
              <div className="user-profile-pic">{emailBCC?.charAt(0)?.toUpperCase()}</div>
              <div>{emailBCC}</div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };
  const handleDelete = (e, index, setValue, receiverName) => {
    e.preventDefault();
    const nameArray = receiverName.split(",");
    if (index >= 0 && index < nameArray.length) {
      nameArray.splice(index, 1);
    }
    setValue(nameArray.join(","));
  };
  const handleClick = (e, index, value, setValue, setResponse) => {
    const updatedReceiverNames = value.split(',').filter(Boolean);
    setValue(updatedReceiverNames[index])
    updatedReceiverNames.splice(index, 1);
    setResponse(updatedReceiverNames.join(','));
  }


  return (

    <div className={'message-box-position ' + appendClassName + " " + isMini} >
      <div className='compose-email-send' id="compose-email-send">
        <div className='header-compose-email'>
          <span> New Message</span>
          <div className="cursor-pointer flex items-center gap-10" >
            <div onClick={() => { dispatch({ type: "COMPOSE_MIN", payload: true }); }} > {logo.compose_mini}    </div>
            {/* <Popconfirm title="Are you sure you want to save draft?" onConfirm={addToDraft} onCancel={closeCompose} okText="Yes" cancelText="No"> */}
            <div onClick={() => addToDraft()}>{logo.compose_close}</div>
            {/* </Popconfirm> */}
          </div>
        </div>

        <div className='input-compose-msg'>
          <div className='send-alert-body-content connect-wallet-account-div reply-email-user'>
            <div className="email-username reply-user compose">
              {/* <span>To</span> */}
              <input className="reply-user-input compose" placeholder="Sender" id={"sender"} defaultValue={user.name} readonly='true' />
            </div>
            <div className="dragValue" >
              <div className="mailContent">
                <ul className={ctrlA ? "maindiv hoverColor" : "maindiv"}>
                  <span className="title">TO</span>
                  {receiverName && receiverName.split(",").length > 0 ? receiverName.split(",").filter(name => name !== "").map((data, index) =>
                    <li key={index} className="receiverEmails" style={{ textDecoration: "none", listStyle: "none", cursor: "pointer", margin: "5px" }} draggable
                      onDragStart={(e) => handleDragStart(e, index, "receiver", receiverName)}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDropText(e, index, receiverName, setReceiverName)}
                      onClick={(e) => {
                        setInputType('receiver');
                        handleClick(e, index, receiverName, setEmailId, setReceiverName)
                      }}
                    >
                      <div className="mainDiv">
                        <p className="receiverPhoto">{data[0] ? data[0].toUpperCase() : data[1].toUpperCase}</p>
                        <p >{data}</p>
                        <p className="close_icon" style={{ marginLeft: "5px" }} onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(e, index, setReceiverName, receiverName)
                        }}> {logo.compose_close}</p>
                      </div>
                    </li>
                  )
                    : null}
                  <div className="email-username reply-user compose" style={{
                    flex: 1, border: 0, padding: 0,
                    alignItems: "center", minWidth: "150px", position: "relative"
                  }}>

                    <input style={{ paddingLeft: "10px" }}
                      className="reply-user-input suggestion-input compose"
                      // placeholder="Recipient"
                      id={"receiver"}
                      value={emailId}
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={(e) => {
                        handleDropTexts(e, receiverName, setReceiverName, emailId, setEmailId)
                      }}
                      onClick={() => { setInputType('receiver'); }}
                      onKeyDown={(e) => handleKeyDown(e, receiverName, setReceiverName, setCtrlA, 'receiver', ctrlA)}
                      onChange={(e) => { localStorage.setItem("recipient", e.target.value); handleInputChange(e.target.value, setEmailId, 'receiver') }}
                      onFocus={() => handleFocus('receiver')}
                      onBlur={() => {
                        setTimeout(() => {
                          setSuggestions([]);
                          setEmailId("")
                        }, 200);
                      }}
                      autoComplete="off"
                    />
                    {!ctrlA && emailId && suggestions.length === 0 && (
                      <div className="suggestion-list">
                        <div className="suggestion-body">
                          <div
                            className="suggestion-list-email"
                            key={emailId}
                            onClick={() => handleSuggestionClick(emailId, setReceiverName)}
                          >
                            <div className="user-profile-pic">{emailId?.charAt(0)?.toUpperCase()}</div>
                            <div>{emailId}</div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* Display suggestions for Recipient */}
                    {isFocused.receiver && suggestions.length > 0 && (
                      <div className="suggestion-list">
                        <div className="suggestion-body">
                          {suggestions.map((email) => {
                            const isSelected = receiverName
                              ? receiverName.split(",").filter(Boolean).includes(email) : false;
                            const emailValue = CC ? CC.split(",").filter(Boolean).includes(email) : false;
                            if (!isSelected && !emailValue) {
                              return (
                                <div
                                  className="suggestion-list-email"
                                  key={email} // Use email as the key for uniqueness
                                  onClick={() => handleSuggestionClick(email, setReceiverName)}
                                >
                                  <div className="user-profile-pic">{email.charAt(0).toUpperCase()}</div>
                                  <div>{email}</div>
                                </div>
                              );
                            }
                            else {
                              return (
                                <div style={{
                                  pointerEvents: "none",
                                  opacity: 0.5,
                                }}
                                  className="suggestion-list-email"
                                  key={email} // Use email as the key for uniqueness
                                  onClick={() => handleSuggestionClick(email, setReceiverName)}
                                >
                                  <div className="user-profile-pic">{email.charAt(0).toUpperCase()}</div>
                                  <div>{email}</div>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </ul>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="compose-cc-bcc" onClick={() => setIsCC(!isCC)}> CC </span>
                <span className="compose-cc-bcc" onClick={() => setIsBCC(!isBCC)}> BCC </span>
              </div>
            </div>
            {isCC && (
              <div className="dragValue" >
                <div className="mailContent">
                  <ul className={ctrlCC ? "maindiv hoverColor" : "maindiv"}>
                    <span className="title">CC</span>
                    {CC && CC.split(",").length > 0 ? CC.split(",").filter(name => name !== "").map((data, index) =>
                      <li key={index} className="receiverEmails" style={{ textDecoration: "none", listStyle: "none", cursor: "pointer", margin: "5px" }} draggable
                        onDragStart={(e) => handleDragStart(e, index, "cc", CC)}
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDropText(e, index, CC, setCC)}
                        onClick={(e) => {
                          setInputType('cc');
                          handleClick(e, index, CC, setEmailCC, setCC)
                        }}
                      >
                        <div className="mainDiv">
                          <p className="receiverPhoto">{data[0]?.toUpperCase()}</p>
                          <p >{data}</p>
                          <p className="close_icon" style={{ marginLeft: "5px" }} onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(e, index, setCC, CC);
                          }}> {logo.compose_close}</p>
                        </div>
                      </li>
                    )
                      : null}
                    <div className="email-username reply-user compose" style={{
                      flex: 1, border: 0, padding: 0,
                      alignItems: "center", minWidth: "150px", position: "relative"
                    }}>
                      <input style={{ paddingLeft: "10px" }}
                        className="reply-user-input suggestion-input compose"
                        value={emailCC}
                        onClick={() => { setInputType('cc'); }}
                        onChange={(e) => { localStorage.setItem("cc", e.target.value); handleInputChange(e.target.value, setEmailCC, 'cc') }}
                        onFocus={() => handleFocus('cc')}
                        onBlur={() => {
                          setTimeout(() => {
                            setSuggestions([]);
                            setEmailCC("")
                          }, 200);
                        }}
                        onKeyDown={(e) => handleKeyDown(e, CC, setCC, setCtrlCC, 'cc', ctrlCC)}
                        autoComplete="off"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => {
                          handleDropTexts(e, CC, setCC, emailCC, setEmailCC)
                        }}
                      />
                      {!ctrlCC && emailCC && suggestions.length === 0 && renderSuggestion()}

                      {isFocused.cc && suggestions.length > 0 && (
                        <div className="suggestion-list" >
                          <div className="suggestion-body">
                            {suggestions.map((email) => {
                              const isSelected = receiverName
                                ? receiverName.split(",").filter(Boolean).includes(email) : false;
                              const emailValue = CC ? CC.split(",").filter(Boolean).includes(email) : false;
                              // const BCC = BCC ? BCC.split(",").filter(Boolean).includes(email) : false; 
                              if (!isSelected && !emailValue && !BCC) {
                                return (
                                  <div
                                    className="suggestion-list-email"
                                    key={email} // Use email as the key for uniqueness
                                    onClick={() => handleSuggestionClick(email, setReceiverName)}
                                  >
                                    <div className="user-profile-pic">{email.charAt(0).toUpperCase()}</div>
                                    <div>{email}</div>
                                  </div>
                                );
                              }
                              else {
                                return (
                                  <div style={{
                                    pointerEvents: "none",
                                    opacity: 0.5,
                                  }}
                                    className="suggestion-list-email"
                                    key={email} // Use email as the key for uniqueness
                                    onClick={() => handleSuggestionClick(email, setReceiverName)}
                                  >
                                    <div className="user-profile-pic">{email.charAt(0).toUpperCase()}</div>
                                    <div>{email}</div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </ul>
                </div>
              </div>
            )}

            {isBCC && (
              <div className="dragValue"  >
                <div className="mailContent">
                  <ul className={ctrlBCC ? "maindiv hoverColor" : "maindiv"}>
                    <span className="title">BCC</span>
                    {BCC && BCC.split(",").length > 0 ? BCC.split(",").filter(name => name !== "").map((data, index) =>
                      <li key={index} className="receiverEmails" style={{ textDecoration: "none", listStyle: "none", cursor: "pointer", margin: "5px" }} draggable
                        onDragStart={(e) => handleDragStart(e, index, "bcc", BCC)}
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDropText(e, index, BCC, setBCC)}
                        onClick={(e) => {
                          setInputType('bcc');
                          handleClick(e, index, BCC, setEmailBCC, setBCC)
                        }} >
                        <div className="mainDiv">
                          <p className="receiverPhoto">{data[0]?.toUpperCase()}</p>
                          <p >{data}</p>
                          <p className="close_icon" style={{ marginLeft: "5px" }} onClick={(e) => {
                            e.stopPropagation()
                            handleDelete(e, index, setBCC, BCC)
                          }}> {logo.compose_close}</p>
                        </div>
                      </li>
                    )
                      : null}
                    <div className="email-username reply-user compose" style={{
                      flex: 1, border: 0, padding: 0,
                      alignItems: "center", minWidth: "150px", position: "relative"
                    }}>

                      <input style={{ paddingLeft: "10px" }}
                        // placeholder="BCC"
                        className="reply-user-input suggestion-input compose"
                        value={emailBCC}
                        onClick={() => { setInputType('bcc'); }}
                        onChange={(e) => { localStorage.setItem("bcc", e.target.value); handleInputChange(e.target.value, setEmailBCC, 'bcc') }}
                        onFocus={() => handleFocus('bcc')}
                        onBlur={() => {
                          setTimeout(() => {
                            setSuggestions([]);
                            setEmailBCC("")
                          }, 200);
                        }}
                        onKeyDown={(e) => handleKeyDown(e, BCC, setBCC, setCtrlBCC, 'bcc', ctrlBCC)}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => {
                          handleDropTexts(e, BCC, setBCC, emailBCC, setEmailBCC)
                        }}
                        autoComplete="off"
                      />
                      {!ctrlBCC && emailBCC && suggestions.length === 0 && renderSuggestions()}

                      {isFocused.bcc && suggestions.length > 0 && (
                        <div className="suggestion-list" >
                          <div className="suggestion-body">
                            {suggestions.map((email) => {
                              const isSelected = receiverName
                                ? receiverName.split(",").filter(Boolean).includes(email) : false;
                              const emailValue = receiverName ? receiverName.split(",").filter(Boolean).includes(email) : false;
                              const emailBCCValue = BCC ? BCC.split(",").filter(Boolean).includes(email) : false;
                              if (!isSelected && !emailValue && !emailBCCValue) {
                                return (
                                  <div
                                    className="suggestion-list-email"
                                    key={email} // Use email as the key for uniqueness
                                    onClick={() => handleSuggestionClick(email, setReceiverName)}
                                  >
                                    <div className="user-profile-pic">{email.charAt(0).toUpperCase()}</div>
                                    <div>{email}</div>
                                  </div>
                                );
                              }
                              else {
                                return (
                                  <div style={{
                                    pointerEvents: "none",
                                    opacity: 0.5,
                                  }}
                                    className="suggestion-list-email"
                                    key={email} // Use email as the key for uniqueness
                                    onClick={() => handleSuggestionClick(email, setEmailBCC)}
                                  >
                                    <div className="user-profile-pic">{email.charAt(0).toUpperCase()}</div>
                                    <div>{email}</div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </ul>
                </div>
              </div>
            )}

            <div className="email-username reply-user compose topic">
              {/* <span>Topic:</span> */}
              <input
                className="reply-user-input"
                id={"subject"}
                placeholder="Topic"
                value={subject}
                onKeyDown={handleTabKey}
                onChange={(e) => { localStorage.setItem("topic", e.target.value); setSubject(e.target.value) }}
              />
            </div>
          </div>
          <Editor placeholder="Write something..." />
        </div>
        {sendLoader &&
          <CompleteLoader isOpen={sendLoader} />
        }
      </div>
    </div >
  )
}

export default ComposeEmail
