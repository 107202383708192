import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import "../App.css";
import { IconContext } from "react-icons";
import { logo } from "../assets/svg/svg.js"
import "../components/styles/navbar.css"
import Cookies from "universal-cookie";
import { getHostContract, getHostHelperContract, getSuggestion } from "../helper/contract-helper.js";
import { getSettings } from "../helper/settingsHelper.js";
import { formatMessageList } from "../helper/dataFormatHelper.js";
import { optionalValidation } from "../helper/object-validation-helper.js";
import { CompleteLoader, CreateFolderModal, QuickSendModal } from "../modals/common-alert.js";
import { CreateEvent } from "../modals/CreacteEvent.js";
import { folders } from "../assets/svg/folder-svg.js";
import { useDispatch, useSelector } from "react-redux";
import contant from "../constants/constants.json";
import config from "../web3/web3Config.json";
import { transactionAction } from "../helper/chain-helper.js";
import { getInsecure } from "../service/api-actions";
import { Tooltip } from 'antd';
import Swal from 'sweetalert2'
import { updateEmailAttributeValue } from '../helper/emailHelper.js';
import { getSingnature, decodeHtml } from "../helper/settingsHelper.js";
import { getGeneralSettings } from '../helper/settingsHelper.js';
const cookies = new Cookies();

function Navbar() {

  const history = useNavigate();
  const dispatch = useDispatch();
  const getRefreshMessage = useSelector((state) => state.refreshMessage);
  const isCompose = useSelector((state) => state.isCompose);
  const refreshSettings = useSelector((state) => state.refreshSettings);
  const selectedMessage = useSelector((state) => state.selectedMessage);
  const [activeIndex, setActiveIndex] = useState(0);
  const [quick, setAddQuick] = useState(false);
  const [user] = useState(cookies.get("userObject"));
  const [indexCount, setIndexCount] = useState(0);
  const [insecureCount, setInsecureCount] = useState(0);
  const [folderList, setFolderList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [quickUserList, setQuickUserList] = useState([]);
  const [folderModal, setFolderModal] = useState(false);
  const [activeFolderIndex, setFolderActiveIndex] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isQuickAccessEdit, setIsQuickAccessEdit] = useState(false);
  const [isInsecure, setIsInsecure] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 1170);
  const [showDeleteIcon, setShowDeleteIcon] = useState(null);
  const [isOpenEvent, setIsOpenEvent] = useState(false);
  const folderUpdate = useSelector((state) => state.folderUpdate)
  const pathName = window.location.pathname;

  const handleDivClick = async (index, itemValue) => {
    dispatch({ type: "SELECT_MESSAGE", payload: !selectedMessage });
    dispatch({ type: "SELECT_TYPE", payload: null });
    setFolderActiveIndex(index);
    fetchData();
    setActiveIndex("");
    dispatch({ type: "FOLDER_INDEX", payload: index });
    localStorage.setItem('selectedFolderId', itemValue.id)
    history("/folders");
  };
  const triggerNewEvent = () => {
    setIsOpenEvent(true);
  }

  useEffect(() => {
    window.addEventListener('NEW_EVENT', triggerNewEvent);
    return () => {
      window.removeEventListener('NEW_EVENT', triggerNewEvent);
    };
  }, []);

  useEffect(() => {
    const pageType = pathName.replace("/", "");
    if (pageType === "folders") {
      setActiveIndex(0);
      history("/inbox");
    }
    getCountByType();
    fetchData();
    fetchSettings();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const handleWindowResize = () => {
    const screenIsSmall = window.innerWidth < 1170;
    setIsScreenSmall(screenIsSmall);

    if (!screenIsSmall) {
      document.body.classList.remove("leftsidebar-toggle-menu");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    handleWindowResize();

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleClickOutsideSidebar = (e) => {
    if (!e.target.closest("#leftsidebar") && !e.target.closest(".bars") && e.target.classList.contains("overlay")) {
      if (isScreenSmall) {
        setIsSidebarOpen(false);
        document.body.classList.remove("leftsidebar-toggle-menu");
      }
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.classList.add("leftsidebar-toggle-menu");
      document.addEventListener("click", handleClickOutsideSidebar);
    } else {
      document.body.classList.remove("leftsidebar-toggle-menu");
      document.removeEventListener("click", handleClickOutsideSidebar);
    }

    return () => {
      document.removeEventListener("click", handleClickOutsideSidebar);
    };
  }, [isSidebarOpen, isScreenSmall]);

  const handleOverlayClick = () => {
    document.body.classList.remove("leftsidebar-toggle-menu");
  };
  useEffect(() => {
    fetchSettings();
  }, [refreshSettings]);

  useEffect(() => {
    const pageType = pathName.replace("/", "");
    const pageIndex = contant.pageType[pageType];

    if (pageIndex) {
      setActiveIndex(pageIndex);
    }
  }, [pathName]);

  async function fetchSettings() {
    try {
      const settingsJson = await getGeneralSettings();
      const insecure = settingsJson.isWeb2Inbox;
      setIsInsecure(insecure);
    } catch (error) {
      return false;
    }
  }

  async function fetchData() {
    try {
      const helperContract = await getHostHelperContract();
      const filteredAddressBook = await getSuggestion();
      const folders = await helperContract.methods.getFolders(user.name).call({ from: user.wallet });
      const usersQuickSend = await helperContract.methods.getUsersQuickSend(user.name).call({ from: user.wallet });
      setQuickUserList(usersQuickSend);
      setAddressList(filteredAddressBook);
      setFolderList(folders);
      dispatch({ type: "FOLDERS_LIST", payload: folders })
      dispatch({ type: "ADDRESS_LIST", payload: filteredAddressBook });
    }
    catch (err) {
      console.error(err)
    }

  }



  useEffect(() => {
    fetchData()
  }, [folderUpdate])

  async function setCountData() {
    getCountByType();
  }
  const getCount = useSelector((state) => state.refreshCount);
  useEffect(() => {
    setCountData();
  }, [getCount]);

  async function getEmailCount(emailType) {
    try {
      const hostContract = getHostContract();
      const emailList = await hostContract.methods[emailType](user.name).call({ from: user.wallet });
      return await formatMessageList(emailList);
    } catch (error) {
      return null;
    }
  }

  async function getCountByType() {
    const inboxUnreadCount = await getEmailCount("getEmailList");
    const unReadInboxCount = inboxUnreadCount.unReadList.filter(data => !data.isArchive && !data.isDeleted && !data.folder && data.date && !data.isSaved).length || 0;
    const insecureList = await getInsecure(user.name);
    const unreadInsecureCount = insecureList?.res?.filter((data) =>
    (data.parts[0].status === 0 &&
      data.parts[0].is_achieve === false &&
      (data.parts[0].deleted_flag === 0 || data.parts[0].deleted_flag === 2))
    ).length || 0;
    setInsecureCount(unreadInsecureCount);
    setIndexCount(unReadInboxCount);
  }


  function returnCountValue(index) {
    const returnArray = [indexCount, insecureCount, 0, 0, 0, 0, 0, 0];
    return returnArray[index];
  }

  function refreshBoxByType() {
    dispatch({ type: "REFRESH_MESSAGE", payload: !getRefreshMessage });
    setCountData();
  }

  function closeQuickUser() {
    setAddQuick(false);
    setFolderModal(false);
    fetchData();
  }

  function closeEvent() {
    setIsOpenEvent(false)
  }

  function getColorFromJson(jsonString) {
    try {
      const json = jsonString;
      const color = json.folderProperties.find(value => value.key == 'color')['value']
      return color;
    } catch (error) {
      return "green";
    }
  }

  const handleDrop = async (e, folderName) => {
    e.preventDefault();
    setLoader(true);
    const id = e.dataTransfer.getData('message_id');
    const messageType = e.dataTransfer.getData('message_type');
    if (id && messageType) {
      await updateEmailAttributeValue(user.name, [id], [{key:'folder', value: (folderName.id).toString() }])
    }
    renderPage();
    setLoader(false);
  };

  const allowDrop = (e) => {
    e.preventDefault();
  };



  async function renderPage() {
    getCountByType();
    fetchData();

  }


  async function deleteQuickAccess(index) {
    setLoader(true);
    const host = getHostHelperContract();
    const functionParams = [user.name, index];
    await transactionAction(host, "deleteQuickSendEntry", functionParams, user.wallet);
    await renderPage();
    setLoader(false);
  }

  const deleteFolderMessage = async (id) => {
    Swal.fire({
      text: "Are you sure you want to delete the folder and all its contents to Delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1677ff",
      cancelButtonColor: "#ffffff",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: "compose-sweet-alert"
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoader(true);
        const helperContract = getHostHelperContract();
        const functionParams = [user.name, parseInt(id)];
        await transactionAction(helperContract, "deleteFolder", functionParams, user.wallet);
        await renderPage();
        history('/inbox');
        setLoader(false);
      }
    });
  }

  const singnature = async () => {
    const signatureData = await getSingnature();
    if (signatureData.signature && !localStorage.getItem("sendingEmail")) {
      const decodedSignature = signatureData.isHtml ? decodeHtml(signatureData.signature) : signatureData.signature;
      localStorage.setItem("sendingEmail", `<div>
        <p><br></p> 
        <p><br></p>
        ${decodedSignature}
      </div>`)
    } else {
      localStorage.setItem("sendingEmail", '')
    }
  }

  const handleStartDrag = (e, item) => {
    e.dataTransfer.setData("text/plain", JSON.stringify(item));

  };

  const handleMailDrag = (e) => {
    e.preventDefault();

  };

  return (
    <>
      <IconContext.Provider value={{ color: "undefined" }}>
        <aside id="leftsidebar" className={`sidebar nav-menu ${isSidebarOpen ? 'open' : ''}`}>
          <div className="nav-menu-items">
            <div className="navbar-toggle">
              <div className="logo-content cursor-pointer" onClick={() => {
                history("/inbox")
              }}>
                {logo.dmail_logo_svg}
                <h4 className="dmail-content-h4"> {logo.dmail_name_svg} </h4>
              </div>
            </div>
            <div className="account-div-content">
              <div className="account-sec-navbar">
                <span>Account</span>
                <div> {`${user.name}`} </div>
              </div>
              <div className="compose-sec-navbar" id="compose">
                <button className="compose-gradient-btn" onClick={async () => {
                  if (!isCompose) {
                    localStorage.setItem("recipient", '');
                    localStorage.setItem("topic", '');
                    localStorage.setItem("cc", '')
                    localStorage.setItem("bcc", '');
                    await singnature();
                  }
                  dispatch({ type: "IS_COMPOSE", payload: true })
                }}>
                  {logo.compose_btn_svg}
                  <div className="compose-gradient-btn-child">
                    <span className="new-msg-font">Compose</span>
                    <span>(Gas 0.005 {config.SYMBOL})</span>
                  </div>
                </button>
              </div>
            </div>
            <div className="class-scroll-bar-navbar" id="style-2" >
              <div className="message-type" >
                <div className="menu-header-data">
                  <span className="menu-type-header">Menu</span>
                </div>
                <ul style={{ padding: 0 }}>
                  {SidebarData.map((item, index) => {
                    if (!isInsecure && item.title === "Web2 Inbox") {
                      return null;
                    }
                    const isActive = activeIndex === index;
                    const linkClass = isActive ? `${item.cName} activated` : `${item.cName} in-activated`;
                    const handleDragOver = (e) => {
                      e.preventDefault();
                    }
                    const handleDrop = (e, title) => {
                      e.preventDefault();
                      const data = e.dataTransfer.getData("text");
                    }
                    return (
                      <Tooltip title={item.title} placement="right">
                        <li key={index} className={linkClass} id={item.id} onDrop={(e) => { handleDrop(e, item.title) }}
                          onDragOver={item.dragging ? handleDragOver : null}
                        >
                          {isActive && <div className="dummy-border-append"> </div>}
                          <Link to={item.path} className={`${item.cName}-a-tag`} onClick={() => {
                            dispatch({ type: "SELECT_MESSAGE", payload: !selectedMessage });
                            dispatch({ type: "SELECT_TYPE", payload: null });
                            setFolderActiveIndex(""); setActiveIndex(index);
                          }} >
                            <div className="row">
                              <div className="col-1 width-2r">{logo[`${item.icon}_logo_svg`]}</div>
                              <div className="col width-11r">
                                <span className="white-space-nowrap">{item.title}</span>
                              </div>
                              <div className="col d-flex align-items-center justify-content-end gap-10 " style={{ width: returnCountValue(index) === 0 ? '4rem' : '' }}>
                                {isActive &&
                                  <div onClick={() => refreshBoxByType(item.title)}>
                                    {logo.reload_svg}
                                  </div>
                                }
                                {!isActive &&
                                  <div className="width-12"></div>
                                }
                                {returnCountValue(index) > 0 && (
                                  <span className="count-type">{returnCountValue(index)}</span>
                                )}
                              </div>
                            </div>
                          </Link>
                        </li>
                      </Tooltip>
                    );
                  })}
                </ul>
              </div>
              <div className="message-type">
                <div className="menu-header-data">
                  <span className="menu-type-header">Quick Send to : <span className="type-num-count">{quickUserList.length}</span> </span>
                  {quickUserList.length > 0 && (
                    <button className="edit-list-bth" onClick={() => {
                      setIsQuickAccessEdit(!isQuickAccessEdit);
                    }}>
                      {isQuickAccessEdit ? "Close" : "Edit List"}
                    </button>
                  )}
                </div>
                <div className="quick-access-content">
                  {quickUserList.length > 0 && quickUserList.map((itemValue, keyIndex) => (
                    <div key={keyIndex} className="div-class-foot-quick-child cursor-pointer" onClick={() => {
                      if (!isCompose) {
                        localStorage.setItem("recipient", itemValue.userEmail);
                      }
                      dispatch({ type: "IS_COMPOSE", payload: true })
                    }} >
                      <div className="circle-add-user"> {itemValue.userName[0].toUpperCase()} </div>
                      <div className="close-icon-quick-access-ele position-relative-cls">
                        <div className="quick-user name"> {itemValue.userName} </div>
                        <div className="quick-user email"> {itemValue.userEmail} </div>

                        {isQuickAccessEdit &&
                          <div className="close-icon-quick-access position-absolute-cls" onClick={(e) => {
                            e.stopPropagation();
                            deleteQuickAccess(keyIndex)
                          }} > x </div>
                        }
                      </div>
                    </div>
                  ))}
                  <div className="div-class-foot-quick-child cursor-pointer" onClick={() => {
                    fetchData();
                    setAddQuick(true);
                  }}>
                    <div className="circle-add-user">+</div>
                    <span className="add-new-user-span">Add New </span>
                  </div>
                </div>
              </div>
              <div className="message-type">
                <div className="menu-header-data">
                  <span className="menu-type-header">Folder :  <span className="type-num-count">{folderList.length}</span> </span>
                  <button className="edit-list-bth" onClick={() => {
                    setFolderModal(true)
                  }} > Create </button>
                </div>
                <div className="folder-content">
                  {folderList.length > 0 && folderList.map((itemValue, keyIndex) => (
                    <div key={keyIndex}
                      onDrop={(e) => {
                        handleDrop(e, itemValue)
                      }}
                      onDragOver={allowDrop}
                      className={`div-class-folder-content cursor-pointer ${keyIndex === activeFolderIndex ? 'active' : ''}`}
                      onClick={() => handleDivClick(keyIndex, itemValue)}
                      onMouseEnter={() => setShowDeleteIcon(keyIndex)} onMouseLeave={() => setShowDeleteIcon(null)}
                    >
                      <div className="folder-common-element"  >
                        {folders.folderIcon[`${getColorFromJson(itemValue)}Folder`]}
                        <div className="sidenav-folder-content d-flex justify-content-between align-item-center"> <p style={{ overflowWrap: "break-word", width: "200px", marginBottom: "0px" }}>{itemValue.name}</p> {showDeleteIcon === keyIndex && <Tooltip title="Delete"><span className="mt-2" onClick={(e) => { e.stopPropagation(); deleteFolderMessage(itemValue.id) }}>{logo.trash_logo_svg}</span></Tooltip>}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <CompleteLoader isOpen={loader} />
          <QuickSendModal isOpen={quick} addressList={addressList} quickUserList={quickUserList} close={() => { closeQuickUser() }} />
          <CreateFolderModal isOpen={folderModal} close={() => { closeQuickUser() }} />
          <CreateEvent isOpen={isOpenEvent} close={() => { closeEvent() }} />
          <div className="overlay" onClick={handleOverlayClick} />
        </aside>
      </IconContext.Provider >
    </>
  );
}
export default Navbar;
