import React, { useState } from 'react';
import { copyEmail } from '../../helper/email-helper';
import Cookies from "universal-cookie";
import { folders } from '../../assets/svg/folder-svg';
import { deleteEmails, updateAttribute, updateEmailAttributeValue } from '../../helper/emailHelper.js';
import { updateInsecureMail } from '../../service/api-actions.js';
import { CreateFolderModal } from "../../../src/modals/common-alert.js"
const cookies = new Cookies();

function getColorFromJson(jsonString) {

    try {
        const json = jsonString;
        const color = json.folderProperties.find(value => value.key == 'color')['value']
        return color;
    } catch (error) {
        return "green";
    }

}

const ContextMenu = ({ x, y, onClose, types, selectedContext, loader, pageType, reRender, messageList, selectedEmailList }) => {
    const [visible, setVisible] = useState(true); // State to manage visibility of the context menu
    const [user] = useState(cookies.get("userObject"));
    const isArchive = ["Important", "Inbox", "Sent"].includes(pageType);
    const [folderPopup, setFolderPopup] = useState(false)
    // Function to handle click outside of the context menu
    const handleClickOutside = (event) => {
        if (!event.target.closest('.contextMenu')) {
            onClose();
        }
    };

    // Attach click event listener to handle clicks outside of the context menu
    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    // Style object to position the context menu
    const style = {
        position: 'fixed',
        left: x + 'px',
        top: y + 'px',
        background: 'white'
    };

    // Function to handle context menu option click
    const handleOptionClick = async (folderName) => {
        const type = pageType === 'Sent' ? `sent-folder-${parseInt(folderName.id)}` : `inbox-folder-${parseInt(folderName.id)}`;
        loader(true);
        if (pageType === "Insecure") {
            await updateAttribute(user.name, [selectedContext.physmessage_id], type, 'folder', true);
        } else {
          await updateEmailAttributeValue(user.name, [selectedContext.id], [{key: 'folder', value: (folderName.id).toString()}]);
        }
        loader(false);
        reRender();
        onClose(); // Close the context menu
    };

    const handleRestore = async () => {
        loader(true);
        await deleteEmails(selectedContext, null, null, null, selectedContext.emailType, null);
        loader(false);
        reRender();
        onClose();
    };


    const handleweb2emails = async (id, value) => {
        loader(true);
        try {
            await updateInsecureMail("Web2emails", [id], value);
            reRender();
        } catch (error) {
            console.error("Error while updating email:", error);
        } finally {
            loader(false);
        }
    };
    const handleArchive = async (value) => {
        loader(true);
        await updateEmailAttributeValue(user.name, [selectedContext.id], [{key:'isArchive', value: (value).toString() }]);
        loader(false);
        reRender();
        onClose();
    };


    const restoreweb2email = async (id) => {
        const value = 2;
        loader(true);
        await updateInsecureMail("deleteemail", id, value);
        reRender();
        onClose();
    }
    const handleFolder = () => {
        setFolderPopup(true)
    }
    const handleCloseButton = () => {
        setFolderPopup(false)
    }

    return (
        <>
            {visible && (
                <div className="contextMenu copying-content" style={style}>
                    <div className="contextMenu--option-div" onClick={onClose} style={{ maxHeight: "450px", overflow: "auto" }}>

                        {types.length > 0 && pageType !== 'Trash' && pageType !== 'Archive' && types.map((item, index) => (
                            <>
                                <div key={index} className="contextMenu--option cursor-pointer" onClick={() => handleOptionClick(item)}>
                                    <div className="contextMenu--option-div-line"> {folders.folderIcon[`${getColorFromJson(item)}Folder`]} </div>
                                    <div> Move to {item.name} </div>
                                </div>
                            </>
                        ))}


                        {pageType === 'Trash' && selectedContext && (
                            selectedContext.mailtype === "web2emails" ? (
                                <>
                                    <div className="contextMenu--option cursor-pointer" onClick={() => restoreweb2email(selectedContext.id)}>
                                        <div className="contextMenu--option-div-line">{folders.folderIcon[`greenFolder`]}</div>
                                        <div> Restore</div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="contextMenu--option cursor-pointer" onClick={() => handleRestore()}>
                                        <div className="contextMenu--option-div-line">{folders.folderIcon[`greenFolder`]}</div>
                                        <div> Restore</div>
                                    </div>
                                </>
                            )
                        )}
                        {/* Move to Archive List */}

                        {pageType === 'Insecure' &&
                            <>
                                <div className="contextMenu--option cursor-pointer" onClick={() => {
                                    handleweb2emails(selectedContext.messageId, "TRUE")
                                }}>
                                    <div className="contextMenu--option-div-line">{folders.folderIcon[`greenFolder`]}</div>
                                    <div>Move to Archive List</div>
                                </div>
                            </>
                        }

                        {pageType === 'Archive' && selectedContext && (
                            selectedContext.mailtype === "web2emails" ? (
                                <div className="contextMenu--option cursor-pointer" onClick={() => handleweb2emails(selectedContext.messageId, "FALSE")}>
                                    <div className="contextMenu--option-div-line">{folders.folderIcon['greenFolder']}</div>
                                    <div>Move to inbox</div>
                                </div>
                            ) : (
                                <div className="contextMenu--option cursor-pointer" onClick={() => handleArchive(false)}>
                                    <div className="contextMenu--option-div-line">{folders.folderIcon['greenFolder']}</div>
                                    <div>Move to inbox</div>
                                </div>
                            )
                        )}

                        {isArchive &&
                            <>
                                <div className="contextMenu--option cursor-pointer" onClick={() => handleArchive(true)}>
                                    <div className="contextMenu--option-div-line">{folders.folderIcon[`greenFolder`]}</div>
                                    <div>Move to Archive List</div>
                                </div>

                            </>
                        }
                        <div className="contextMenu--option cursor-pointer" onClick={() => handleFolder()}>
                            <div className="contextMenu--option-div-line">{folders.folderIcon[`greenFolder`]}</div>
                            <div>Create Folder</div>

                        </div>

                    </div>
                </div>
            )}
            < CreateFolderModal isOpen={folderPopup} close={() => { handleCloseButton() }} />
        </>
    );
};

export default ContextMenu;
