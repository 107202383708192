import React from "react";

export const SidebarData = [
  {
    title: "Inbox",
    path: "/inbox",
    icon: "inbox",
    cName: "nav-text",
    id: "inbox",
    dragging: false
  },
  {
    title: "Web2 Inbox",
    path: "/insecure",
    icon: "inbox",
    cName: "nav-text",
    id: "web2inbox",
    dragging: false
  },
  {
    title: "Sent",
    path: "/sent",
    icon: "sent",
    cName: "nav-text",
    id: "sent",
    dragging: false
  },
  {
    title: "Outbox",
    path: "/outbox",
    icon: "outbox",
    cName: "nav-text",
    id: "outbox",
    dragging: false
  },
  {
    title: "Draft",
    path: "/draft",
    icon: "draft",
    cName: "nav-text",
    id: "draft",
    dragging: false
  },
  {
    title: "Important",
    path: "/important",
    icon: "important",
    cName: "nav-text",
    id: "important",
    dragging: true
  },
  {
    title: "Archive",
    path: "/archive",
    icon: "inbox",
    cName: "nav-text",
    id: "archive",
    dragging: true
  },
  {
    title: "Trash",
    path: "/trash",
    icon: "trash",
    cName: "nav-text",
    id: "trash",
    dragging: true
  }
  // {
  //   title : "DCalendar" ,
  //   path : "/calendar" ,
  //   icon : "key" ,
  //   cName : "nav-text"
  // }
];
