import { getDecryptedContentFromIndexedDb } from "../db/indexed-helper";
import { getBlockedList } from '../helper/settingsHelper.js';
import { getInsecure } from "../service/api-actions.js"
export const formatMessageList = async (messageList, type = "no_data") => {
    const allList = [];
    const readList = [];
    const unReadList = [];
    const deletedList = [];
    const archiveList = [];
    const getBlocked = await getBlockedList();
    const blockedAddresses = getBlocked.address ? getBlocked.address.split(',') : [];
    for (let msg of messageList) {
        // console.log('msg', msg);
        const idformat = type === 'Insecure' ? msg.id : parseInt(msg.id);
        const decryptedMessage = await getDecryptedContentFromIndexedDb(idformat, type.toLowerCase());
        if (msg) {
            if (blockedAddresses.includes(msg.sender)) {
                continue;
            }

            if(msg?.attributes){
                for (const element of msg?.attributes) {
                    msg[element.key] = element.value === 'true' || element.value === 'false' 
                        ? JSON.parse(element.value) 
                        : element.value;
                }
            }
            
            const returnObject = {
                messageId: idformat,
                subject: msg.subject,
                encryptedMsg: msg.encryptedData,
                date: msg.receivedDate,
                emailHeader: msg.emailDetails,
                read: msg.isRead,
                important: msg.isStarred,
                bcc: msg.isBCC,
                cc: msg.isCC,
                sender: msg.senderName,
                isStarred: msg.isStarred,
                decryptedMessage: decryptedMessage,
                ...msg
            };
            if (type === 'Trash') {
                returnObject.emailType = 'inboxtrash';

            } else if (type === 'Senttrash') {
                returnObject.emailType = 'Senttrash';
                returnObject.isPermanentDeleted = msg.isPermanentDeleted;
            }

            if (msg && !msg.isDeleted) {
                allList.push(returnObject);
                if (msg.isRead) {
                    readList.push(returnObject);
                } else {
                    unReadList.push(returnObject);
                }
            } else {
                deletedList.push(returnObject);
            }
        }
    }

    return { allList, readList, unReadList, deletedList };
}
export const getWeb2emails = async (user, type = "no_data") => {
    const insecureList = await getInsecure(user);

    const formattedresponse = await Promise.all(insecureList.res?.map(async (email) => {
        const idformat = email.parts[0].message_id;
        const decryptedMessage = await getDecryptedContentFromIndexedDb(idformat, type.toLowerCase());
        const data = JSON.parse(email.parts[0].data);
        const encrypted_data = data.encrypted_data;
        const emailDetails = JSON.parse(data.email_details[2]);
        const sender = emailDetails.sender;
        const subject = emailDetails.subject;
        const isRead = email.parts[0].status !== 0;
        const isFav = email.parts[0].fav === 1 ? true : false;
        const isArchive = email.parts[0].is_achieve;
        const deleteemails = email.parts[0].deleted_flag;
        const physmessage_id = email.physmessage_id;
        const trashtype = type === "Trash" ? "Trash" : null;
        const formatedDate = {
            type: trashtype,
            mailtype: "web2emails",
            created_at: email.parts[0].internal_date,
            receivedDate: email.parts[0].internal_date,
            id: email.parts[0].message_id,
            encryptedMsg: encrypted_data,
            encryptedData: encrypted_data,
            isStarred: isFav,
            sender: sender,
            senderName: sender,
            subject: subject,
            isRead: isRead,
            header: data.email_details[2],
            emailDetails: data.email_details[2],
            isCC: false,
            isBCC: false,
            isDeleted: false,
            isArchive,
            delete: deleteemails,
            physmessage_id,
            decryptedMessage: decryptedMessage,
        };
        return formatedDate;
    }));
    return formattedresponse;

}

export const formatEmailDetails = async (emailDetails) => {
    return emailDetails?.map((email) => {
        for (const element of email?.attributes) {
            email[element.key] = element.value === 'true' || element.value === 'false'
                ? JSON.parse(element.value)
                : element.value;
        }
        return email;
    }) || [];
}