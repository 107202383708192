import React, { useState, useEffect } from 'react';
import { logo } from '../assets/svg/svg';
import { Link } from 'react-router-dom';
import '../components/styles/settings.css';
import { useSelector } from 'react-redux';
import ComposeEmail from '../components/emails/ComposeEmail.js';
import General from '../components/settings/General.js';
import Profile from '../components/settings/Profile.js';
import FilterEmail from '../components/settings/FilterEmail.js';
import { Tooltip } from "antd"
const settingsTitle = [
    { label: "General Settings", id: 1 },
    { label: "Profile Settings", id: 2 },
    { label: "Blocked Addresses", id: 3 }
];

const Settings = () => {
    const [isCompose, setIsCompose] = useState(false);
    const [receiverNameValue, setReceiverName] = useState("");
    const [activeTitle, setActiveTitle] = useState(1);
    const [showSidebar, setShowSidebar] = useState(false); // Sidebar visibility

    const getCompose = useSelector((state) => state.isCompose);
    useEffect(() => {
        setIsCompose(getCompose);
    }, [getCompose]);

    const reRender = () => {
        // Re-render logic (not used here, placeholder)
    };

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar); // Toggle sidebar on click
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 539) {
                setShowSidebar(false); // Hide sidebar on larger screens
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div>
            <div className='box-common-header web3-id'>
                <div className='box-common-header-back web3-id'>
                    <Tooltip title="Back to Inbox" placement="top">
                        <Link to="/inbox">{logo.msg_box_back_btn}</Link>
                    </Tooltip>
                </div>
                <div className='web3-id-header-name' onClick={toggleSidebar}>
                    Settings
                </div>
            </div>

            <div className='container'>
                <div className='row gap-10 d-flex'>
                    {/* Sidebar Section */}
                    <div className={`col-md-3 settings-catagery-section ${showSidebar ? 'show-sidebar' : ''}`}>
                        {settingsTitle.map((title) => (
                            <li
                                key={title.id}
                                className={"list-group-item cursor-pointer border-bottom setting-list" + `${activeTitle === title.id ? ' gradient-text' : ''}`}
                                onClick={() => {
                                    setActiveTitle(title.id);
                                    setShowSidebar(false); // Hide sidebar after selection
                                }}
                            >
                                {title.label}
                            </li>
                        ))}
                    </div>

                    {/* Content Section */}
                    <div className='col-md-7 settings-catagery-content border-start'>
                        {activeTitle === 1 && <General />}
                        {activeTitle === 2 && <Profile />}
                        {activeTitle === 3 && <FilterEmail />}
                    </div>
                </div>
            </div>

            {/* Compose Email Section */}
            {isCompose && (
                <div>
                    <ComposeEmail setIsCompose={setIsCompose} reRender={reRender()} receiverName={receiverNameValue} pageType={'Settings'} />
                </div>
            )}
        </div>
    );
};

export default Settings;
