import Cookies from "universal-cookie";
import { getHostContract, getHostHelperContract } from "../helper/contract-helper.js"
const cookies = new Cookies();
export const getSettings = async () => {
  const user = cookies.get("userObject")
  const hostContract = getHostContract();
  const settingsJson = await hostContract.methods.getAccountSettings(user.name).call({ from: user.wallet });
  return settingsJson;
}

export const getSingnature = async () => {
  const user = cookies.get("userObject")
  const helperContract = getHostHelperContract();
  const getSettings = await helperContract.methods.getSettings(user.name).call({ from: user.wallet });
  let isHtml = false;
  let signature = null;
  let isActive = false;
  Object.values(getSettings).filter(value => value.key === 'signature').map((opt) => {
    opt.options.map((optVal) => {
      if (optVal.key === 'signatureText') signature = optVal.value;
      if (optVal.key === 'isHtml') isHtml = optVal.value.toLowerCase() === "true";
      if (optVal.key === 'isActive') isActive = optVal.value.toLowerCase() === "true";
    });
  });
  if (!isActive) return { signature: null, isHtml: false };
  return { signature, isHtml };
}

export const decodeHtml = (html) => {
  const textArea = document.createElement("textarea");
  textArea.innerHTML = html;
  return textArea.value;
};

export const getGeneralSettings = async () => {
  const user = cookies.get("userObject")
  let isSaveSent = false;
  let isIncludePrevious = true;
  let isWeb2Inbox = true;
  let isGrouped = true;
  let isSpellChecked = false;
  let zone = "system";
  const helperContract = getHostHelperContract();
  const getSettings = await helperContract.methods.getSettings(user.name).call({ from: user.wallet });
  Object.values(getSettings).filter(value => value.key === 'general').map((opt) => {
    opt.options.map((optVal) => {
      if (optVal.key === 'isSaveSent') isSaveSent = optVal.value.toLowerCase() === "true";
      if (optVal.key === 'isIncludePrevious') isIncludePrevious = optVal.value.toLowerCase() === "true";
      if (optVal.key === 'isWeb2Inbox') isWeb2Inbox = optVal.value.toLowerCase() === "true";
      if (optVal.key === 'isGrouped') isGrouped = optVal.value.toLowerCase() === "true";
      if (optVal.key === 'isSpellChecked') isSpellChecked = optVal.value.toLowerCase() === "true";
      if (optVal.key === 'zone') zone = optVal.value;
    });
  });
  localStorage.setItem("timeZone", zone);
  return { isSaveSent, isIncludePrevious, isWeb2Inbox, isGrouped, isSpellChecked, zone };
}

export const getProfileSettings = async () => {
  const user = cookies.get("userObject")
  const helperContract = getHostHelperContract();
  const getSettings = await helperContract.methods.getSettings(user.name).call({ from: user.wallet });
  let firstName = "";
  let lastName = "";
  Object.values(getSettings).filter(value => value.key === 'profile').map((opt) => {
    opt.options.map((optVal) => {
      if (optVal.key === 'firstName') firstName = optVal.value;
      if (optVal.key === 'lastName') lastName = optVal.value;
    });
  });
  return { firstName, lastName };
}

export const getBlockedList = async () => {
  const user = cookies.get("userObject")
  const helperContract = getHostHelperContract();
  const getSettings = await helperContract.methods.getSettings(user.name).call({ from: user.wallet });
  let address = '';
  Object.values(getSettings).filter(value => value.key === 'blockedAddress').map((opt) => {
    opt.options.map((optVal) => {
      if (optVal.key === 'address') address = optVal.value;
    });
  });
  return { address };
}

export const timeZone = [
  { "zoneName": "Africa/Abidjan" },
  { "zoneName": "Africa/Algiers" },
  { "zoneName": "Africa/Bissau" },
  { "zoneName": "Africa/Cairo" },
  { "zoneName": "Africa/Casablanca" },
  { "zoneName": "Africa/Ceuta" },
  { "zoneName": "Africa/El_Aaiun" },
  { "zoneName": "Africa/Johannesburg" },
  { "zoneName": "Africa/Juba" },
  { "zoneName": "Africa/Khartoum" },
  { "zoneName": "Africa/Lagos" },
  { "zoneName": "Africa/Maputo" },
  { "zoneName": "Africa/Monrovia" },
  { "zoneName": "Africa/Nairobi" },
  { "zoneName": "Africa/Ndjamena" },
  { "zoneName": "Africa/Sao_Tome" },
  { "zoneName": "Africa/Tripoli" },
  { "zoneName": "Africa/Tunis" },
  { "zoneName": "Africa/Windhoek" },
  { "zoneName": "America/Adak" },
  { "zoneName": "America/Anchorage" },
  { "zoneName": "America/Araguaina" },
  { "zoneName": "America/Argentina/Buenos_Aires" },
  { "zoneName": "America/Argentina/Catamarca" },
  { "zoneName": "America/Argentina/Cordoba" },
  { "zoneName": "America/Argentina/Jujuy" },
  { "zoneName": "America/Argentina/La_Rioja" },
  { "zoneName": "America/Argentina/Mendoza" },
  { "zoneName": "America/Argentina/Rio_Gallegos" },
  { "zoneName": "America/Argentina/Salta" },
  { "zoneName": "America/Argentina/San_Juan" },
  { "zoneName": "America/Argentina/San_Luis" },
  { "zoneName": "America/Argentina/Tucuman" },
  { "zoneName": "America/Argentina/Ushuaia" },
  { "zoneName": "America/Asuncion" },
  { "zoneName": "America/Bahia" },
  { "zoneName": "America/Bahia_Banderas" },
  { "zoneName": "America/Barbados" },
  { "zoneName": "America/Belem" },
  { "zoneName": "America/Belize" },
  { "zoneName": "America/Boa_Vista" },
  { "zoneName": "America/Bogota" },
  { "zoneName": "America/Boise" },
  { "zoneName": "America/Cambridge_Bay" },
  { "zoneName": "America/Campo_Grande" },
  { "zoneName": "America/Cancun" },
  { "zoneName": "America/Caracas" },
  { "zoneName": "America/Cayenne" },
  { "zoneName": "America/Chicago" },
  { "zoneName": "America/Chihuahua" },
  { "zoneName": "America/Ciudad_Juarez" },
  { "zoneName": "America/Costa_Rica" },
  { "zoneName": "America/Cuiaba" },
  { "zoneName": "America/Danmarkshavn" },
  { "zoneName": "America/Dawson" },
  { "zoneName": "America/Dawson_Creek" },
  { "zoneName": "America/Denver" },
  { "zoneName": "America/Detroit" },
  { "zoneName": "America/Edmonton" },
  { "zoneName": "America/Eirunepe" },
  { "zoneName": "America/El_Salvador" },
  { "zoneName": "America/Fort_Nelson" },
  { "zoneName": "America/Fortaleza" },
  { "zoneName": "America/Glace_Bay" },
  { "zoneName": "America/Goose_Bay" },
  { "zoneName": "America/Grand_Turk" },
  { "zoneName": "America/Guatemala" },
  { "zoneName": "America/Guayaquil" },
  { "zoneName": "America/Guyana" },
  { "zoneName": "America/Halifax" },
  { "zoneName": "America/Havana" },
  { "zoneName": "America/Hermosillo" },
  { "zoneName": "America/Indiana/Indianapolis" },
  { "zoneName": "America/Indiana/Knox" },
  { "zoneName": "America/Indiana/Marengo" },
  { "zoneName": "America/Indiana/Petersburg" },
  { "zoneName": "America/Indiana/Tell_City" },
  { "zoneName": "America/Indiana/Vevay" },
  { "zoneName": "America/Indiana/Vincennes" },
  { "zoneName": "America/Indiana/Winamac" },
  { "zoneName": "America/Inuvik" },
  { "zoneName": "America/Iqaluit" },
  { "zoneName": "America/Jamaica" },
  { "zoneName": "America/Juneau" },
  { "zoneName": "America/Kentucky/Louisville" },
  { "zoneName": "America/Kentucky/Monticello" },
  { "zoneName": "America/La_Paz" },
  { "zoneName": "America/Lima" },
  { "zoneName": "America/Los_Angeles" },
  { "zoneName": "America/Maceio" },
  { "zoneName": "America/Managua" },
  { "zoneName": "America/Manaus" },
  { "zoneName": "America/Martinique" },
  { "zoneName": "America/Matamoros" },
  { "zoneName": "America/Mazatlan" },
  { "zoneName": "America/Menominee" },
  { "zoneName": "America/Merida" },
  { "zoneName": "America/Metlakatla" },
  { "zoneName": "America/Mexico_City" },
  { "zoneName": "America/Miquelon" },
  { "zoneName": "America/Moncton" },
  { "zoneName": "America/Monterrey" },
  { "zoneName": "America/Montevideo" },
  { "zoneName": "America/New_York" },
  { "zoneName": "America/Nome" },
  { "zoneName": "America/Noronha" },
  { "zoneName": "America/North_Dakota/Beulah" },
  { "zoneName": "America/North_Dakota/Center" },
  { "zoneName": "America/North_Dakota/New_Salem" },
  { "zoneName": "America/Nuuk" },
  { "zoneName": "America/Ojinaga" },
  { "zoneName": "America/Panama" },
  { "zoneName": "America/Paramaribo" },
  { "zoneName": "America/Phoenix" },
  { "zoneName": "America/Port-au-Prince" },
  { "zoneName": "America/Porto_Velho" },
  { "zoneName": "America/Puerto_Rico" },
  { "zoneName": "America/Punta_Arenas" },
  { "zoneName": "America/Rankin_Inlet" },
  { "zoneName": "America/Recife" },
  { "zoneName": "America/Regina" },
  { "zoneName": "America/Resolute" },
  { "zoneName": "America/Rio_Branco" },
  { "zoneName": "America/Santarem" },
  { "zoneName": "America/Santiago" },
  { "zoneName": "America/Santo_Domingo" },
  { "zoneName": "America/Sao_Paulo" },
  { "zoneName": "America/Scoresbysund" },
  { "zoneName": "America/Sitka" },
  { "zoneName": "America/St_Johns" },
  { "zoneName": "America/Swift_Current" },
  { "zoneName": "America/Tegucigalpa" },
  { "zoneName": "America/Thule" },
  { "zoneName": "America/Tijuana" },
  { "zoneName": "America/Toronto" },
  { "zoneName": "America/Vancouver" },
  { "zoneName": "America/Whitehorse" },
  { "zoneName": "America/Winnipeg" },
  { "zoneName": "America/Yakutat" },
  { "zoneName": "Antarctica/Casey" },
  { "zoneName": "Antarctica/Davis" },
  { "zoneName": "Antarctica/Macquarie" },
  { "zoneName": "Antarctica/Mawson" },
  { "zoneName": "Antarctica/Palmer" },
  { "zoneName": "Antarctica/Rothera" },
  { "zoneName": "Antarctica/Troll" },
  { "zoneName": "Antarctica/Vostok" },
  { "zoneName": "Asia/Almaty" },
  { "zoneName": "Asia/Amman" },
  { "zoneName": "Asia/Anadyr" },
  { "zoneName": "Asia/Aqtau" },
  { "zoneName": "Asia/Aqtobe" },
  { "zoneName": "Asia/Ashgabat" },
  { "zoneName": "Asia/Atyrau" },
  { "zoneName": "Asia/Baghdad" },
  { "zoneName": "Asia/Baku" },
  { "zoneName": "Asia/Bangkok" },
  { "zoneName": "Asia/Barnaul" },
  { "zoneName": "Asia/Beirut" },
  { "zoneName": "Asia/Bishkek" },
  { "zoneName": "Asia/Chita" },
  { "zoneName": "Asia/Choibalsan" },
  { "zoneName": "Asia/Colombo" },
  { "zoneName": "Asia/Damascus" },
  { "zoneName": "Asia/Dhaka" },
  { "zoneName": "Asia/Dili" },
  { "zoneName": "Asia/Dubai" },
  { "zoneName": "Asia/Dushanbe" },
  { "zoneName": "Asia/Famagusta" },
  { "zoneName": "Asia/Gaza" },
  { "zoneName": "Asia/Hebron" },
  { "zoneName": "Asia/Ho_Chi_Minh" },
  { "zoneName": "Asia/Hong_Kong" },
  { "zoneName": "Asia/Hovd" },
  { "zoneName": "Asia/Irkutsk" },
  { "zoneName": "Asia/Jakarta" },
  { "zoneName": "Asia/Jayapura" },
  { "zoneName": "Asia/Jerusalem" },
  { "zoneName": "Asia/Kabul" },
  { "zoneName": "Asia/Kamchatka" },
  { "zoneName": "Asia/Karachi" },
  { "zoneName": "Asia/Kathmandu" },
  { "zoneName": "Asia/Khandyga" },
  { "zoneName": "Asia/Kolkata" },
  { "zoneName": "Asia/Krasnoyarsk" },
  { "zoneName": "Asia/Kuching" },
  { "zoneName": "Asia/Macau" },
  { "zoneName": "Asia/Magadan" },
  { "zoneName": "Asia/Makassar" },
  { "zoneName": "Asia/Manila" },
  { "zoneName": "Asia/Nicosia" },
  { "zoneName": "Asia/Novokuznetsk" },
  { "zoneName": "Asia/Novosibirsk" },
  { "zoneName": "Asia/Omsk" },
  { "zoneName": "Asia/Oral" },
  { "zoneName": "Asia/Pontianak" },
  { "zoneName": "Asia/Pyongyang" },
  { "zoneName": "Asia/Qatar" },
  { "zoneName": "Asia/Qostanay" },
  { "zoneName": "Asia/Qyzylorda" },
  { "zoneName": "Asia/Riyadh" },
  { "zoneName": "Asia/Sakhalin" },
  { "zoneName": "Asia/Samarkand" },
  { "zoneName": "Asia/Seoul" },
  { "zoneName": "Asia/Shanghai" },
  { "zoneName": "Asia/Singapore" },
  { "zoneName": "Asia/Srednekolymsk" },
  { "zoneName": "Asia/Taipei" },
  { "zoneName": "Asia/Tashkent" },
  { "zoneName": "Asia/Tbilisi" },
  { "zoneName": "Asia/Tehran" },
  { "zoneName": "Asia/Thimphu" },
  { "zoneName": "Asia/Tokyo" },
  { "zoneName": "Asia/Tomsk" },
  { "zoneName": "Asia/Ulaanbaatar" },
  { "zoneName": "Asia/Urumqi" },
  { "zoneName": "Asia/Ust-Nera" },
  { "zoneName": "Asia/Vladivostok" },
  { "zoneName": "Asia/Yakutsk" },
  { "zoneName": "Asia/Yangon" },
  { "zoneName": "Asia/Yekaterinburg" },
  { "zoneName": "Asia/Yerevan" },
  { "zoneName": "Atlantic/Azores" },
  { "zoneName": "Atlantic/Bermuda" },
  { "zoneName": "Atlantic/Canary" },
  { "zoneName": "Atlantic/Cape_Verde" },
  { "zoneName": "Atlantic/Faroe" },
  { "zoneName": "Atlantic/Madeira" },
  { "zoneName": "Atlantic/South_Georgia" },
  { "zoneName": "Atlantic/Stanley" },
  { "zoneName": "Australia/Adelaide" },
  { "zoneName": "Australia/Brisbane" },
  { "zoneName": "Australia/Broken_Hill" },
  { "zoneName": "Australia/Darwin" },
  { "zoneName": "Australia/Eucla" },
  { "zoneName": "Australia/Hobart" },
  { "zoneName": "Australia/Lindeman" },
  { "zoneName": "Australia/Lord_Howe" },
  { "zoneName": "Australia/Melbourne" },
  { "zoneName": "Australia/Perth" },
  { "zoneName": "Australia/Sydney" },
  { "zoneName": "CET" },
  { "zoneName": "CST6CDT" },
  { "zoneName": "EET" },
  { "zoneName": "EST" },
  { "zoneName": "EST5EDT" },
  { "zoneName": "Etc/GMT" },
  { "zoneName": "Etc/GMT+1" },
  { "zoneName": "Etc/GMT+10" },
  { "zoneName": "Etc/GMT+11" },
  { "zoneName": "Etc/GMT+12" },
  { "zoneName": "Etc/GMT+2" },
  { "zoneName": "Etc/GMT+3" },
  { "zoneName": "Etc/GMT+4" },
  { "zoneName": "Etc/GMT+5" },
  { "zoneName": "Etc/GMT+6" },
  { "zoneName": "Etc/GMT+7" },
  { "zoneName": "Etc/GMT+8" },
  { "zoneName": "Etc/GMT+9" },
  { "zoneName": "Etc/GMT-1" },
  { "zoneName": "Etc/GMT-10" },
  { "zoneName": "Etc/GMT-11" },
  { "zoneName": "Etc/GMT-12" },
  { "zoneName": "Etc/GMT-13" },
  { "zoneName": "Etc/GMT-14" },
  { "zoneName": "Etc/GMT-2" },
  { "zoneName": "Etc/GMT-3" },
  { "zoneName": "Etc/GMT-4" },
  { "zoneName": "Etc/GMT-5" },
  { "zoneName": "Etc/GMT-6" },
  { "zoneName": "Etc/GMT-7" },
  { "zoneName": "Etc/GMT-8" },
  { "zoneName": "Etc/GMT-9" },
  { "zoneName": "Etc/UTC" },
  { "zoneName": "Europe/Andorra" },
  { "zoneName": "Europe/Astrakhan" },
  { "zoneName": "Europe/Athens" },
  { "zoneName": "Europe/Belgrade" },
  { "zoneName": "Europe/Berlin" },
  { "zoneName": "Europe/Brussels" },
  { "zoneName": "Europe/Bucharest" },
  { "zoneName": "Europe/Budapest" },
  { "zoneName": "Europe/Chisinau" },
  { "zoneName": "Europe/Dublin" },
  { "zoneName": "Europe/Gibraltar" },
  { "zoneName": "Europe/Helsinki" },
  { "zoneName": "Europe/Istanbul" },
  { "zoneName": "Europe/Kaliningrad" },
  { "zoneName": "Europe/Kirov" },
  { "zoneName": "Europe/Kyiv" },
  { "zoneName": "Europe/Lisbon" },
  { "zoneName": "Europe/London" },
  { "zoneName": "Europe/Madrid" },
  { "zoneName": "Europe/Malta" },
  { "zoneName": "Europe/Minsk" },
  { "zoneName": "Europe/Moscow" },
  { "zoneName": "Europe/Paris" },
  { "zoneName": "Europe/Prague" },
  { "zoneName": "Europe/Riga" },
  { "zoneName": "Europe/Rome" },
  { "zoneName": "Europe/Samara" },
  { "zoneName": "Europe/Saratov" },
  { "zoneName": "Europe/Simferopol" },
  { "zoneName": "Europe/Sofia" },
  { "zoneName": "Europe/Tallinn" },
  { "zoneName": "Europe/Tirane" },
  { "zoneName": "Europe/Ulyanovsk" },
  { "zoneName": "Europe/Vienna" },
  { "zoneName": "Europe/Vilnius" },
  { "zoneName": "Europe/Volgograd" },
  { "zoneName": "Europe/Warsaw" },
  { "zoneName": "Europe/Zurich" },
  { "zoneName": "HST" },
  { "zoneName": "Indian/Chagos" },
  { "zoneName": "Indian/Maldives" },
  { "zoneName": "Indian/Mauritius" },
  { "zoneName": "MET" },
  { "zoneName": "MST" },
  { "zoneName": "MST7MDT" },
  { "zoneName": "PST8PDT" },
  { "zoneName": "Pacific/Apia" },
  { "zoneName": "Pacific/Auckland" },
  { "zoneName": "Pacific/Bougainville" },
  { "zoneName": "Pacific/Chatham" },
  { "zoneName": "Pacific/Easter" },
  { "zoneName": "Pacific/Efate" },
  { "zoneName": "Pacific/Fakaofo" },
  { "zoneName": "Pacific/Fiji" },
  { "zoneName": "Pacific/Galapagos" },
  { "zoneName": "Pacific/Gambier" },
  { "zoneName": "Pacific/Guadalcanal" },
  { "zoneName": "Pacific/Guam" },
  { "zoneName": "Pacific/Honolulu" },
  { "zoneName": "Pacific/Kanton" },
  { "zoneName": "Pacific/Kiritimati" },
  { "zoneName": "Pacific/Kosrae" },
  { "zoneName": "Pacific/Kwajalein" },
  { "zoneName": "Pacific/Marquesas" },
  { "zoneName": "Pacific/Nauru" },
  { "zoneName": "Pacific/Niue" },
  { "zoneName": "Pacific/Norfolk" },
  { "zoneName": "Pacific/Noumea" },
  { "zoneName": "Pacific/Pago_Pago" },
  { "zoneName": "Pacific/Palau" },
  { "zoneName": "Pacific/Pitcairn" },
  { "zoneName": "Pacific/Port_Moresby" },
  { "zoneName": "Pacific/Rarotonga" },
  { "zoneName": "Pacific/Tahiti" },
  { "zoneName": "Pacific/Tarawa" },
  { "zoneName": "Pacific/Tongatapu" }
];
