import React, { useState, useEffect, useRef } from 'react';
import { logo } from "../assets/svg/svg.js"
import "../components/styles/header.css"
import { getAccountBalance, web3AccountCheck } from '../helper/web3helper.js';
import Cookies from "universal-cookie";
import Profile from './Profile.js';
import balance from "../assets/images/balance.png";
import { getFirstLetterUppercase } from '../helper/object-validation-helper.js';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import config from "../web3/web3Config.json";
import { calender_svg } from "../assets/svg/calender_svg.js";
import { DatePicker, Tooltip } from 'antd';
import moment from 'moment';
import { getOrganizationData } from '../service/api-actions.js';
import db from '../db/db-service.js';

const cookies = new Cookies();
const { RangePicker } = DatePicker;

const Header = ({ toggleSidebar, name }) => {

  const history = useNavigate(); // Get the history object
  const dispatch = useDispatch();
  const [web3Value, setWeb3] = useState(null);
  const [account, setAccount] = useState("");

  const [accountBalance, setAccountBalance] = useState(0);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const [filterData, setFilterData] = useState({
    from: '',
    to: '',
    subject: '',
    dateRange: [],
  });
  let profileName = name
  const [user] = useState(cookies.get("userObject"));
  const profileRef = useRef(null);
  const triggerRef = useRef(null);
  const filterRef = useRef(null);
  const triggerfilterRef = useRef(null);
  const triggerAppRef = useRef(null);
  const appRef = useRef(null);
  const [appOpen, setAppOpen] = useState(false);

  const getFilters = useSelector((state) => state.filterObject);
  useEffect(() => {
    if (Object.keys(getFilters).length > 0) {
      setFilterData({
        from: getFilters.from,
        to: getFilters.to,
        subject: getFilters.subject,
        dateRange: getFilters.dateRange,
      });
    }
  }, [isFilter, getFilters]);

  const handleClickOutside = (event) => {
    if (profileRef.current && !profileRef.current.contains(event.target) && triggerRef.current && !triggerRef.current.contains(event.target)) {
      setProfileOpen(false);
    }
    const datePickerElement = document.querySelector('.ant-picker-panel-container') ?? false;
    if (filterRef.current && !filterRef.current.contains(event.target) && triggerfilterRef.current && !triggerfilterRef.current.contains(event.target)) {
      if (datePickerElement && !datePickerElement.contains(event.target)) {
        setIsFilter(false);
      } else if (!datePickerElement) {
        setIsFilter(false);
      }
    }
    if (appRef.current && !appRef.current.contains(event.target) && triggerAppRef.current && !triggerAppRef.current.contains(event.target)) {
      setAppOpen(false);
    }
  };

  const disabledDate = (current) => {
    return current && current > moment().startOf('day');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
    });
  };

  const handleDateChange = (dates) => {
    setFilterData({
      ...filterData,
      dateRange: dates,
    });
  };
  const triggerFilter = useSelector((state) => state.trigger);
  const handleSubmit = () => {
    dispatch({ type: "FILTER_OBJECT", payload: filterData, value: !triggerFilter });
    if (filterData.from.trim() || filterData.to.trim() || filterData.subject.trim() || filterData.dateRange.length > 0) {
      setIsApplied(true);
    } else {
      setIsApplied(false);
    }
    setIsFilter(false);
  };

  useEffect(() => {

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Check if MetaMask is installed
    if (window.ethereum) {
      web3AccountCheck(setWeb3, setAccount);
    } else {
      console.log("MetaMask is not installed");
    }
  }, []);

  useEffect(() => {
    async function fetchdata() {
      if (account) {

        const availableBalance = await getAccountBalance(web3Value, account);
        setAccountBalance(Number(availableBalance));
      }
    }
    if (web3Value) {
      fetchdata();
    }
  }, [web3Value, account]);

  useEffect(() => {
    const getOrganization = async () => {
      const organizationData = await getOrganizationData(user?.name?.split('@')?.pop(), user?.name);
      dispatch({ type: "ORGANIZATION_DATA", payload: organizationData?.data })
      const indexdb = await db.table('organizationData').toArray();
      console.log('indexdb', indexdb)
      if (organizationData?.data?.status && indexdb?.length) {
        const value = await db.table('organizationData').update(indexdb?.[0]?.id, organizationData?.data);
        return value;
      } else if(organizationData?.data?.status) {
        const value = await db.table('organizationData').add(organizationData?.data);
        return value;
      }
    }
    getOrganization()
  }, []);

  function handleProfile() {
    setProfileOpen(!isProfileOpen);
  }

  const handleApp = () => {
    setAppOpen(!appOpen)
  }

  function handleSettings(e) {
    history("/settings");
  }

  return (
    <>
      {isProfileOpen &&
        <div ref={profileRef}>
          < Profile handleProfile={handleProfile} />
        </div>
      }
      <div className='header-common-class'>
        <div className='header-container'>
          <div className="flex items-center">
            {/* <span className='cursor-pointer' onClick={() => {dispatch({ type: "HIDE_SIDE_BAR", payload: !hideSideBar } ); }}>{calender_svg.menu_svg}</span> */}
            <a className="bars cursor-pointer" onClick={toggleSidebar}>{calender_svg.menu_svg}</a>
            <div className='search-header'>
              <input type="text" placeholder='Search for message...' onChange={(e) => {
                dispatch({ type: "SEARCH_TERM", payload: e.target.value })
              }} />
              <div className='search-icon d-flex gap-10 align-items-center justify-content-center user-select-none'>
                <span className='mt-2'>{logo.dmail_search_icon_header}</span>
                <span className='cursor-pointer d-flex align-items-center justify-content-center' ref={triggerfilterRef} onClick={() => { setIsFilter(!isFilter) }}>{logo.filter_icon_svg}
                  {isApplied &&
                    <Tooltip title="Filter applied!" placement="right" visible={true}><span className='filter-applied'></span></Tooltip>
                  }
                </span>
              </div>
            </div>
            {isFilter &&
              <div className='filter-section' ref={filterRef}>
                <div className="card">
                  <div className="card-body">
                    <div className="border-bottom d-flex mt-2 gap-10">
                      <span><b>From:</b> </span>
                      <input className="reply-user-input compose" name='from' value={filterData.from}
                        onChange={handleInputChange} />
                    </div>
                    <div className="border-bottom d-flex mt-4 gap-10">
                      <span><b>To:</b> </span>
                      <input className="reply-user-input compose" name='to' value={filterData.to}
                        onChange={handleInputChange} />
                    </div>
                    <div className="border-bottom d-flex mt-4 gap-10">
                      <span><b>Subject:</b> </span>
                      <input className="reply-user-input compose" name='subject' value={filterData.subject}
                        onChange={handleInputChange} />
                    </div>
                    <div className="d-flex mt-4 align-items-center gap-10">
                      <span><b>Date within:</b> </span>
                      <RangePicker
                        className="custom-date-picker"
                        placeholder={['Start Date', 'End Date']}
                        disabledDate={disabledDate}
                        value={filterData.dateRange}
                        onChange={handleDateChange}
                      />
                    </div>
                    <div className="d-flex mt-4 gap-10 justify-content-end align-items-center">
                      <p style={{ margin: 0 }} className="cursor-pointer text-decoration-none gradient-text" onClick={() => {
                        setFilterData({
                          from: '',
                          to: '',
                          subject: '',
                          dateRange: [],
                        });
                        dispatch({ type: "FILTER_OBJECT", payload: {}, value: !triggerFilter });
                        setIsFilter(false);
                        setIsApplied(false);
                      }
                      }>Reset</p>
                      <button className="linear-gradient-btn" onClick={handleSubmit}>Search</button>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="flex items-center">
            <div className='balance-header'>
              <div className='chain-icon'>   <img src={balance} alt="Web3" /> </div>
              <div className='balance-details'>
                <div className='balance-text'>Balance</div>
                <div className='balance-value'>{accountBalance.toFixed(4)} {config.SYMBOL}</div>
              </div>
            </div>
            <div className='settings-header cursor-poiner-common' onClick={handleSettings} >
              {logo.dmail_settings_svg}
            </div>
            <div className='settings-header'>
              <span className='cursor-pointer d-flex' onClick={handleApp} ref={triggerAppRef}>{logo.app_menu}</span>
              {appOpen &&
                <div className='app-menu' ref={appRef}>
                  <div className='app-menu-item'>
                    <div className='app-menu-item-icon cursor-pointer d-flex justify-content-start align-items-center' onClick={() => {
                      window.open(config.DCALENDAR_URL, "_blank");
                    }}>
                      <img src='/Dcalendar-Black.svg' width={120} alt='dcalender-icon' />
                    </div>
                    <div className='app-menu-item-icon cursor-pointer d-flex justify-content-start align-items-center' onClick={() => {
                      window.open(config.DCONTACT_URL, "_blank");
                    }}>
                      <img src='/Dcontacts-Black.svg' width={115} alt='dcontacts-icon' />
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className='user-profile-header' ref={triggerRef} onClick={handleProfile} >
              <div className='user-profile-header gap-10'>
                <div className="user-profile-pic">{getFirstLetterUppercase(user.name.split("@")[0])}</div>
                <div className='user-profile-name' >
                  <div className='user-profile-username'>  {(profileName.firstName && profileName.lastName) ? `${profileName.firstName} ${profileName.lastName}` : (profileName.firstName || profileName.lastName || user.name.split("@")[0])}</div>
                  <div className='user-profile-useremail'>{user.name}</div>
                </div>
              </div>
              <div className='user-profile-arrow cursor-poiner-common' >{logo.dmail_arrow_down_svg}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
