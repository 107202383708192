import Cookies from "universal-cookie";
import { connectWallet } from "./helper/wallet-helper";
import { getUserContract } from "./helper/contract-helper";
import { deleteDatabase } from "./db/db-helper";
import { login } from "./service/api-actions";
const cookies = new Cookies();

export function requireAuth() {

    const user = cookies.get("userObject");
    if (user && user.name) {
        connectWallet().then(async (address) => {
            if (address) {
                 const contract = getUserContract();
                const getUserDetailsForWallet = await contract.methods.getUserDetailsForWallet(address).call({ from: address });

                if (getUserDetailsForWallet && getUserDetailsForWallet.length) {

                    const userName = user.name;
                    const userPresent = getUserDetailsForWallet.some(userData => userData.userName === userName);

                    if (!userPresent) {
                        const userFirstData = getUserDetailsForWallet[0];
                        const getToken = await login(userFirstData.userName, userFirstData.publicKey);
                        if (getToken && getToken.isAuth && getToken.token) {
                            cookies.set("accessToken", getToken.token, { path: "/" });
                            const userObject = { name: userFirstData.userName, wallet: userFirstData.walletAddress, publicKey: userFirstData.publicKey };
                            cookies.set("userObject", userObject, { path: "/" });
                            deleteDatabase();
                            window.open('/inbox', '_self');
                        } else {
                            window.open('/signup', '_self');
                        }
                    } else {
                        
                    }
                } else {
                    logout();
                }
            } else {
                logout();
            }
        })
        return true;
    }
    logout();
}

export function logout() {
    cookies.remove("accessToken");
    deleteDatabase();
    window.open('/', '_self');
}

