import React from 'react'
import { logo } from '../assets/svg/svg'
import { message, Tooltip } from 'antd';

const ViewMessageHeader = (props) => {

    const { messageList, index } = props;
    // const totalMessages = messageList.length;
    //const numberLength = totalMessages.toString().length;
    // const currentPage = index + 1;
    // Convert currentnumber to a string and pad with leading zeros to match the length of total
    // let paddedNumber = currentPage.toString().padStart(numberLength.toString().length, '0');

    const flattenedMessages = messageList.flatMap((message) =>
        message.data && Array.isArray(message.data) ? message.data : message
    );
    const totalMessages = flattenedMessages.length;


    if (totalMessages === 0) {
        return <div>No messages available</div>; // Handle case where no messages exist
    }

    const currentPage = index + 1;
    const paddedNumber = currentPage.toString().padStart(totalMessages.toString().length, '0');

    const handlePrevious = () => {
        if (currentPage === 1) return; // Disable if already at the first message
        const prevIndex = index - 1;
        props.setIndex(prevIndex);
        const message = flattenedMessages[prevIndex];
        props.openMessage(message);
    };

    const handleNext = () => {
        if (currentPage === totalMessages) return; // Disable if already at the last message
        const nextIndex = index + 1;
        props.setIndex(nextIndex);
        const message = flattenedMessages[nextIndex];
        props.openMessage(message);
    };
    return (
        <div className='box-common-header'>
            <div className='box-common-header-back cursor-pointer' disabled={true} onClick={() => {
                {
                    props.setIndex(null);
                    props.setIsDecrypted(null);
                    props.setDecryptedContent(null);
                    props.reRender();
                }
            }}>
                <Tooltip title={`back to ${props.pageType === "Insecure" ? "Web2 Inbox" : props.pageType}`} placement='top'>{logo.msg_box_back_btn}</Tooltip>
            </div>

            {
                !props.isDecrypted && <div className='box-common-header-actions'>

                    <Tooltip title="Previous">
                        <div className='box-common-header-actions-btn' onClick={() => {

                            handlePrevious()

                        }}> {logo.back_arrow_svg}</div>
                    </Tooltip>

                    <div className='page-index-division'> <span className='current-page'> {paddedNumber} </span>  / {totalMessages} </div>
                    <Tooltip title="Next">
                        <div className='box-common-header-actions-btn' onClick={() => {
                            handleNext()
                        }}> {logo.next_arrow_svg} </div>
                    </Tooltip>
                </div>

            }

        </div >
    )
}

export default ViewMessageHeader
