import Web3 from 'web3';
import contractData from '../web3/hostContract.json';
import hostRegistry from '../web3/hostRegistry.json';
import config from '../web3/web3Config.json';
import Cookies from "universal-cookie";
import { getPublicKey, sendEmailOnDifferentChain, deleteFolderMessage } from './email-helper.js';
import { getEncryptedValue, sendAttachment, sendWebTwoEmail, storeSendCopyEmailsInAdmin, updateInsecureMail } from '../service/api-actions';
import { transactionAction } from './chain-helper';
import db from '../db/db-service.js';
import { getHostHelperContract, getHostContract } from './contract-helper.js';
import { toast, Bounce } from 'react-toastify';
import { generateEvent, parseICalendar } from './inviteHelper.js'
import constants from '../constants/constants.json';

const contractAddress = config.CONTRACT;
const chainId = config.CHAIN_ID;
const cookies = new Cookies();

const web3 = new Web3(window.ethereum);
const contractMethods = new web3.eth.Contract(contractData.contract, contractAddress);




export async function saveSenderEncryptedEmail(emailObject) { // Save sent items.
    const userName = cookies.get("userObject");
    const msg = JSON.stringify(emailObject);
    const publicKey = await contractMethods.methods.getPublicKeyOfUser(userName.name).call();
    const data = await getEncryptedValue(msg, publicKey);
    const encryptedMessage = data.returnValue;

    return encryptedMessage;
}


const validateEmails = (emails) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const invalidEmails = emails.filter(email => !emailRegex.test(email));
    return {
        isValid: invalidEmails.length === 0,
        invalidEmails
    };
};

export const sendInvite = async (bulkemail, eventParams, active, account, subject, text, files) => {
    try {
        let event = null;
        let creatEvent = null;
        let file = null;
        if (eventParams) {
            creatEvent = await generateEvent(eventParams, active);
            if (!creatEvent) {
                return false;
            }
            const blob = new Blob([creatEvent], { type: "text/calendar" });
            file = new File([blob], "invite.ics", { type: "text/calendar" });
            event = await parseICalendar(creatEvent);
            if (!event) {
                return false;
            }
        }
        return await sendEmails(bulkemail, [], [], subject, text, false, 'MSG', [file], event)
    } catch (error) {
        console.log(error);
        return null;
    }
}

// function to send/store email on blockchain
export const sendEmails = async (to, cc, bcc, subject, message, isSavedOn = false, defaultEncryptedMessage = "MSG", files = null, event = null) => {

    const allUsers = to.concat(cc, bcc).filter(value => value !== "");
    if (allUsers.length === 0) {
        toast.error("Please specify at least one recipient.", {
            position: "top-center",
            transition: Bounce,
        });
        return false;
    }
    const { isValid, invalidEmails } = validateEmails(allUsers);
    if (!isValid) {
        toast.dismiss()
        const errorMessage = `Invalid email ${invalidEmails.length > 1 ? 'addresses' : 'address'}: ${invalidEmails.join(", ")}`;
        toast.error(errorMessage, {
            position: "top-center",
            transition: Bounce,
        });
        return false;
    }

    const userRestrictions = await organizationEmailValidation(allUsers);
    if (userRestrictions) {
        toast.error('You are restricted from sending emails to external organizations or domains.',
            {
                position: "top-center",
                transition: Bounce,
            }
        );
        return false;
    }

    const unIdentifiedUser = await userAvailableCheck(allUsers);

    const userNameData = cookies.get("userObject");
    const outbox = {
        to: to,
        bcc: bcc,
        cc: cc,
        subject: subject || "(No Subject)",
        message: message,
        sender: userNameData.name,
    }
    try {
        const UTCtime = new Date().toUTCString();
        // to encrypt the message.
        const [toEncryptionMessage, ccEncryptionMessage, bccEncryptionMessage] = await Promise.all([
            await getEncryptedMessageByUserArray(to, subject, message, files, event),
            await getEncryptedMessageByUserArray(cc, subject, message, files, event),
            await getEncryptedMessageByUserArray(bcc, subject, message, files, event)
        ]);

        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        const sender = userNameData.name;
        const emailHeaderObject = { sender, to, cc, bcc, subject };
        const emailDetails = [accounts[0], UTCtime, JSON.stringify(emailHeaderObject), defaultEncryptedMessage];
        const functionParams = [subject, sender, to, cc, bcc, toEncryptionMessage, ccEncryptionMessage, bccEncryptionMessage, emailDetails];
        let filteredTo = functionParams[2].filter((val, index) => functionParams[5][index] !== '-');
        let filteredCC = functionParams[3].filter((val, index) => functionParams[6][index] !== '-');
        let filteredBcc = functionParams[4].filter((val, index) => functionParams[7][index] !== '-');
        let filterEncryptTo = functionParams[5].filter(val => val !== '-');
        let filterEncryptCC = functionParams[6].filter(val => val !== '-');
        let filterEncryptBcc = functionParams[7].filter(val => val !== '-');

        const isOwnDomainPresent = await getOwnDomainBoolStatus([filteredTo, filteredCC, filteredBcc]);
        // const isChainDomainPresent = await getChainDomainJson([filteredTo, filteredCC, filteredBcc]); // TODO

        // const filteredParams = [subject, sender, filteredTo, filteredCC, filteredBcc, filterEncryptTo, filterEncryptCC, filterEncryptBcc, emailDetails];
        const filteredParams = [filteredTo, filteredCC, filteredBcc, filterEncryptTo, [''], [''], sender, emailDetails, subject, constants.emailAttributes]
        let checkIsSaved = false;
        // if (isChainDomainPresent.length) {
        //     checkIsSaved = true;
        //     for (let chainData of isChainDomainPresent) {
        //         const chainHostContract = chainData[0];
        //         const chainHostJson = JSON.parse(chainData[1]);
        //         if (chainHostJson.chainId === parseInt(chainId)) {
        //             const contractMethodValue = new web3.eth.Contract(contractData.contract, chainHostContract);
        //             const returnValue = await transactionAction(contractMethodValue, "sendEmailValues", filteredParams, accounts[0]);
        //             if (!returnValue) {
        //                 await setOutBoxDB(outbox);
        //             }
        //         } else {
        //             const chainJson = await getChainDetails(config.DOMAIN);
        //             const returnValue = await sendEmailOnDifferentChain(filteredParams, chainJson, accounts[0], chainData);
        //             if (!returnValue) {
        //                 await setOutBoxDB(outbox);
        //             }
        //         }
        //     }
        // }
        console.log(filteredParams)

        if (isOwnDomainPresent) {
            checkIsSaved = true;
            const returnValue = await saveEmailForUser(accounts[0], filteredParams); // send emails
            if (!returnValue) {
                await setOutBoxDB(outbox);
                return false
            }
        }

        for (const web2 of unIdentifiedUser) {
            const status = await domainAvailableCheck(web2);
            const web2Object = [subject, userNameData.name, to, cc, bcc, message, files];
            const helperContractMethod = getHostHelperContract();
            if (!status) {
                checkIsSaved = true;
                const helperfunctionParams = [userNameData.name, unIdentifiedUser];
                const web2AddressBookForUser = await helperContractMethod.methods.getWeb2Emails(userNameData.name).call({ from: userNameData.wallet });
                const filterAddress = web2AddressBookForUser.filter(email => email === web2);
                if (filterAddress.length === 0) {
                    await transactionAction(helperContractMethod, "addWeb2Emails", helperfunctionParams, accounts[0], 'helper');
                }
                await sendWebTwoEmail(web2Object);
                break;
            }
        }
        if (isSavedOn && checkIsSaved) {
            const functionParams = [[userNameData.name], [defaultEncryptedMessage], emailDetails, subject, [...constants.emailAttributes, {key:'isSaved', value: 'true'}] ];
            await transactionAction(contractMethods, "storeSendMail", functionParams, accounts[0], 'hostContract');
        }
        if (!checkIsSaved) {
            toast.error("Something went wrong!", {
                position: "top-center",
                transition: Bounce,
            });
            return false;
        }
        await storeSendCopyEmailByAdmin(outbox);
        return true;
    } catch (error) {
        console.error('error', error);
        await setOutBoxDB(outbox);
        return false;
    }
}

async function setOutBoxDB(json) {
    const decryptedObject = { json: json };
    const value = await db.table('outbox').add(decryptedObject);
    return value;
}

async function getEncryptedMessageByUserArray(userArray, subject, message, files, event) {

    let returnArray = [];
    for (let i = 0; i < userArray.length; i++) {
        const user = userArray[i];
        if (user) {
            const encryptedMessage = await getEncryptedMessageByUser(user, subject, message, files, event);
            returnArray.push(encryptedMessage || "-");
        }
    }
    return returnArray;
}


async function getEncryptedMessageByUser(user, subject, message, files, event) {
    const publicKey = await contractMethods.methods.getPublicKeyOfUser(user).call();
    const emailObject = { recipient: user, subject: subject, message: message, event: event };
    if (!publicKey) return null;
    if (files && files.length) {
        const attachmentResult = await sendAttachment(files, publicKey);
        emailObject.attachment = attachmentResult.data.returnValue;
    }
    const msg = JSON.stringify(emailObject);
    const data = await getEncryptedValue(msg, publicKey);
    return data && data.returnValue;
}

async function saveEmailForUser(account, functionParams) {
    const contractMethods = new web3.eth.Contract(contractData.contract, contractAddress);
    const txHash = await transactionAction(contractMethods, "addEmails", functionParams, account, 'hostContract');
    return txHash;
}

async function getChainDetails(receiptDomain) {
    const hostAddress = await contractMethods.methods.hostRegistryAddress().call();
    const hostContractMethods = new web3.eth.Contract(hostRegistry.contract, hostAddress);
    const chainJson = await hostContractMethods.methods.getChainDetails(receiptDomain).call();
    return chainJson;
}

async function getOwnDomainBoolStatus(userArray) {

    let present = false;
    for (const users of userArray) {
        for (const user of users) {
            const publicKey = await contractMethods.methods.getPublicKeyOfUser(user).call();
            if (publicKey) {
                present = true;
                break;
            }
        }
    }

    return present;
}

async function getChainDomainJson(userArray) {

    const hostDetails = [];
    const domain = await contractMethods.methods.constDomain().call();

    for (const users of userArray) {
        for (const user of users) {
            const userDomain = user.split("@")[1];
            if (userDomain && userDomain != domain) {
                const chainJson = await getChainDetails(userDomain);
                if (chainJson["0"] && chainJson["1"]) {
                    chainJson["user_domain"] = userDomain;
                    hostDetails.push(chainJson);
                }
            }
        }
    }

    return hostDetails;
}

async function domainAvailableCheck(user) {
    const publicKey = await contractMethods.methods.getPublicKeyOfUser(user).call();;
    if (publicKey) {
        return true;
    } else {
        return false;
    }
}


async function userAvailableCheck(userList) {
    const unIdentifiedUser = [];
    for (let user of userList) {
        const publicKey = await contractMethods.methods.getPublicKeyOfUser(user).call();
        if(!publicKey) {
            unIdentifiedUser.push(user);
        }
    }
    return unIdentifiedUser
}

export const deleteEmails = async (message, msgId, index, messageList, type, getFolderIndex) => {
    const user = cookies.get("userObject");

    switch (type) {
        case "Draft":
            await deleteDraft(user.name, msgId);
            break;
        case "Outbox":
            await deleteOutbox(index);
            break;
        case "Insecure":
            await deleteInsecure(msgId);
            break;
        case "Trash":
            await deleteTrashMail(user.name, msgId, message);
            break;
        case "Sent":
            await deleteSentMail(user.name, msgId);
            break;
        case "Folders":
            await deleteFolderMail(index, message, user.name, user.wallet, getFolderIndex);
            break;
        case "Inbox":
        case "Archive":
        case "Important":
            await deleteInboxMail(user.name, msgId);
            break;
        case "inboxtrash":
        case "Senttrash":
            await restoreEmail(user.name, message, type);
            break;
        case "emptyTrash":
            await emptyTrash(message, user.name);
            break;
        default:
            break;
    }
}
async function emptyTrash(message, userName) {
    const inboxData = message.map(value => value.id).map(value => parseInt(value.id));
    const collection = [...inboxData];
    await permanentlyDeleteEmail(userName, collection, 'hostContract')
}

async function restoreEmail(userName, message, type) {
    if (type === 'inboxtrash') {
        let functionParams = "";
        if (message.length > 1) {
            const inboxdata = message.map(data => parseInt(data.id))
            functionParams = [userName, inboxdata, [{key:'isDeleted', value: 'false'}]];
        }
        else {
            let value = ""
            if (Array.isArray(message) && message.length > 0) {
                value = parseInt(message[0].id);
            }
            else {
                value = parseInt(message.id)
            }
            functionParams = [userName, [value], [{key:'isDeleted', value: 'false'}]];
        }
        await updateEmailAttributeValue(...functionParams, 'hostContract');
    } else if (type === 'Senttrash') {

        let functionParams = "";
        if (message.length > 1) {
            const inboxdata = message.map(data => parseInt(data.id))

            functionParams = [userName, inboxdata, [{key: "isDeleted", value: "false" }]];
        }
        else {
            let value = ""
            if (Array.isArray(message) && message.length > 0) {
                value = parseInt(message[0].id);
            }
            else {
                value = parseInt(message.id)
            }
            functionParams = [userName, [value], [{key: "isDeleted", value: "false" }]];
        }
        await updateEmailAttributeValue(...functionParams, 'hostContract');
    }
}

async function deleteDraft(userName, id) {
    await db.table('draft').delete(id);
}

async function deleteOutbox(index) {
    const indexdb = await db.table('outbox').toArray();
    if (indexdb.length > 0) {
        const getid = indexdb[index];
        await db.table('outbox').delete(getid.id);
    }
}

async function deleteInsecure(id) {
    await updateInsecureMail('deleteemail', id, "");
}

const deleteTrashMail = async (userName, msgId, message) => {
    const response = message && message?.filter(data => data?.emailType === 'Senttrash');
    if (response.length > 0) {
        const inboxData = message?.length > 1 
            ? message?.map(data => parseInt(data?.id)) 
            : [parseInt(message?.[0]?.id || message?.id)];        
        await permanentlyDeleteEmail(userName, inboxData, 'hostContract');
    } else {
        await permanentlyDeleteEmail(userName, [parseInt(msgId)], 'hostContract');
    }
}

const deleteSentMail = async (userName, msgId) => {
    const attribute = [{key: "isDeleted", value: "true" }];
    const functionParams = msgId.length > 1
    ? [userName, msgId?.map(data => parseInt(data)), attribute]
    : [userName, [Array.isArray(msgId) ? parseInt(msgId[0]) : parseInt(msgId)], attribute];

    await updateEmailAttributeValue(...functionParams, 'hostContract');
}

// const deleteFolderMail = async (index, messageList, userName, wallet, getFolderIndex) => {
//     const response = messageList.filter(data => data.emailType === "inbox")
//     const sentresponse = messageList.filter(data => data.emailType === "sent")
//     if (response.length > 0) {
//         const value = response.map(data => data.id)
//         await deleteInboxMail(userName, value);
//     }
//     if (sentresponse.length > 0) {
//         const value = sentresponse.map(data => data.id)
//         await deleteSentMail(userName, value);
//     }

// }
const deleteFolderMail = async (index, messageList, userName, wallet, getFolderInde) => {
    try {
        const inboxIds = [];
        const sentIds = [];
        messageList.forEach((data) => {
            if (data.emailType === "inbox") {
                inboxIds.push(data.id);
            } else if (data.emailType === "sent") {
                sentIds.push(data.id);
            }
        });
        if (inboxIds.length > 0) {
            await deleteInboxMail(userName, inboxIds);
        }
        if (sentIds.length > 0) {
            await deleteSentMail(userName, sentIds);
        }

    } catch (error) {
        console.error('Error deleting messages:', error);
    }
};

const deleteInboxMail = async (userName, msgId) => {

    let functionParams = ""
    if (msgId.length > 1) {

        functionParams = [userName, msgId, [{key: "isDeleted", value: "true" }]];
    } else {
        functionParams = [userName, [parseInt(msgId)], [{key: "isDeleted", value: "true" }]];
    }
    await updateEmailAttributeValue(...functionParams, 'hostContract');
}

export const updateAttribute = async (userName, id, key, type, value) => {
    const functionParams = [userName, id, type, key, value];
    const accounts = await window.ethereum.request({ method: 'eth_accounts' });
    const helperContractMethod = getHostHelperContract();
    await transactionAction(helperContractMethod, "setAttribute", functionParams, accounts[0], 'helper');
}

export const organizationEmailValidation = async(allUsers) => {
    try {
        const organizationData = await db.table('organizationData').toArray();
        if(organizationData?.length > 0 && (!organizationData[0]?.organizationData?.organizationSettings?.sendOutsideOrg && !organizationData[0]?.userSettingsData?.sendOutsideOrg)){
            const usersDomain = allUsers.map(data => data.split("@")[1]);
            return usersDomain?.some(data => data !== organizationData[0]?.organizationData?.domain)
        }else {
            return false
        }
    } catch (error) {
        return false
    }
}


export const storeSendCopyEmailByAdmin = async (emailData) => {
    try {
        const organizationData = await db.table('organizationData').toArray();
        if (organizationData?.length > 0) {
            const { organizationSettings, primaryEmail } = organizationData[0]?.organizationData || {};
            const { userSettingsData } = organizationData[0] || {};            
            const sendCopyEmail = userSettingsData?.sendCopyEmail || organizationSettings?.sendCopyEmail;        
            if (sendCopyEmail) {
                let email = primaryEmail;
                if(userSettingsData?.sendCopyEmail && userSettingsData?.copyEmailUser ) {
                    email = userSettingsData?.copyEmailUser
                } else if (organizationSettings?.sendCopyEmail && organizationSettings?.copyEmailUser) {
                    email = organizationSettings?.copyEmailUser
                }
                const result = await storeSendCopyEmailsInAdmin(emailData, organizationData[0]?.uuid, email);
                console.log('result', result);
            }
        } else {
            return null;
        }
        
    } catch (error) {
        console.log('error', error)
        return null
    }
}

export const permanentlyDeleteEmail = async (userName, emailIds, contractName) => {
    const functionParams = [userName, emailIds];
    const accounts = await window.ethereum.request({ method: 'eth_accounts' });
    const contractMethods = getHostContract();
    await transactionAction(contractMethods, "permanentlyDeleteEmail", functionParams, accounts[0], contractName);
}

export const updateEmailAttributeValue = async (userName, emailIds, attributes, contractName) => {
    try {
        const functionParams = [userName, emailIds, attributes];
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        const contractMethods = getHostContract();
        await transactionAction(contractMethods, "updateEmailAttributes", functionParams, accounts[0], contractName);
    } catch (error) {
        console.log('*****Error on UpdateEmailAttributeValue*****', error);
    }
}